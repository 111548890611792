import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import moment from "moment";
import _ from "lodash";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import "moment/locale/nl";
import {

    Tag, Menu,
    Input, Spin, Result, Button, Table

} from "antd";
import { VehicleEvent } from "@internal/hikes";
import fireStoreService from "../fireStoreService";
import { formatLicenseplate } from "../licenseplateFormater";
// @ts-ignore
const menu = (
    <Menu>
        <Menu.Item>Action 1</Menu.Item>
        <Menu.Item>Action 2</Menu.Item>
    </Menu>
);
// @ts-ignore
export const DesktopDataFeed = ({ setVehicleDrawer, setActiveCar }) => {
    moment().locale("nl");
    const [history, setHistory] = useState<VehicleEvent[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(true);
    useEffect(() => {
        // historyFeed(kenteken)
        const date = new Date();
        date.setDate(date.getDate() - 1);
        const today = date.toLocaleDateString("fr-CA");
        console.log(today);
        fireStoreService.subscribeToHistory(today, (data: any) => {
            setHistory(data);
            setPending(false);
        });
    }, []);
    const historyFeed = async (kenteken: string) => {
        const data = await fireStoreService.getHistoryForCar(kenteken);
        // console.log("check", data.filter((x: any) => x.actions).map((x: any) => x.actions))
        if (data != "error") {
            ReactGA.event({
                category: "Historie",
                action: `Geschiedenis opgevraagd`,
                label: `Geschiedenis opgevraagd voor auto ${kenteken}`,
                value: data.data.length
            });
            // @ts-ignore
            setHistory(data);
        } else {
            setError(true);
        }
        setPending(false);
    };
    const columns = [
        {
            title: "Kenteken",
            dataIndex: "kenteken",
            key: "kenteken",
            ellipsis: false,
            render: (kenteken: any) => (
                <span>
                    <Tag className="kentekenTag"> {formatLicenseplate(kenteken)} </Tag>

                </span>
            ),
            onCell: (value: any, index: number) => ({
                onClick: () => {
                    console.log("on click", value.kenteken);
                    setActiveCar(value.kenteken);
                    ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                    });
                    setVehicleDrawer(true);
                }

            }),
            filters: _.uniqBy((history.map((x: any) => {
                return {
                    text: x.gebruikersnaam,
                    value: x.gebruikersnaam
                };
            })), "text"),
            onFilter: (value: any, record: any) => record.gebruikersnaam.includes(value),
            sorter: (a: any, b: any) => a.gebruikersnaam.localeCompare(b.gebruikersnaam)
        },
        {
            title: "Gebruiker", dataIndex: "gebruikersnaam", key: "gebruikersnaam", ellipsis: true,
            filters: _.uniqBy((history.map((x: any) => {
                return {
                    text: x.gebruikersnaam,
                    value: x.gebruikersnaam
                };
            })), "text"),
            onFilter: (value: any, record: any) => record.gebruikersnaam.includes(value),
            sorter: (a: any, b: any) => a.gebruikersnaam.localeCompare(b.gebruikersnaam)
        },
        {
            filters: _.uniqBy((history.map((x: any) => {
                return {
                    text: moment(x.event_datum).format("ll"),
                    value: moment(x.event_datum).format("ll")
                };
            })), "text"),
            onFilter: (value: any, record: any) => moment(record.event_datum).format("ll").includes(value),
            title: "Datum/tijd", dataIndex: "event_datum", key: "platform",
            render: (date: any) => (
                <span>
                    {moment(date).format("lll")}
                </span>
            ),
        },
        {
            title: "Gewijzigd", dataIndex: "actions", key: "Acties",
            render: (actions: any) => (
                <span>

                    {actions && actions.filter((x: any) => !x.length).map((value: any, index: number) => {
                        return (<Tag key={`action-${index}-${value.action}`} style={{
                            borderRadius: "10px",
                            marginBottom: "7px",
                            paddingLeft: "17px",
                            paddingRight: "17px"
                        }} color="blue">{getByLabel(value.action)}</Tag>);
                    })}
                </span>
            )
        }
    ];
    const expandedRowRender = (tabledata: any) => {
        const columns = [
            {
                title: "Actie", dataIndex: "action", key: "Action",
                render: (action: any) => (<span>{getByLabel(action)}</span>),
            },
            { title: "Van", dataIndex: "intitialValue", key: "initial" },
            {
                title: "Naar", dataIndex: "value", key: "value",
            },
        ];

        return <Table columns={columns} rowKey={record => `action-table-${record.kenteken}`} dataSource={tabledata.actions} pagination={false} />;
    };
    if (pending) {
        return <div> <Spin tip="Loading...">
        </Spin></div>;
    }
    if (error && history.length == 0) {
        return (<Result
            status="403"
            title="Niet beschikbaar"
            subTitle="Sorry, op dit moment is er geen geschiendenis beschikbaar"
            extra={<Button type="primary">Back Home</Button>}
        />);
    }


    return (<Table
        className="table"
        // @ts-ignore
        columns={columns}
        expandable={{
            expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                    <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                ) : (
                    <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                )
        }}
        dataSource={history}

        rowKey={record => `event-table-${record.id}`}
    />);

};
const labels = {
    verzorgings_status: "Staat",
    huidige_locatie_op_terein: "Locatie",
    kilometerstand: "Kilometerstand",
    liters: "Getankte liters",
    organisatie: "Vloot"
} as any;
const getByLabel = (key: string) => {
    const label = labels[key] || key;
    return label;
};
