import React, { useContext, useState } from "react";
import { message, Spin, Table, Alert, Row, DatePicker, Steps, Checkbox, Tabs, Form, Input, Tag, Select, Button, List, Col } from 'antd';
import _ from "lodash";
import { CarOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { LocationIcon } from "../../components/icons";
import { AuthContext } from "../../Providers/Auth";
import { createTeam, Team, WorkOrder, createWorkOrder } from "@internal/hikes";
import ReactGA from "react-ga";
import fireStoreService from "../../fireStoreService";
import * as Sentry from "@sentry/browser";
import { User } from "../../types";
import { RBAC } from "../../Rbac";
import { permision } from '../Teams/TeamsScreen';
import moment from 'moment';
const options = { month: "long", day: "numeric", year: "numeric" };
const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const { Step } = Steps;
const { TextArea } = Input;
interface TeamDetailsType {
  team: Team;
  users: User[];
}
export const WorkOrderWizard = (props) => {
  const { currentUser } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});
  const [removePermissionMutations, setRemovePermissionMutations] = useState<string[]>([]);
  const [addPermissionMutations, setAddPermissionMutations] = useState<string[]>([]);
  const [formSaved, setFormSaved] = useState<boolean>(true);
  const [tasks, setTasks] = useState<any[]>([]);
  // if (!team) {
  //   setCreatenNewTeam(true);
  // }

  const messageConfig = (content) => {
    return {
      content,
      style: {
        fontWeight: 800
      }
    };
  }

  const searchVehicle = async (event) => {
    const value = form.getFieldValue("kenteken");
    if (!value) {
      message.error(messageConfig("Eerst een kenteken invoeren"));
      Sentry.captureMessage(`Gebruiker heeft een locatie gezocht zonder kenteken`);

      return;
    }
    ReactGA.event({
      category: "Opdrachten",
      action: "onClick",
      label: `${currentUser.first_name} locatie zoeken voor ${value}`,
      value
    });
    const vehicle = await fireStoreService.getCarByKenteken(value);
    if (vehicle) {
      form.setFieldsValue({ location: vehicle.huidige_locatie_op_terein });
    } else {
      Sentry.captureMessage(`Opdracht wizard locatie voor ${value} niet gevonden`);
      message.error(messageConfig("Huidige locatie is niet gevonden"));

    }
  };
  const onChange = (record: any, isNotSelected, param) => {
    const newPermission = param.map(x => x.name);
    // console.log("current permissions", selectedRowKeys);
    // console.log("new permissions", newPermission);
    // console.log(param.map(x => x.name));
    // console.log("current permissions", selectedRowKeys);
    if (isNotSelected) {
      // console.log("add permission", addPermissionMutations, record.name);
      setAddPermissionMutations([...addPermissionMutations, record.name]);
      // const newArray = selectedRowKeys.splice(selectedRowKeys.indexOf(record.name), 1);
      // setSelectedRowKeys(newArray);
    } else {
      setRemovePermissionMutations([...removePermissionMutations, record.name]);
      // console.log("remove permission", removePermissionMutations, record.name);
      // setSelectedRowKeys([...selectedRowKeys, record.name]);
      // selectedRowKeys.push(record.key);
    }

  };
  function onDueDate(value: string, dateString: string) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  function onOk(value: string) {
    console.log("onOk: ", value);
  }
  function onTaskSelect(value: string[], value2: any) {
    console.log("onOk: ", value, value2);
    setTasks(value2)
  }
  const handleKenteken = (e) => {
    if (e.length === 6) {

    }
  }
  const rowSelection = {

    onSelect: (record, second, param) => onChange(record, second, param),
    onSelectAll: (record, second, param) => onChange(record, second, param),
    getCheckboxProps: (record: any) => ({
      disabled: false, // Column configuration not to be checked
      name: record.name
    })
  };
  const onChangeForm = () => {
    setFormSaved(false);
  };

  const handleFormSubmit = () => {
    // console.log('equal', equal, currentFormState, formState)
    form.validateFields()
      .then(async (values) => {
        const dueDate = values.dueDate ? values.dueDate.format() : moment().format();
        const workOrderVehicle = values.kenteken;
        const location = values.location;
        const description = values.description || "";
        const prio = parseInt(values.prio);
        const vehicle = {
          kenteken: workOrderVehicle,
          location
        };
        const name = values.name;
        const workOrder = await createWorkOrder(name, currentUser, description, "", dueDate, values.tasks, "", prio, vehicle, currentUser.role);
        const order = await fireStoreService.saveWorkOrder(workOrder);
        if (order) {
          setFormSaved(true);
          // console.log("work order", order);
          message.success("Succesvol opgeslagen", 1);
        }
      })
      .catch((errorInfo) => {
        const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
        Sentry.captureMessage(`validatie fout voor ${errorObject}`, Sentry.Severity.Warning);
        // console.log("error info", errorObject);
        // console.log("error info", errorInfo);
      });
  };

  return (
    <div className="workorderwizard full-height-with-menu">
      <Form form={form} size="large" style={{ marginTop: "0" }}
        onChange={() => { onChangeForm(); }} onFinish={(values) => { handleFormSubmit(); }} layout="vertical" wrapperCol={{ span: 24 }}>
        {/* <Steps size="small" current={1}>
          <Step title="Basis gegevens" />
          <Step title="taken toevoegen" />
        </Steps> */}

        {/* <Form.Item name="name" rules={[{ required: true }]} label="Samenvatting van de opdracht">
          <Input
            type="text"
          />
        </Form.Item> */}
        <div>
        </div>
        <h2 style={{ color: "#31a3ff", fontWeight: "700" }}>Om welk kenteken gaat het?</h2>
        <Form.Item name="icle" label="">
          <Form.Item name="kenteken" style={{
            display: "inline-block",
            width: "calc(50% - 8px)"
          }}
            rules={[{
              validateTrigger: "onChange",
              message: "Ongeldig",
              validator: (rule, value) => {
                // console.log("value kenteken", value);
                if (!value || value.length < 6) {
                  return Promise.reject(new Error("Geen geldig kenteken"));
                }
                // if (isNaN(value)) {
                //   return Promise.reject(new Error("geen geldige waarde"));
                // }
                // console.log("resolving")
                return Promise.resolve();
              }

            }]}>
            <Input
              placeholder={"2-THX-57"}
              type="text"
              onChange={handleKenteken}
              addonAfter={
                <CarOutlined />
              }
            />
          </Form.Item>
          <Form.Item style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px"
          }}
            name="location" rules={[{ required: true, message: "Locatie opgeven" }]}>
            <Input
              type="text"
              placeholder="Waar staat de auto?"
              addonAfter={
                <LocationIcon />
              }
            />


          </Form.Item>
          <Button style={{ float: "right", marginTop: "-12px" }} onClick={(e) => { searchVehicle(e); }} className="zoek-huidige-locatie" icon={<SearchOutlined style={{ fontWeight: 800, fontSize: "18px" }} />}
            type="link">Zoek naar de huidige locatie</Button>
        </Form.Item>
        <h2 style={{ color: "#31a3ff", fontWeight: 700 }}>Wanneer moet de auto gereed zijn?</h2>
        <Row gutter={6}>
          <RBAC {...props} permissionsNeeded={["opdrachten:bewerken"]} currentUserPermissions={currentUser.permissions} >
            <Col span={12}>
              <Form.Item style={{
                display: "inline-block",
                width: "100%"
              }} name="dueDate" rules={[{
                message: "Selecteer een datum",
                required: true
              }]}>
                <DatePicker placeholder={"Selecteer datum"} style={{ width: "100%" }} format="DD-MM-YYYY" onChange={onDueDate} onOk={onOk} />
              </Form.Item>

            </Col>
          </RBAC>
          <Col span={12}>
            <Form.Item
              name="prio" rules={[{
                message: "Geef een prioriteit op",
                required: true
              }]}>
              <Select placeholder="Dag deel">
                <Option key={4} value={"1"}>Klant wacht op auto</Option>
                <Option key={1} value={"2"}>Voor 12 uur</Option>
                <Option key={2} value={"3"}>Voor 17 uur</Option>
                <Option key={3} value={"4"}>Einde dag</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Notities">
              <TextArea
                placeholder={"extra informatie"}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div>
          <h2 style={{ color: "#31a3ff", fontWeight: 700 }}>Voeg een taak toe</h2>
          <div style={{ textAlign: "right" }}> <span>Aantal taken</span><span style={{ fontSize: "28px", fontWeight: "bold" }}> {tasks.length}</span></div>
          {currentUser.role == 1 ?
            <Form.Item
              name="tasks" rules={[{ required: true, message: "Selecteer minimaal 1 taak" }]}>
              <Select placeholder="Selecteer taak" onChange={onTaskSelect} style={{ width: "100%" }} value={tasks} defaultValue={tasks} mode="tags">
                <Option key={1} value={"Wassen"}>Autowassen</Option>
                <Option key={3} value={"Stofzuigen"}>Stofzuigen</Option>
                <Option key={2} value={"Tanken"}>Tanken</Option>
                <Option key={4} value={"Aftersales"}>AfterSales</Option>
                <Option key={5} value={"Shortlease"}>Shortlease</Option>
              </Select>
            </Form.Item>
            :
            <Form.Item
              name="tasks" rules={[{ required: true }]}>
              <Select placeholder="Selecteer taak" onChange={onTaskSelect} style={{ width: "100%" }} value={tasks} defaultValue={tasks} mode="tags">
                <Option key={1} value={"Wassen"}>Autowassen</Option>
                <Option key={3} value={"Stofzuigen"}>Stofzuigen</Option>
              </Select>
            </Form.Item>
          }
        </div>
        {/* <List
          dataSource={tasks}
          renderItem={item => (<List.Item actions={[<a key="list-loadmore-edit">Verwijderen</a>]}>
            <List.Item.Meta
              title={item.value}
            />

          </List.Item>)}>

        </List> */}

        {/* <Form.Item>
          <Button data-testid="save-icle" className="icle-form-save" type="primary" htmlType="submit">
            Opslaan
          </Button>

        </Form.Item> */}
        <Button type="primary" htmlType="submit" disabled={formSaved} onClick={() => { close(); }} style={{
          position: "absolute", bottom: 0, left: 0, height: "50px", display: "inline-block",
          width: "100%"
        }}>
          Opslaan
        </Button>
      </Form>
      <div></div>
    </div >
  );
};

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  console.log("tags", label, props, closable, onClose)
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="kentekenTag"
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}
const list = [{
  name: "Stofzuigen"
}, {
  name: "Tanken"
}, {
  name: "wassen"
}];