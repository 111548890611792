import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import "./AllCarsScreen.css";
import _ from "lodash";
import formatLicenseplate from "../../licenseplateFormater";
import { CSVLink } from "react-csv";
import moment from "moment";
import { PieChartOutlined, RiseOutlined, CalculatorTwoTone, DownloadOutlined, BarChartOutlined, PieChartTwoTone, CalendarTwoTone } from "@ant-design/icons";
import {
  Table, Row, Col, Drawer, Button, Tag, Badge, Space, Card, Alert, Spin, Divider, Select, Form, Statistic

} from "antd";
import { AppContext } from "../../Providers/MainProvider";
import { CarScreenDesktop } from "../Hikes/Car/CarScreenDesktop";
import { VehicleContext } from "../../Providers/VehicleProvider";
import { DashBoardMenu } from "../../components/DashboardMenu";
import { AuthContext } from "../../Providers/Auth";
import { Vehicle } from "../../types/vehicle";
import fireStoreService from "../../fireStoreService";
import { TankEvent } from "../../../../lib/dist/types";
import { orange } from "@material-ui/core/colors";
const { Column } = Table;
const { Option } = Select;

export const TankHistoryScreen: React.FC = () => {
  const context = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);
  const currentVehicleContext = useContext(VehicleContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [DaysInThePast, setDaysInThePast] = useState<number>(7);
  const [tankEvents, setTankEvents] = useState<TankEvent[]>([]);
  const [vehicleDrawerisOpen, setVehicleDrawerisOpen] = useState<boolean>(false);
  const [audi, setAudi] = useState<number>(0);
  const [volkswagen, setVolkswagen] = useState<number>(0);
  const [wittebrug, setWittebrug] = useState<number>(0);
  const [dates, setDates] = useState<any>({});
  const [literCol, setLiterCol] = useState<any>({});
  const [filteredInfo, setFilteredInfo] = useState<{}>({});

  useEffect(() => {
    // context.searchCars("");
    const date = new Date();
    const fromDate = date.toLocaleDateString("fr-CA");
    date.setDate(date.getDate() - DaysInThePast);
    const toDate = date.toLocaleDateString("fr-CA");

    const unSubscribeToTankEvents = fireStoreService.subscribeToTankEvents(toDate, (data: any) => {
      setTankEvents(data);
      const grouped = _(data).groupBy("organisatie").map((deparment, index) => {
        return {
          organisatie: index,
          amount: _.sumBy(deparment, "liters")
        };
      }).value();
      // console.log("tank events", data)
      setDates({
        from: fromDate,
        to: toDate
      });
      setAudi(_.get(grouped.find(x => x.organisatie === "Audi"), "amount", 0));
      setVolkswagen(_.get(grouped.find(x => x.organisatie === "Volkswagen"), "amount", 0));
      setWittebrug(_.get(grouped.find(x => x.organisatie === "Wittebrug Verhuur"), "amount", 0));
      // setPending(false)
    });

    return () => {
      unSubscribeToTankEvents();
    };
  }, [DaysInThePast]);
  if (!context || !currentUser) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin></div>;
  }
  // tslint:disable-next-line: triple-equals
  if (currentUser && currentUser.role == 2) {
    return <div>
      <Alert
        message="Je hebt niet voldoende rechten om deze pagina te bekijken."
        description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
        type="info"
      />
    </div>;
  }
  const handleDateSwitch = (value: any) => {
    setDaysInThePast(value);
  };

  return (
    <div className="dashboard-screen">
      {/* <HikerMenu
        user={context.user}
        kenteken={context.kenteken}
        selectedCar={context.selectedCar}
      /> */}
      <DashBoardMenu context={context} />
      <div className="body-content">
        <Row>
          <Col><h1 className="page-header">Tank Historie
            <Select onChange={handleDateSwitch} className="historie-switcher" defaultValue="7" style={{ width: "200px", fontSize: "30px", height: "50px" }} bordered={false}>
              <Option value="120">120 dagen</Option>
              <Option value="90">90 dagen</Option>
              <Option value="60">60 dagen</Option>
              <Option value="30">30 dagen</Option>
              <Option value="14">14 dagen</Option>
              <Option value="7">7 dagen</Option>
              <Option value="0">vandaag</Option>
            </Select>
          </h1></Col>
        </Row>
        <Divider style={{ marginTop: "15px" }} />
        <Row style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Card bodyStyle={{ height: "120px", overflowX: "auto", width: "100%" }} className="dashboard-card">
              <Row gutter={16}>
                <Col span={4}>
                  <Statistic style={{ borderRight: "2px solid #f2f2f2" }} title="Afgelopen"
                    formatter={value => value} precision={0} value={DaysInThePast} prefix={<CalendarTwoTone />} suffix="Dagen" />
                </Col>
                <Col span={4}>
                  <Statistic style={{ borderRight: "2px solid #f2f2f2" }} title="Getankt" formatter={value => value} precision={0} value={tankEvents.length} prefix={<RiseOutlined style={{ color: "#08c" }} />} suffix="Keer" />
                </Col>
                <Col span={4}>
                  <Statistic style={{ borderRight: "2px solid #f2f2f2" }} title="Totaal"
                    formatter={value => value} precision={0} value={_.sumBy(tankEvents, (x) => x.liters)} prefix={<CalculatorTwoTone style={{ color: "#08c" }} />} suffix="Liter" />
                </Col>
                <Col span={4}>
                  <Statistic style={{ borderRight: "2px solid #f2f2f2" }}
                    title="Audi" formatter={value => value} precision={0} value={audi} prefix={<PieChartTwoTone style={{ color: "#87d068" }} />} suffix="Liter" />
                </Col>
                <Col span={4}>
                  <Statistic style={{ borderRight: "2px solid #f2f2f2" }} title="Wittebrug Verhuur" formatter={value => value} precision={0} value={wittebrug} prefix={<PieChartTwoTone style={{ color: "#2db7f5" }} />} suffix="Liter" />
                </Col>
                <Col span={4}>
                  <Statistic title="Volkswagen" formatter={value => value} precision={0} value={volkswagen}
                    prefix={<PieChartTwoTone color={"#2db7f5"} style={{ color: "#2db7f5" }} />} suffix="Liter" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Drawer
              headerStyle={{ textAlign: "center" }}
              width={700}
              title={currentVehicleContext.selectedVehicle ? <div> <strong>{currentVehicleContext.selectedVehicle.kenteken_pretty}</strong>
                <div>{currentVehicleContext.selectedVehicle.voertuig_model_omschrijving}</div> </div> : "Bewerk auto"}
              placement={"left"}
              closable={true}
              onClose={(e) => { setVehicleDrawerisOpen(false); }}
              visible={vehicleDrawerisOpen}
            >
              <CarScreenDesktop />
            </Drawer>
            <Card style={{ width: "100%", marginBottom: "20px" }} className="dashboard-card">
              <div style={{ marginBottom: "20px" }}>
                <CSVLink filename={`tankhistorie-${DaysInThePast}-${dates.from}-${dates.to}`}
                  data={tankEvents}><Button icon={<DownloadOutlined />}
                    data-testid="add-cart-btn" className="add-vehicle" style={{ width: "300px" }} size="large" shape="round">Download tank historie</Button> </CSVLink>
              </div>
              <Table dataSource={tankEvents} rowKey={(record, index) => `${record.kenteken}-${index}`} pagination={{ pageSize: 50 }}>
                <Column title="Kenteken" dataIndex="kenteken" key="kenteken"
                  sorter={(a: any, b: any) => a.kenteken.localeCompare(b.kenteken)}
                  render={(kenteken) => (<span><Tag className="kentekenTag">{formatLicenseplate(kenteken)}</Tag></span>)}
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      console.log("kenteken", value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })}
                // render={vehicle => (
                //   <img style={{ width: "80px", height: "auto" }} onError={addDefaultSrc} src={`/assets/cars/${vehicle.toLowerCase()}.png`} alt="car" />

                // )}
                />
                <Column title="Liters" dataIndex="liters" key="liters"
                  sorter={(a: any, b: any) => a.liters - b.liters}
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })}
                  render={liters => (<Badge style={{ backgroundColor: badgeColor(liters), fontWeight: "bold" }} size="default" count={liters} />)}
                />
                <Column title="Vloot" dataIndex="organisatie" key="organisatie"
                  sorter={(a: any, b: any) => a.organisatie.localeCompare(b.organisatie)}
                  filters={_.uniqBy((tankEvents.map((x: any) => {
                    return {
                      text: x.organisatie,
                      value: x.organisatie
                    };
                  })), "text")}
                  onFilter={(value, record: any) => record.organisatie.includes(value)}
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })}
                  render={organisatie => (<Tag style={{ fontSize: "14px", fontWeight: "bolder", borderRadius: "10px", ...colorMapping(organisatie) }}>{nameLabel(organisatie)} </Tag>)} />
                <Column title="Datum/tijd" dataIndex="event_datum" key="event_datum"
                  filters={_.uniqBy((tankEvents.map((x: any) => {
                    return {
                      text: moment(x.event_datum).format("ll"),
                      value: moment(x.event_datum).format("ll")
                    };
                  })), "text")}
                  onFilter={(value: string, record: any) => moment(record.event_datum).format("ll").includes(value)}
                  render={(date: any) => (
                    <span>
                      {moment(date).format("lll")}
                    </span>
                  )} />
                <Column title="Gebruiker" dataIndex="gebruikersnaam" key="gebruikersnaam"
                  sorter={(a: any, b: any) => a.gebruikersnaam.localeCompare(b.gebruikersnaam)}
                  filters={_.uniqBy((tankEvents.map((x: any) => {
                    return {
                      text: x.gebruikersnaam,
                      value: x.gebruikersnaam
                    };
                  })), "text")}
                  onFilter={(value, record: any) => record.gebruikersnaam.includes(value)}
                  onCell={(value: TankEvent, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })} />
              </Table>
            </Card>
          </Col>

        </Row>

      </div >
    </div >
  );
};

const colorStyle = {
  "Wittebrug Rent": {
    color: "#00B2F2",
    backgroundColor: "#e6f7ff",
    borderColor: "#00B2F2"
  },
  "Wittebrug Verhuur": {
    color: "#00B2F2",
    backgroundColor: "#e6f7ff",
    borderColor: "#00B2F2"
  },
  Wittebrug: {
    color: "#00B2F2",
    backgroundColor: "#e6f7ff",
    borderColor: "#00B2F2"
  },
  Volkswagen: {
    color: "#001e50",
    backgroundColor: "#cad0da",
    borderColor: "#001e50"
  },
  Audi: {
    color: "#f50437",
    backgroundColor: "#fbcad5",
    borderColor: "#f50437"
  },
  Seat: {
    color: "#f50437",
    backgroundColor: "#fbcad5",
    borderColor: "#f50437"
  }
};

const badgeColor = (liters: number) => {
  let color = "#52c41a";
  if (liters > 5) {
    color = "orange";
  }
  if (liters >= 26) {
    color = "#f50";
  }
  return color;
};

const nameLabel = (name: string) => {
  if (name === "Wittebrug Rent" || name === "Wittebrug") {
    name = "Wittebrug Verhuur";
  }
  return name;
};

const colorMapping = (organisatieName: string) => {
  return colorStyle[organisatieName] || "blue";
};
