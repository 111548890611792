import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import _ from "lodash";
import { DeleteOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Table, Row, Col, Drawer, Input, Button, Tabs, Dropdown, Result, Tag, Menu, Badge, Space, Card, Alert, Spin, Divider, Select, Form, Statistic

} from "antd";
import { AppContext } from "../../Providers/MainProvider";
import { VehicleContext } from "../../Providers/VehicleProvider";
import { DashBoardMenu } from "../../components/DashboardMenu";
import { AuthContext } from "../../Providers/Auth";
import { Vehicle } from "../../types/vehicle";
import "./OpdrachtenScreen.css";
import fireStoreService from "../../fireStoreService";
import { Team, Permission, WorkOrder } from "@internal/hikes";
import { WorkOrderProvider, WorkOrderContext } from "../../Providers/WorkOrderProvider";
import { RBAC } from "../../Rbac";
import { WorkOrderWizard } from './WorkOrderWizard';
import { WorkOrderDetails } from './WorkOrderDetails';
import moment from 'moment';
import { UserProviderContext } from '../../Providers/UserProvider';
const { Column } = Table;
const { Option } = Select;
const { TabPane } = Tabs;
interface Selected {
  drawerIsOpen: boolean;
  workOrder?: WorkOrder | null;
}
export const OpdrachtenSceen: React.FC = () => {
  const context = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);
  const { subscribeToWorkOrders, workOrders } = useContext(WorkOrderContext);
  const { getUserById } = useContext(UserProviderContext);
  const currentVehicleContext = useContext(VehicleContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [DaysInThePast, setDaysInThePast] = useState<number>(7);
  const [mainDrawerIsOpen, setMainDrawerIsOpen] = useState<boolean>(false);
  const [audi, setAudi] = useState<number>(0);
  const [volkswagen, setVolkswagen] = useState<number>(0);
  const [wittebrug, setWittebrug] = useState<number>(0);
  const [selected, setSelected] = useState<Selected>({ drawerIsOpen: false, workOrder: null });
  const [literCol, setLiterCol] = useState<any>({});
  const [filteredInfo, setFilteredInfo] = useState<{}>({});
  const [createteamDrawerIsOpen, setCreateteamDrawerIsOpen] = useState<boolean>(true);

  useEffect(() => {
    // context.searchCars("");
    const date = new Date();
    const fromDate = date.toLocaleDateString("fr-CA");
    date.setDate(date.getDate() - DaysInThePast);
    const toDate = date.toLocaleDateString("fr-CA");
    subscribeToWorkOrders();
    console.log("work orders", workOrders);
  }, []);
  if (!context || !currentUser) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin></div>;
  }
  if (currentUser && currentUser.role === 2) {
    return <div>
      <Alert
        message="Je hebt niet voldoende rechten om deze pagina te bekijken."
        description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
        type="info"
      />
    </div>;
  }
  const handleButtonClick = (e: any, workOrder: any) => {
    setSelected({
      drawerIsOpen: true,
      workOrder
    });
    setMainDrawerIsOpen(true);
  };

  const handleClose = () => {
    setSelected({
      drawerIsOpen: false,
      workOrder: null
    });
  };
  const deleteRecord = (record) => {
    fireStoreService.deleteWorkOrder(record.id);
    // console.log("Delete", record);
  };
  const handleSearch = (search) => {
    console.log("search", search);
  }

  const handleCreateWorkOrder = () => {
    /// Set ReactGA Event
    ReactGA.event({
      category: "Opdrachten",
      action: "Nieuwe Opdracht maken",
      label: `${currentUser?.first_name} heeft een nieuwe opdracht aangemaakt`,
      value: 1
    });
    setCreateteamDrawerIsOpen(true);
    setSelected({
      drawerIsOpen: true,
      workOrder: null
    });
  };

  const handleMenuClick = (record: Team) => {
  };
  const { Search } = Input;

  const CustomMenu = ({ record }) => (
    <Menu onClick={() => handleMenuClick(record)}>
      <Menu.Item key="1">
        <Button onClick={() => deleteRecord(record)} icon={<DeleteOutlined />} danger shape="round">Verwijderen</Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="dashboard-screen">
      {/* <HikerMenu
        user={context.user}
        kenteken={context.kenteken}
        selectedCar={context.selectedCar}
      /> */}
      <DashBoardMenu context={context} />
      <div className="body-content">
        <Row>
          <Col><h1 className="page-header">Opdrachten</h1></Col>
        </Row>
        <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Col span={24}>
            {/* <Input prefix={<SearchOutlined />} placeholder={"Zoek een opdracht"} onChange={handleSearch} allowClear style={{
              width: "400px",
              height: "45px",
              borderRadius: "15px",
              border: "none"
            }} /> */}
            <Button icon={<PlusOutlined style={{ fontWeight: 800 }} />} shape="round" type="primary" onClick={handleCreateWorkOrder} size={"large"} style={{
              height: "50px",
              width: "300px",
              fontWeight: 800
            }} >Nieuwe opdracht aanmaken</Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Drawer
              headerStyle={{ textAlign: "center" }}
              width={700}
              title={"Opdrachten"}
              destroyOnClose={true}
              placement={"left"}
              closable={true}
              onClose={(e) => {
                setSelected({
                  drawerIsOpen: false,
                  workOrder: null
                });
              }}
              visible={selected.drawerIsOpen}
            >
              {selected.workOrder ? <WorkOrderDetails close={handleClose} data={selected.workOrder} /> : <WorkOrderWizard />}

            </Drawer>
            {/* <div>
              <label>Filters</label>
              <Button type="link">Klaar</Button>
              <Button type="link">in behandeling</Button>
              <Button type="link">Open</Button>
            </div> */}
            <Table className="opdrachtenTable" dataSource={workOrders} rowKey={(record, index) => `${record.id}-${index}`} pagination={{ pageSize: 50 }}>
              <Column title="Status" dataIndex="state" key="state"
                sorter={(a: any, b: any) => a.state - b.state}
                render={(state: string) => (<span>{stateLabel[state]}</span>)}
                // filteredValue={["Klaar"] || null}
                filters={_.uniqBy((workOrders.map((x: any) => {
                  return {
                    text: stateLabel[x.state],
                    value: stateLabel[x.state]
                  };
                })), "text")}
                onFilter={(value, record: any) => record.state == stateLabelReverse[value]}
                onCell={(value: Vehicle, index) => ({
                  onClick: () => {
                    setMainDrawerIsOpen(true);
                  }

                })}
              />
              <Column title="Prioriteit" dataIndex="priority" key="email"
                render={priority => (<Tag style={{ fontSize: "14px", fontWeight: "bolder", borderRadius: "10px", ...colorMapping(priority) }}>
                  {prioMapping(priority)}</Tag>)}
              />
              <Column title="Aangemaakt door" dataIndex="createdBy" key="createdBy"
                render={createdBy => (<div>{createdBy}</div>)}
              />
              <Column title="In behandeling door" dataIndex="assignee" key="assignee"
                render={assignee => (<div>{assignee}</div>)}
              />
              <Column title="Datum" dataIndex="dueDate" key="dueDate"
                render={date => (<div>{moment(date).format("ll")}</div>)}
              />
              <Column title="Kenteken" dataIndex={["vehicleAttachment", "kenteken_pretty"]} key="kenteken"
                render={kenteken => (<Tag className="kentekenTag">{kenteken}</Tag>)}
              />
              <Column title="Acties" dataIndex="id" key="id"
                render={(id, record) => (<div>
                  <RBAC permissionsNeeded={["opdrachten:bewerken"]} currentUserPermissions={currentUser.permissions} >
                    <Dropdown.Button onClick={(e) => handleButtonClick(e, record)} overlay={<CustomMenu record={record} />}>
                      Bewerken
                    </Dropdown.Button>
                  </RBAC>
                </div>)}
              />
            </Table>
          </Col>
        </Row>
      </div >
    </div >
  );
};

const prioLibrary = {
  1: "Spoed",
  2: "Voor 12:00 uur",
  3: "Voor 17:00 uur",
  4: "Einde dag"
};

const prioMapping = (prio: string) => {
  return prioLibrary[prio];
};


const colorStyle = {
  4: {
    color: "#00B2F2",
    backgroundColor: "#e6f7ff",
    borderColor: "#00B2F2"
  },
  3: {
    color: "#001e50",
    backgroundColor: "#cad0da",
    borderColor: "#001e50"
  },
  2: {
    color: "#f50437",
    backgroundColor: "#fbcad5",
    borderColor: "#f50437"
  },
  1: {
    color: "#f50437",
    backgroundColor: "#fbcad5",
    borderColor: "#f50437"
  }
};

const stateLabel = {
  0: "Open",
  1: "In behandeling",
  2: "Klaar"
};

const stateLabelReverse = {
  "Open": 0,
  "In behandeling": 1,
  "Klaar": 2
};
const badgeColor = (liters: number) => {
  let color = "#52c41a";
  if (liters > 5) {
    color = "orange";
  }
  if (liters >= 26) {
    color = "#f50";
  }
  return color;
};

const nameLabel = (name: string) => {
  if (name === "Wittebrug Rent" || name === "Wittebrug") {
    name = "Wittebrug Verhuur";
  }
  return name;
};

const colorMapping = (organisatieName: string) => {
  return colorStyle[organisatieName] || "blue";
};
