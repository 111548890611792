import * as Sentry from "@sentry/browser";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { unformatLicenseplate } from "./licenseplateFormater";
import { VehicleEvent, TankEvent, Vehicle, Team } from "@internal/hikes";
import { User } from './types/user';
import firebase from "firebase/app";
import { WorkOrder } from '../../lib/dist/types';

let tankEventsCache = [];
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
const firebaseApp = app.initializeApp(firebaseConfig);
firebase.analytics();
const db = firebaseApp.firestore();
if (process.env.NODE_ENV !== "production") {
  // firebase.firestore.setLogLevel('debug');
}


class FireStore {
  public async getCarByKenteken(kenteken: string) {
    const data = unformatLicenseplate(kenteken);
    const ref = db.doc(`cars/${data}`);
    try {
      const docSnapShot = await ref.get();
      return docSnapShot.data() as Vehicle;
    } catch (error) {
      // console.log("env from node", process.env.NODE_ENV)
      // console.log("the error", error)
      Sentry.captureException(error);
    }
  }
  public async getHistoryForCar(kenteken: string) {
    const ref = db.collection(`vehicle_events`).where("kenteken", "==", kenteken).orderBy("event_datum", "desc").limit(10);
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as VehicleEvent[];
      const start = querySnapShot.docs[querySnapShot.docs.length - 1];
      console.log("start position", start, "snapshot", querySnapShot.docs);
      querySnapShot.forEach(result => {
        const item = result.data() as VehicleEvent;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getHistoryForCar", relatedVechiles.length);
      }
      return {
        start,
        data: relatedVechiles || []
      };
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("getHistoryForCar", error);
      }
      Sentry.captureException(error);
      return "error";

    }
  }

  public async getHistoryForCarMore(kenteken: string, start: any) {
    const ref = db.collection(`vehicle_events`).where("kenteken", "==", kenteken).orderBy("event_datum", "desc").startAfter(start).limit(5);
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as VehicleEvent[];
      const start = querySnapShot.docs[querySnapShot.docs.length - 1];
      querySnapShot.forEach(result => {
        const item = result.data() as VehicleEvent;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getHistoryForCar", relatedVechiles.length);
      }
      return {
        start,
        data: relatedVechiles || []
      };
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("getHistoryForCar", error);
      }
      Sentry.captureException(error);
      return "error";

    }
  }
  public async getTankEventsByKenteken(endTime: string, kenteken: string) {
    const ref = db.collection("/tank_events").where("kenteken", "==", kenteken).where("event_datum", ">", endTime).orderBy("event_datum", "desc").limit(20);
    try {
      const querySnapShot = await ref.get();
      const start = querySnapShot.docs[querySnapShot.docs.length - 1];
      const relatedVechiles = [] as TankEvent[];
      querySnapShot.forEach(result => {
        const item = result.data() as TankEvent;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getTankEventsByKenteken", relatedVechiles.length);
      }
      return {
        start,
        data: relatedVechiles || []
      };
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("getHistoryForCar", error);
      }
      Sentry.captureException(error);
      return "error";

    }
  }
  public async getTankEventsByKentekenMore(kenteken: string, start: any) {
    const ref = db.collection(`/tank_events`).where("kenteken", "==", kenteken).orderBy("event_datum", "desc").startAfter(start).limit(20);
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as TankEvent[];
      const start = querySnapShot.docs[querySnapShot.docs.length - 1];
      querySnapShot.forEach(result => {
        const item = result.data() as TankEvent;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getHistoryForCar", relatedVechiles.length);
      }
      return {
        start,
        data: relatedVechiles || []
      };
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("getHistoryForCar", error);
      }
      Sentry.captureException(error);
      return "error";

    }
  }
  public async getHistory(cb: any, date: string) {
    console.log("get history", date);
    const ref = db.collection(`vehicle_events`).where("event_datum", ">", date).orderBy("event_datum", "desc");
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as VehicleEvent[];
      querySnapShot.forEach(result => {
        const item = result.data() as VehicleEvent;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched", relatedVechiles.length);
      }
      return cb(relatedVechiles || []);
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("getHistoryForCar", error);
      }
      Sentry.captureException(error);
      return "error";

    }
  }
  public forgotPassword = (email: string, cb: (user: any, err: any) => void) => {
    firebaseApp.auth().sendPasswordResetEmail(email)
      .then((user: any) => {
        cb(user, null);
      }).catch((e: any) => {
        cb(null, e);
        Sentry.captureException(e);
      });
  }
  public async getAll(path: string) {
    const ref = db.collection(path);
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as Vehicle[];
      querySnapShot.forEach(result => {
        const item = result.data() as Vehicle;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getAll", relatedVechiles.length);
      }
      return relatedVechiles;
    } catch (error) {
      // console.log("error", error);
      Sentry.captureException(error);
    }
  }
  public async getAllCars() {
    const ref = db.collection("cars");
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as Vehicle[];
      querySnapShot.forEach(result => {
        const item = result.data() as Vehicle;
        relatedVechiles.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("items fetched getAllCars", relatedVechiles.length);
      }
      return relatedVechiles;
    } catch (error) {
      // console.log("error", error);
      Sentry.captureException(error);
    }
  }
  public async getRelatedCars(kenteken: string, type: string) {
    const ref = db.collection(`cars`).where("model", "==", type).limit(10);
    try {
      const querySnapShot = await ref.get();
      const relatedVechiles = [] as Vehicle[];
      querySnapShot.forEach(result => {
        if (result.data().kenteken !== kenteken) {
          const item = result.data() as Vehicle;
          relatedVechiles.push(item);
        }
      });
      return relatedVechiles;
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  public isAuhtenticated() {
    return localStorage.getItem("user") !== null;
  }

  public async createProfile(username: string, password: string, profile: any) {
    const request = {
      username,
      password,
      ...profile
    };
    const createUser = firebaseApp.functions("europe-west1").httpsCallable("createUser");
    firebaseApp.functions();
    return createUser(request);
  }
  public async createCar(values: any) {
    const request = {
      versie: 1,
      ...values
    };
    const createVehicle = firebaseApp.functions("europe-west1").httpsCallable("createVehicle");
    firebaseApp.functions();
    return createVehicle(request);
  }
  public async createCarLocal(values: any) {
    const request = {
      versie: 1,
      ...values
    };
    const createVehicle = firebaseApp.functions("europe-west1").httpsCallable("createVehicle");
    firebaseApp.functions();
    return createVehicle(request);
  }




  public getUserProfile() {
    return localStorage.getItem("user");
  }
  public async updateCarByKenteken(kenteken: string, data: any) {
    const ref = db.doc(`cars/${kenteken}`);
    try {
      await ref.set(data, { merge: true });
      if (process.env.NODE_ENV !== "production") {
        console.log("update car", data);
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async saveVehicleEvent(data: any) {
    try {
      const result = await db.collection("vehicle_events").doc().set(data);
      if (process.env.NODE_ENV !== "production") {
        console.log("saved new vehicle event in database", data.id, data);
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async saveWorkOrder(data: WorkOrder) {
    const ref = db.doc(`workOrders/${data.id}`);
    try {
      await ref.set(data, { merge: true });
      if (process.env.NODE_ENV !== "production") {
        console.log("save workOrder", data);
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async deleteWorkOrder(id: string) {
    const ref = db.doc(`workOrders/${id}`);
    try {
      await ref.delete();
      if (process.env.NODE_ENV !== "production") {
        console.log("Document successfully deleted!");
      }
      return true;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async deleteTeam(id: string) {
    const ref = db.doc(`teams/${id}`);
    try {
      await ref.delete();
      if (process.env.NODE_ENV !== "production") {
        console.log("Document successfully deleted!");
      }
      return true;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async saveTeam(data: Team) {
    const ref = db.doc(`teams/${data.id}`);
    try {
      await ref.set(data, { merge: true });
      if (process.env.NODE_ENV !== "production") {
        console.log("save team", data);
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }

  public async editPermissionsOnAllUsers(teamId: string, permissions: string[], removedPermissions: string[], addedPermissions: string[]) {
    if (process.env.NODE_ENV !== "production") {
      console.log("update permission on all users", teamId, permissions);
      console.log("add these permissions for all users", addedPermissions);
      console.log("remove these permissions for all users", removedPermissions);
    }

    const permissionsArray = permissions.map(permission => `${teamId}|${permission}`);
    const permissionsToRemove = removedPermissions.map(permission => `${teamId}|${permission}`);
    const permissionsToAdd = addedPermissions.map(permission => `${teamId}|${permission}`);
    // console.log("permission", permissionsArray);
    try {
      const allUsersWithTeamRef = db.collection(`users`).where("teams", "array-contains", teamId);
      const allUsersWithTeam = await allUsersWithTeamRef.get();
      allUsersWithTeam.docs.forEach(async (doc) => {
        const user = await doc.data() as User;
        this.saveTeamUser(user.uid, teamId, permissionsArray, permissionsToRemove, permissionsToAdd);
        console.log("update permission TODO", user);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("update user with success");
      }
      return [];
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async addPermissions(uid: string, permissions: any) {
    console.log("add permission", uid, permissions);
    const ref = db.doc(`users/${uid}`);
    try {
      await ref.update({
        permissions: firebase.firestore.FieldValue.arrayUnion(...permissions)
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("added permission with success");
      }
      return [];
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async removePermissions(uid: string, permissions: any) {
    console.log("add permission", uid, permissions);
    const ref = db.doc(`users/${uid}`);
    try {
      await ref.update({
        permissions: firebase.firestore.FieldValue.arrayRemove(...permissions)
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("added permission with success");
      }
      return [];
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }

  public async saveTankEvent(data: any) {
    try {
      const result = await db.collection("tank_events").doc().set(data);
      if (process.env.NODE_ENV !== "production") {
        console.log("saved new tank event in database");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }

  public async saveUnKownVehicle(data: any) {
    try {
      const result = await db.collection("unkown_vehicles").doc().set(data);
      if (process.env.NODE_ENV !== "production") {
        console.log("saved UnKownVehicle in database");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async saveTeamUser(userId: string, teamId: string, permissions: string[], removedPermissions: string[], addedPermissions: string[]) {
    const ref = db.doc(`users/${userId}`);
    try {

      await ref.update({
        teams: firebase.firestore.FieldValue.arrayUnion(teamId)
      });
      if (removedPermissions?.length) {
        if (process.env.NODE_ENV !== "production") {
          console.log("remove permissions", removedPermissions);
        }
        await ref.update({
          permissions: firebase.firestore.FieldValue.arrayRemove(...removedPermissions)
        });
      }
      if (addedPermissions?.length) {
        if (process.env.NODE_ENV !== "production") {
          console.log("added permissions", addedPermissions);
        }
        await ref.update({
          permissions: firebase.firestore.FieldValue.arrayUnion(...permissions)
        });
      }
      const user = await ref.get();
      const result = await user.data() as User;
      console.log("result")
      if (process.env.NODE_ENV !== "production") {
        console.log("update user with success");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async removeUserFromTeam(userId: string, teamId: string, permissions: string[]) {
    const ref = db.doc(`users/${userId}`);
    try {
      const user = await ref.get();
      const result = await user.data() as User;
      const data = result.permissions.filter((permission) => permission.startsWith(teamId));
      console.log("data for user", data);
      await ref.update({
        teams: firebase.firestore.FieldValue.arrayRemove(teamId)
      });
      if (data.length) {
        await ref.update({
          permissions: firebase.firestore.FieldValue.arrayRemove(...data)
        });
      }

      // const user = await ref.get();
      // const result = await user.data() as User;
      if (process.env.NODE_ENV !== "production") {
        console.log("removed user with success");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async addUserToTeamUser(userId: string, teamId: string, permissions: string[]) {
    const ref = db.doc(`users/${userId}`);
    try {
      const permissionsToAdd = permissions.map(permission => `${teamId}|${permission}`);
      await ref.update({
        teams: firebase.firestore.FieldValue.arrayUnion(teamId),
        permissions: firebase.firestore.FieldValue.arrayUnion(...permissionsToAdd)
      });

      const user = await ref.get();
      const result = await user.data() as User;
      if (process.env.NODE_ENV !== "production") {
        console.log("update user with success");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public async deleteTeamUser(userId: string, teamId: string, permissions: string[]) {
    console.log("Delete permission", userId, teamId);
    const ref = db.doc(`users/${userId}`);
    try {
      const user = await ref.get();
      const result = await user.data() as User;
      // logic to keep duplicate permissions
      await ref.update({
        teams: firebase.firestore.FieldValue.arrayRemove(teamId),
        permissions: firebase.firestore.FieldValue.arrayRemove(...permissions)
      });
      console.log("result");
      if (process.env.NODE_ENV !== "production") {
        console.log("update user with success");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }

  public async saveNewVehicle(data: any) {
    try {
      const result = await db.collection("new_vehicles").doc().set(data);
      if (process.env.NODE_ENV !== "production") {
        console.log("saved new vehicles in database");
      }
      return result;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }

  public users(uid: string) {
    return db.doc(`organizations/${uid}/contacts`).collection;
  }
  public logout() {
    firebaseApp
      .auth()
      .signOut()
      .then(
        () => {
          // Sign-out successful.
          // console.log("success logout");
        },
        (error) => {
          // An error happened.
          Sentry.captureException(error);
        }
      );
  }
  public async login(username: string, password: string) {
    return firebaseApp.auth().signInWithEmailAndPassword(username, password);
  }

  public async getCurrentUser() {
    console.log("current user", await firebaseApp.auth().currentUser);
    return firebaseApp.auth().currentUser;
  }
  // public getTankEventsByKenteken(endtime: string, callBack: (data: any[]) => void) {
  //   return db.collection("/tank_events").where("event_datum", ">", endtime).orderBy("event_datum", "desc").limit(500).onSnapshot((snapShot) => {
  //     const events = [] as any;
  //     snapShot.forEach(result => {
  //       const item = result.data();
  //       events.push(item);
  //     });
  //     callBack(events);
  //   });
  // }

  public async onAuthUserListener(next: any, fallback: any) {
    try {
      firebaseApp.auth().onAuthStateChanged(async (authUser) => {
        if (authUser) {

          const userProfile = await this.getUserByUid(authUser.uid);
          if (userProfile) {
            const user = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...userProfile.data()
            };
            next(user);
          } else {
            fallback("no user profile for this user");
          }
        } else {
          fallback("Geen ingelogde gebruiker");
        }
      });
    } catch (error) {
      console.log("auth error", error);
      Sentry.captureException(error);
    }
  }

  public async getUserByUid(uid: string) {
    try {
      const ref = db.collection(`users`).doc(uid);
      const user = await ref.get();
      return user;
    } catch (error) {
      console.log("get error", error)
    }

  }

  public async getItems(uid: string) {
    try {
      const collection = db
        .doc(`/organizations/${uid}`)
        .collection("items");
      const data = await collection.get();
      return this.transformResponse(data);
    } catch (error) {
      console.log(error);
    }
  }

  public subscribe(path: string, startDate: string, endDate: string, callBack: (data: Vehicle[]) => void) {
    return db.collection(`${path}`).orderBy("laast_aangepast_op", "desc").startAt(startDate).endAt(endDate).limit(500).onSnapshot((snapShot) => {
      const relatedVechiles = [] as Vehicle[];
      snapShot.forEach(result => {
        const item = result.data() as Vehicle;
        relatedVechiles.push(item);
      });
      callBack(relatedVechiles);
    });
  }

  public subscribeToResources(path: string, callBack: (data: Vehicle[]) => void, limit = 500) {
    console.log(path);
    return db.collection(`${path}`).limit(limit).onSnapshot((snapShot) => {
      const data = [] as any;
      snapShot.forEach(result => {
        const item = result.data() as Vehicle;
        data.push(item);
      });
      callBack(data);
    });
  }

  public subscribeToHistory(date: string, callBack: (data: Vehicle[]) => void) {
    return db.collection("/vehicle_events").orderBy("event_datum", "desc").where("event_datum", ">=", date).limit(500).onSnapshot((snapShot) => {
      const relatedVechiles = [] as Vehicle[];
      snapShot.forEach(result => {
        const item = result.data() as Vehicle;
        relatedVechiles.push(item);
      });
      callBack(relatedVechiles);
    });
  }

  public subscribeToVehicles(path: string, callBack: (data: Vehicle[]) => void) {
    const limit = process.env.REACT_APP_VEHICLE_LIMIT ? parseInt(process.env.REACT_APP_VEHICLE_LIMIT) : 10;
    return db.collection(`${path}`).orderBy("laast_aangepast_op", "desc").where("isDeleted", "==", false).limit(limit).onSnapshot((snapShot) => {
      const relatedVechiles = [] as Vehicle[];
      snapShot.forEach(result => {
        const item = result.data() as Vehicle;
        relatedVechiles.push(item);
      });
      callBack(relatedVechiles);
    });
  }

  public subscribeToTankEvents(today: string, callBack: (data: any[]) => void) {
    return db.collection("/tank_events").where("event_datum", ">", today).orderBy("event_datum", "desc").limit(1000).onSnapshot((snapShot) => {
      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      tankEventsCache = events;
      callBack(events);
    });
  }
  public subscribeToUnkownUpdates(callBack: (data: any[]) => void) {
    const date = new Date();
    date.setDate(date.getDate() - 3);
    const today = date.toLocaleDateString("fr-CA");
    return db.collection("unkown_vehicles").where("event_datum", ">", today).orderBy("event_datum", "desc").limit(100).onSnapshot((snapShot) => {
      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      callBack(events);
    });
  }

  public subscribeToCar(path: string, callBack: (data: any) => void) {
    return db.doc(`${path}`).onSnapshot((snapShot) => {
      const events = [] as any;
      callBack(snapShot.data());
    });
  }

  public removeFromQuarintine(path: string, callBack: (data: any) => void) {
    return db.doc(`${path}`).onSnapshot((snapShot) => {
      const events = [] as any;
      callBack(snapShot.data());
    });
  }

  public subscribeToUsers(callBack: (data: any) => void) {
    return db.collection("users").orderBy("created_at", "desc").onSnapshot((snapShot) => {

      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      if (process.env.NODE_ENV !== "production") {
        console.log("subscribeToUsers", events);
      }
      callBack(events);
    });
  }

  public subscribeToWorkOrders(callBack: (data: any) => void) {
    return db.collection("workOrders").onSnapshot((snapShot) => {
      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      callBack(events);
    });
  }

  public subscribeToSubSelectionWorkOrders(callBack: (data: any) => void, userRole: number) {
    return db.collection("workOrders").where("createdByRole", "==", userRole).onSnapshot((snapShot) => {
      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      callBack(events);
    });
  }
  public subscribeToActiveOrders(callBack: (data: any) => void, userRole: number) {
    return db.collection("workOrders").where("state", "<=", 1).onSnapshot((snapShot) => {
      const events = [] as any;
      snapShot.forEach(result => {
        const item = result.data();
        events.push(item);
      });
      callBack(events);
    });
  }

  public async updateOrCreateVehicle(vehicle: Vehicle) {
    const ref = db.doc(`cars/${vehicle.kenteken}`);
    try {
      await ref.set(vehicle, { merge: true });
      if (process.env.NODE_ENV !== "production") {
        console.log("updateOrCreateVehicle", vehicle);
      }
      return vehicle;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log("error", error);
      }
      Sentry.captureException(error);
    }
  }
  public transformResponse(data: any) {
    return data.docs.map((item: any) => {
      return item.data();
    });
  }
}
export default new FireStore();
