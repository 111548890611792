import React, { useContext } from "react";
import { AppContext } from "../Providers/MainProvider";
import { Form, Input, Button, Select, Tag } from "antd";

export const NewVehicleForm = () => {
    const context = useContext(AppContext);
    const { Option } = Select;
    const handleChange = (value: string) => {
    };
    return (
        <Form size="large" initialValues={{
            "owner": "Wittebrug Verhuur",
            "priority": "1",
        }} style={{ marginTop: "0" }} onFinish={(values) => { context.createVehicle(values); }} layout="vertical" wrapperCol={{ span: 24 }}>
            <Form.Item name="kenteken" rules={[{ required: true }]} label="Kenteken zonder streepjes">
                <Input data-testid="kenteken-form-input" />
            </Form.Item>
            <Form.Item name="model" rules={[{ required: true }]} label="Model">
                <Input
                    data-testid="kenteken-model-input"
                    type="text"
                />
            </Form.Item>
            <Form.Item name="owner" label="Eigenaar" rules={[{ required: false }]}>
                <Select data-testid="kenteken-owner-input" style={{ width: "100%" }}>
                    <Option value="Wittebrug Verhuur">Wittebrug Verhuur</Option>
                    <Option value="Euromobil">Euromobil</Option>
                    <Option value="Klant">Klant</Option>
                </Select>
            </Form.Item>
            <Form.Item name="priority" label="Prioriteit" rules={[{ required: false }]}>
                <Select data-testid="kenteken-priority-input" style={{ width: "100%" }} onChange={handleChange}>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                </Select>
            </Form.Item>
            <Form.Item name="taken" label="Taken" rules={[{ required: false }]}>

                <Select
                    data-testid="kenteken-tasks-input"
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    style={{ width: '100%' }}
                >
                    <Option value="Schoonmaken">Schoonmaken</Option>
                    <Option value="Tanken">Tanken</Option>
                    <Option value="Wassen">Wassen</Option>

                </Select>
            </Form.Item>
            {/* <Form.Item name="status" label="verzorging" rules={[{ required: false }]}>

                <Select
                    data-testid="kenteken-tasks-input"
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    style={{ width: '50%' }}
                >
                    <Option value="Schoonmaken">Schoon</Option>
                    <Option value="Tanken">Vies</Option>

                </Select>
            </Form.Item>
            <Form.Item name="tank" label="Tankinhoud" rules={[{ required: false }]}>
                <Select
                    data-testid="kenteken-tasks-input"
                    mode="multiple"
                    showArrow
                    style={{ width: '50%' }}
                >
                    <Option value="Schoonmaken">25%</Option>
                    <Option value="Tanken">50%</Option>
                    <Option value="Tanken">75%</Option>
                    <Option value="Tanken">100%</Option>

                </Select>
            </Form.Item> */}
            {/* <Form.Item name="filliaal" label="Filliaal">
                <Input
                    type="text"
                />
            </Form.Item> */}
            {/* <Form.Item name="inzet" rules={[{ required: true }]} label="Inzet administratie">
                <Input
                    type="text"
                />
            </Form.Item> */}
            <Form.Item>
                <Button data-testid="save-vehicle" className="vehicle-form-save" type="primary" htmlType="submit">
                    Opslaan
        </Button>
            </Form.Item>
        </Form >);
};

const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;

    return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    );
}