import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import HomeScreen from "./Screens/Hikes/Home/HomeScreen";
import LoginScreen from "./Screens/Login/LoginScreen";
import SplashScreen from "./Screens/SplashScreen";
import { CarScreen } from "./Screens/Hikes/Car/CarScreen";
import "antd/dist/antd.css";
import "antd-mobile/dist/antd-mobile.css";
import { BrowserRouter as Router, Route, Redirect, RouteProps, RouteComponentProps, Switch } from "react-router-dom";
import AppProvider from "./Providers/MainProvider";
// import fireStoreService from "./fireStoreService";
import UploadScreen from "./Screens/Upload/UploadScreen";
import { useAppContext } from "./Providers/MainProvider";
import { User } from "./types/";
import fireStoreService from "./fireStoreService";
import DashboardScreen from "./Screens/Dashboard/DashboardScreen";
import { AllCarsDashboardScreen } from "./Screens/AllCarsDashboard/AllCarsDashboardScreen"
import { TankHistoryScreen } from "./Screens/TankHistory/TankhistoryScreen";
import UserScreen from "./Screens/Users/UserScreen";
import KanbanScreen from "./Screens/Kanban/KanbanScreen";
import { CarScreenBeta } from "./Screens/Hikes/Car/CarScreenBeta";
import ReactGA from "react-ga";
import {
  Alert, Spin

} from "antd";
import {
  useWindowSize
} from "@react-hook/window-size";
import { AuthProvider, AuthContext } from "./Providers/Auth";
import { VehicleProvider } from "./Providers/VehicleProvider"
import { TeamsScreen } from "./Screens/Teams/TeamsScreen";
import { UserProvider } from "./Providers/UserProvider";
import { WorkOrderProvider } from "./Providers/WorkOrderProvider";
import { OpdrachtenSceen } from "./Screens/Opdrachten/OpdrachtenScreen";
import { MobileHomeScreen } from './mobileapp/Home/MobileHomeScreen';


type logType = (message: string) => void

declare global {
  interface Window {
    log: logType;
  }
}
window.log = (...msgs: any[]) => {
  if (process.env.NODE_ENV === "development") { console.log(...msgs); };
};
const App = () => {
  const { user } = useAppContext();
  // @ts-ignore

  const { currentUser } = useContext(AuthContext);
  const [width] = useWindowSize();
  // const [user, setUser] = useState();

  // useEffect(() => {
  //   fireStoreService.onAuthUserListener(
  //     (firebaseUser: any) => {
  //       console.log("from app.tsx", firebaseUser)
  //       // setLoading(false);
  //       // Set UA
  //       setUser(firebaseUser);
  //     },
  //     (error: any) => {
  //       console.log("what is the error", error);
  //     }
  //   );
  // }, []);

  // if (!user) {
  //   return <div> <Spin tip="Loading...">
  //     <Alert
  //       message="Gegevens worden opgehaald"
  //       description="een ogenblik geduld"
  //       type="info"
  //     />
  //   </Spin></div>;
  // }
  return (
    // if not logged in route to login else either dashboard or Home screen

    <Router>
      <AuthProvider>
        <AppProvider>
          <WorkOrderProvider>
            <VehicleProvider>
              <UserProvider>
                <div className="full-height">
                  <Switch>
                    <RouteWhenAuthBased
                      authState={currentUser}
                      exact
                      path="/"
                      component={MobileHomeScreen}
                    />
                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/dashboard"
                      component={DashboardScreen}
                    />
                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/zoeken"
                      component={MobileHomeScreen}
                    />
                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/tank-historie"
                      // component={HomeScreenListView}
                      component={TankHistoryScreen}
                    />
                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/autos"
                      // component={HomeScreenListView}
                      component={AllCarsDashboardScreen}
                    />

                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/teams"
                      component={TeamsScreen}
                    />
                    <RouteWhenAuthorized
                      authState={currentUser}
                      exact
                      path="/opdrachten"
                      component={OpdrachtenSceen}
                    />


                    {/* <Route path="/Car" component={CarScreenBeta} />
              <Route path="/splash" component={SplashScreen} /> */}

                    <RouteWhenAuthorized authState={currentUser} exact path="/gebruikers" component={UserScreen} />

                    {/* <Route exact path="/toevoegen" component={UploadScreen} />
              <Route exact path="/autos" component={AllCarsDashboardScreen} />
              <Route exact path="/auto/:kenteken" component={CarScreen} />
              <Route exact path="/Kanban" component={KanbanScreen} /> */}
                    <Route path="/login" component={LoginScreen} />
                  </Switch>
                </div>
              </UserProvider>
            </VehicleProvider>
          </WorkOrderProvider>
        </AppProvider>

      </AuthProvider>
    </Router>

  );
};

export default App;

interface AuthState {
  authState: User | null;
}
interface PrivateRouteProps extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}
const RouteWhenAuthorized: React.FC<PrivateRouteProps & AuthState> = ({ component: Component, authState, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      path="/dashboard"
      render={renderProps =>
        currentUser ? (
          <Component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: renderProps.location }
            }}
          />
        )
      }
    />
  );
};
const RouteWhenAuthBased: React.FC<PrivateRouteProps & AuthState> = ({ component: Component, authState, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={renderProps =>
        currentUser ? (
          <Component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: renderProps.location }
            }}
          />
        )
      }
    />
  );
};
const RouteWhenAuthorizedAndAdmin: React.FC<PrivateRouteProps & AuthState> = ({ component: Component, authState, ...rest }) => (
  <Route
    {...rest}
    render={renderProps => {
      console.log("autstate", authState);
      return fireStoreService.isAuhtenticated() || authState && authState.role == 1 ? (
        <Component {...renderProps} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: renderProps.location }
          }}
        />
      )
    }
    }

  />
);
interface PrivateRouteProps extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}
// const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
//   component: Component,
//   ...rest
// }) => {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         true ? (
//           <Component {...props} />
//         ) : (
//             <Redirect
//               to={{
//                 pathname: "/signin"
//               }}
//             />
//           )
//       }
//     />
//   );
// };
