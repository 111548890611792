import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { Modal, message, Form, Input } from "antd";
import { ActivityIndicator } from "antd-mobile";
import { v4 as uuidv4 } from "uuid";
import { transform, isEqual, isObject } from "lodash";
import { createTankEvent, VehicleEvent } from "@internal/hikes";
import {
    CheckMarkIcon,
    LocationIcon,
    KilometerIcon,
    WaterDropIcon
} from "../../../components/icons";
import fireStoreService from "../../../fireStoreService";
import { AuthContext } from "../../../Providers/Auth";
// @ts-ignore
export const CarScreenModal = ({ afterClose, open, submitHandler, cancelHandler, selectedCar, loading }) => {
    const [form] = Form.useForm();
    const [formState, setFormState] = useState<{}>({});
    const [currentState, setCurrentState] = useState<number>();
    const { currentUser } = useContext(AuthContext);
    const [dropDownIsOpen, setdropDownState] = useState<boolean>(false);
    useEffect(() => {
        // global.log("run useeffect", form.getFieldsValue(true));
        setFormState({
            huidige_locatie_op_terein: selectedCar.huidige_locatie_op_terein,
            kilometerstand: selectedCar.kilometerstand,
            verzorgings_status: selectedCar.verzorgings_status,
            tankinhoud: selectedCar.tankinhoud,
            voertuig_bescrhijving: selectedCar.voertuig_bescrhijving
        });
        return () => { };
    }, []);
    const isIngoredValue = (value: string, data: Array<string>) => {
        return data.includes(value);
    };
    const differenceIgnored = (object: any, base: any, igorneList = []) => {
        return transform(object, (result: any, value: any, key: any) => {
            // console.log(key, !isIngoredValue(key, igorneList))
            if (!isEqual(value, base[key]) && !isIngoredValue(key, igorneList)) {
                result[key] =
                    isObject(value) && isObject(base[key])
                        ? differenceIgnored(value, base[key])
                        : value;
            }
        });
    };
    const handleDropdown = (fieldName: any, defaultValue: any) => {
        // const elName = e.target.name;
        // const newValue = e.target.value;
        // console.log("formstate prev", formState)
        // const newState = { ...currentState, [fieldName]: defaultValue }
        // console.log('fieldValue', form.getFieldsValue(true))
        // console.log(fieldName, defaultValue)
        // setFormState(newState)
        // currentFormState = newState
    };
    const handleClose = () => {
        // setFormState({})
        setCurrentState(0);
        setdropDownState(false);
        form.setFieldsValue({ liters: "" });
        afterClose();
    };

    const createEvent = (values: any, oldValues: any) => {
        /// Write a Car event
        const currentDate = new Date();
        const time = currentDate.toISOString();
        const actions = Object.keys(values).map((action: string) => {
            return {
                action,
                category: "Vehicle",
                // @ts-ignore
                intitialValue: oldValues[action] || "", // if key  === liters return laatste tank event
                // @ts-ignore
                value: values[action]
            };
        });
        const carEvent = {
            id: uuidv4(),
            kenteken: selectedCar.kenteken,
            event_datum: time,
            gebruikersnaam: `${currentUser.first_name} ${currentUser.last_name} `,
            userId: currentUser.uid,
            maand: currentDate.getMonth(),
            actions
        } as VehicleEvent;
        return carEvent;

    };
    const handleFormSubmit = () => {

        // console.log('equal', equal, currentFormState, formState)
        form.validateFields()
            .then((values) => {
                // console.log('current', JSON.stringify(currentFormState), 'new', JSON.stringify(values))
                const time = new Date().toISOString();
                // console.log('current', formState, 'new', values)
                const tankIsEqual = isEqual(values.liters, currentState);
                const difference = differenceIgnored(values, formState);

                if (Object.keys(difference).length == 0) {
                    console.log("nothing to save form did not change");
                    return;
                }

                // console.log('did tank', didTank, values.liters, currentFormState.liters)
                const dataToBeSaved = {
                    huidige_locatie_op_terein: values.huidige_locatie_op_terein || "",
                    kilometerstand: values.kilometerstand,
                    verzorgings_status: values.verzorgings_status,
                    tankinhoud: values.tankinhoud,
                    voertuig_bescrhijving: values.voertuig_bescrhijving,
                    lastModifiedBy: currentUser.first_name,
                    lastModifiedAt: time,
                    laast_aangepast_op: time,
                    aparaat_gebruiker: navigator.userAgent,
                    laast_aangepast_door: currentUser.first_name
                };
                if (values.liters && !tankIsEqual) {

                    const event = createTankEvent(selectedCar.kenteken, values.liters, values.organisatie, currentUser.first_name);
                    // console.log("starting to create tank event ", event);
                    // @ts-ignore
                    dataToBeSaved.laatste_tank_event = event;
                    fireStoreService.saveTankEvent(event);

                }
                const event = createEvent(difference, formState);
                fireStoreService.saveVehicleEvent(event);
                const newUpdate = { ...selectedCar, ...dataToBeSaved };
                fireStoreService.updateCarByKenteken(selectedCar.kenteken, newUpdate);
                setCurrentState(values.liters);
                setFormState(values);
                message.success("Succesvol opgeslagen", 1);
                submitHandler("success");
                // Submit values
                // submitValues(values);
                // TODO increase count on vehicle object
            })
            .catch((errorInfo) => {
                const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
                Sentry.captureMessage(`validatie fout voor ${selectedCar.kenteken} ${errorObject}`, Sentry.Severity.Warning);
                Sentry.captureException(errorInfo.errorFields);
                // console.log("error info", errorObject);
                console.log("error info", errorInfo);
            });
    };
    return (
        <Modal
            afterClose={handleClose}
            destroyOnClose
            className="car-edit-form"
            title=""
            visible={open}
            okText="Opslaan"
            onOk={handleFormSubmit}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={cancelHandler}
        >

            <Form form={form} initialValues={{
                huidige_locatie_op_terein: selectedCar.huidige_locatie_op_terein,
                kilometerstand: selectedCar.kilometerstand,
                verzorgings_status: selectedCar.verzorgings_status,
                tankinhoud: selectedCar.tankinhoud,
                voertuig_bescrhijving: selectedCar.voertuig_bescrhijving,
                organisatie: "Wittebrug Verhuur"
            }} onFieldsChange={handleDropdown} onFinish={(values) => { submitHandler(values); }}>
                <ActivityIndicator animating={loading} toast text="bezig..." />
                <div className="edit-form">
                    <div className="edit-form-item">
                        <LocationIcon />
                        <div className="edit-form-item-label">Locatie</div>
                        <Form.Item name="huidige_locatie_op_terein">
                            {/* <Input
                  placeholder="input placeholder"
                  type="text"
                  className="simple-form-input"
                /> */}

                            <input
                                name="huidige_locatie_op_terein"
                                // value={context.selectedCar.huidige_locatie_op_terein}
                                className="simple-form-input"
                            />
                        </Form.Item>
                    </div>
                    <div className="row">
                        <div className="edit-form-item col-6">
                            <KilometerIcon />
                            <div className="edit-form-item-label">Kilometers</div>
                            <Form.Item name="kilometerstand">
                                <Input
                                    name="kilometerstand"
                                    // onChange={handleFieldsInModal}
                                    // value={formState.kilometerstand}
                                    className="simple-form-input"
                                />
                            </Form.Item>
                        </div>
                        <div className="edit-form-item col-6">
                            <CheckMarkIcon />
                            <div className="edit-form-item-label">Status</div>
                            <Form.Item name="verzorgings_status">
                                <select style={{ width: "100%" }}
                                    name="verzorgings_status"
                                    className="simple-select"
                                >
                                    <option value="schoon">Schoon</option>
                                    <option value="vies">Vies</option>
                                </select>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="edit-form-item tanken">
                        <div className="column col-3">
                            <WaterDropIcon />
                            {/* <div className="notification-wrapper">
                <span className="take-action">Tanken</span>
                <br />
              </div> */}
                            <div className="edit-form-item-label">Tankinhoud</div>
                            <Form.Item name="tankinhoud">
                                <select
                                    name="tankinhoud"
                                    className="simple-select"
                                >
                                    <option value="25">25%</option>
                                    <option value="50">50%</option>
                                    <option value="75">75%</option>
                                    <option value="100">100%</option>
                                </select>
                            </Form.Item>
                            {/* <input
                onChange={context.handleFieldsInModal}
                name="tankinhoud"
                value={context.selectedCar.tankLevel}
                className="simple-form-input"
              /> */}
                        </div>
                        <div className="column col-9 tanken-dropdown">
                            <div>
                                {!dropDownIsOpen ? <div><button onClick={() => {
                                    handleDropdown("organisatie", "Wittebrug Rent");
                                    setdropDownState(true);
                                }} className="tank-button">Tanken</button> <div className="activity-label">
                                        {selectedCar.laatste_tank_event && <span>
                                            laatst getankt - {selectedCar.laatste_tank_event.liters} liter</span>}
                                    </div></div> : <div> <div className={"tank-dropdown"}>
                                        <div className="col-6">
                                            <div className="edit-form-item-label">Organisatie</div>
                                            <Form.Item name="organisatie">
                                                <select
                                                    name="organisatie"
                                                    className="simple-select"
                                                >
                                                    <option value="Wittebrug Verhuur">Wittebrug Verhuur</option>
                                                    <option value="Audi">Audi</option>
                                                    <option value="Volkswagen">Volkswagen</option>
                                                </select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-6 liters">
                                            <div className="edit-form-item-label">Liters</div>

                                            <Form.Item hasFeedback rules={[{
                                                validateTrigger: "onChange",
                                                message: "Ongeldig",
                                                validator: (rule, value) => {
                                                    if (!value || value == 0) {
                                                        return Promise.reject(new Error("0 liter is geen waarde"));
                                                    }
                                                    if (isNaN(value)) {
                                                        return Promise.reject(new Error("geen geldige waarde"));
                                                    }
                                                    // console.log("resolving")
                                                    return Promise.resolve();
                                                }

                                            }]} name="liters">
                                                <Input className="simple-form-input" />
                                            </Form.Item>
                                        </div>
                                    </div></div>}


                            </div>

                        </div>
                    </div>
                </div>
                <div className="edit-form-item-bottom">
                    <div className="edit-form-item-label">Bijzonderheden/schade</div>
                    <Form.Item name="voertuig_bescrhijving">
                        <textarea
                            name="voertuig_bescrhijving"
                            className="simple-form-input simple-textarea"
                        />
                    </Form.Item>
                </div>
            </Form>
        </Modal >
    );
};