import React, { useContext, useState, useEffect } from "react";
import ReactGA from "react-ga";
import moment from "moment";
import formatLicenseplate from "../licenseplateFormater";
import { AppContext } from "../Providers/MainProvider";
import { ClockCircleOutlined, DownOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import "moment/locale/nl";
import {
    Tag, Menu,
    Input, Spin, Result, Button, Table

} from "antd";
import { VehicleEvent } from "@internal/hikes";
import fireStoreService from "../fireStoreService";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { getByLabelText } from "@testing-library/dom";

const { Search } = Input;
// @ts-ignore
const menu = (
    <Menu>
        <Menu.Item>Action 1</Menu.Item>
        <Menu.Item>Action 2</Menu.Item>
    </Menu>
);
// @ts-ignore
export const DataFeed = ({ data, kenteken, view }) => {
    moment().locale("nl");
    const [history, setHistory] = useState<VehicleEvent[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(true);
    const [hideMoreButton, setHideButton] = useState<boolean>(false);
    const [lastPosition, setLastPosition] = useState<any>();
    useEffect(() => {
        historyFeed(kenteken);
    }, [kenteken]);
    const historyFeed = async (kenteken: string) => {
        const data = await fireStoreService.getHistoryForCar(kenteken);
        // console.log("check", data.filter((x: any) => x.actions).map((x: any) => x.actions))
        if (data != "error") {
            ReactGA.event({
                category: "Historie",
                action: `Geschiedenis opgevraagd`,
                label: `Geschiedenis opgevraagd voor auto ${kenteken}`,
                value: data.data.length
            });
            // @ts-ignore
            setHistory(data.data);
            setLastPosition(data.start);
        } else {
            setError(true);
        }
        setPending(false);
    };
    const handleMoreButton = async () => {
        // get current month and year 
        // use start before and end after query
        // get history for car(fromDate, toDate) 

        const data = await fireStoreService.getHistoryForCarMore(kenteken, lastPosition);
        if (data === "error") {
            return console.log("error from firebase");
        }
        const currentTable = history;
        const newTable = [...currentTable, ...data.data];

        data.start ? setLastPosition(data.start) : setHideButton(true);
        setHistory(newTable);
        console.log("more...", data.data);
        console.log("table...");

    };
    const columns = [
        { title: "Gebruiker", dataIndex: "gebruikersnaam", key: "gebruikersnaam", ellipsis: true },
        {
            title: "Datum", dataIndex: "event_datum", key: "platform",
            render: (date: any) => (
                <span>
                    {moment(date).format("lll")}
                </span>
            ),
        },
        {
            title: "Gewijzigd", dataIndex: "actions", key: "Acties",
            render: (actions: any) => (
                <span>

                    {actions && actions.filter((x: any) => !x.length).map((value: any, index: number) => {
                        return (<Tag key={`action-${index}-${value.action}`} style={{
                            borderRadius: "10px",
                            marginBottom: "7px",
                            paddingLeft: "17px",
                            paddingRight: "17px"
                        }} color="blue">{getByLabel(value.action)}</Tag>);
                    })}
                </span>
            ),
        },
    ];
    const expandedRowRender = (tabledata: any) => {
        const columns = [
            {
                title: "Actie", dataIndex: "action", key: "Action",
                render: (action: any) => (<span>{getByLabel(action)}</span>),
            },
            { title: "Van", dataIndex: "intitialValue", key: "initial" },
            {
                title: "Naar", dataIndex: "value", key: "value",
            },
        ];

        return <Table columns={columns} rowKey={record => `action-table-${record.kenteken}`} dataSource={tabledata.actions} pagination={false} />;
    };
    if (pending) {
        return <div> <Spin tip="Loading...">
        </Spin></div>;
    }
    if (error && history.length == 0) {
        return (<Result
            status="403"
            title="Niet beschikbaar"
            subTitle="Sorry, op dit moment is er geen geschiendenis beschikbaar"
            extra={<Button type="primary">Back Home</Button>}
        />);
    }

    return (<div>

        <Table
            className="table"
            columns={columns}

            expandable={{
                expandedRowRender,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                    ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                    )
            }}
            dataSource={history}

            rowKey={record => `event-table-${record.id}`}
        />
        { !hideMoreButton && <Button style={{
            color: "#fff",
            height: "50px",
            backgroundColor: "#108ee9",
            border: "1PX solid #108ee9",
            borderRadius: "5px"
        }} type="primary" onClick={() => handleMoreButton()} className="">
            Meer laden
    </Button>}
    </div>);

};

const labels = {
    verzorgings_status: "Staat",
    huidige_locatie_op_terein: "Locatie",
    kilometerstand: "Kilometerstand",
    liters: "Getankte liters",
    organisatie: "Vloot",
    tankinhoud: "Tankinhoud",
} as any;

const getByLabel = (key: string) => {
    const label = labels[key] || key;
    return label;
};
