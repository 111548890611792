import React, { useEffect, useState } from "react";
import fireStoreService from "../fireStoreService";
import { useHistory, useLocation } from "react-router-dom";
import { Vehicle } from "@internal/hikes";
import * as Sentry from "@sentry/browser";

// type VehicleProps = {
//     currentVehicle: Vehicle | null,
//     pending: boolean
// };

// @ts-ignore
export const VehicleContext = React.createContext<any>({ currentVehicle: {}, loading: true });
// @ts-ignore
export const VehicleProvider = ({ children }) => {
    const [selectedVehicle, setCurrentVehicle] = useState<Vehicle | null>(null);
    const [pending, setPending] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const history = useHistory();

    useEffect(() => {

    }, [history]);

    const setVehicle = (vehicle: any) => {
        setCurrentVehicle(vehicle);
    };
    const setNotFoundVehicle = (bool: boolean) => {
        setNotFound(bool);
    };

    const getVehicle = async (kenteken: string) => {
        const item = await fireStoreService.getCarByKenteken(kenteken);
        if (item) {
            setCurrentVehicle(item);
            setNotFoundVehicle(false);
            return item;
        } else {
            Sentry.captureMessage(`Kenteken niet beschikbaar ${kenteken}`, Sentry.Severity.Warning);
            setNotFound(true);
            return null;
        }

    };

    return (
        // @ts-ignore
        <VehicleContext.Provider
            value={{
                selectedVehicle,
                pending,
                setVehicle,
                getVehicle,
                notFound,
                setNotFoundVehicle
            }}
        >
            {children}
        </VehicleContext.Provider>
    );
};
