import React, { useContext } from "react";
import fireStoreService from "../fireStoreService";
import { DashboardLogoIcon } from "./icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { HomeFilled, UserOutlined, CarOutlined, TeamOutlined, CarryOutOutlined } from "@ant-design/icons";
import { AuthContext } from "../Providers/Auth";
import { RBAC } from "../Rbac";
import {
    Button, Menu, Divider, Avatar

} from "antd";
// @ts-ignore
export const DashBoardMenu = (props) => {
    const use = useLocation();
    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return (<div>Gebruiker nog niet gevonden</div>)
    }
    const number = {
        "/": "2",
        "/autos": "3",
        "/gebruikers": "4"
    };
    // @ts-ignore
    const key = number[use.pathname];
    return (
        // @ts-ignore
        <Menu style={{ position: "fixed", backgroundColor: "#00041c", width: "200px", fontWeight: "bold", height: "100%", zIndex: 888 }} theme="dark" mode="vertical" defaultSelectedKeys={[key]}>
            <div style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px" }}>
                <DashboardLogoIcon />
                <Divider style={{ backgroundColor: "white" }} />
            </div>
            <div style={{ paddingTop: "0", paddingBottom: "10px", paddingLeft: "20px" }}>
                <Avatar size="large" gap={8}>{currentUser.first_name.charAt(0).toUpperCase() + currentUser.last_name.charAt(0).toUpperCase()} </Avatar>
                <span style={{ marginLeft: "10px", textTransform: "capitalize" }}>{currentUser?.first_name}</span>
            </div>


            <div style={{ paddingTop: "0", paddingLeft: "20px", paddingRight: "20px" }}>
                <Divider style={{ backgroundColor: "white" }} />
            </div>
            <RBAC {...props} permissionsNeeded={["voertuigen:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item className={"ant-menu-item"} icon={<HomeFilled />} key="2"><Link to="/dashboard">
                    Dashboard
                </Link>
                </Menu.Item>
            </RBAC>
            {/* <RBAC {...props} permissionsNeeded={["voertuigen:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item icon={<CarOutlined />} key="3"><Link className="autos-link" to="/autos">
                    Vloot
                </Link></Menu.Item>
            </RBAC> */}
            <RBAC {...props} permissionsNeeded={["gebruikers:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item icon={<UserOutlined />} key="4"><Link to="/gebruikers">
                    Gebruikers
                </Link></Menu.Item>
            </RBAC>
            <RBAC {...props} permissionsNeeded={["voertuigen:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item icon={<CarOutlined />} key="5">
                    <Link to="/tank-historie">
                        Tank gegevens
                    </Link>
                </Menu.Item>
            </RBAC>
            <RBAC {...props} permissionsNeeded={["teams:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item icon={<TeamOutlined />} key="6">
                    <Link className="gebruikers-link" to="/teams">
                        Teams
                    </Link>
                </Menu.Item>
            </RBAC>
            <RBAC {...props} permissionsNeeded={["opdrachten:bekijken"]} currentUserPermissions={currentUser.permissions} >
                <Menu.Item icon={<CarryOutOutlined />} key="7">
                    <Link to="/opdrachten">
                        Opdrachten
                    </Link>
                </Menu.Item>
            </RBAC>
            <Menu.Item style={{ position: "absolute", "bottom": "10px", width: "100%" }} onClick={() => {
                fireStoreService.logout();
            }} key="1"> <Button style={{ border: "1px solid #e6f7ff", backgroundColor: "white", color: "black" }} type="primary" shape="round" block>Uitloggen</Button></Menu.Item>

        </Menu >
    );
}