import React, { useContext, useState } from "react";
import { WhiteSpace, Icon, Flex, ActivityIndicator } from "antd-mobile";
import { Modal, notification, Spin, Alert, Tag } from "antd";
import HikerMenu from "../../../components/menu";
import { CarScreenModal } from "./CarscreenModal"
import "./CarScreen.css";
import _ from "lodash";
import formatLicenseplate from "../../../licenseplateFormater";
import { AppContext } from "../../../Providers/MainProvider";
import { useParams } from "react-router-dom";
import {
  CheckMarkIcon,
  LocationIcon,
  KilometerIcon,
  WaterDropIcon,
  BackIcon,
  AlertMarkIcon
} from "../../../components/icons";
import { Vehicle } from "../../../types";
const options = { month: "long", day: "numeric", year: "numeric" };


export const CarScreen: React.FC<CarScreenProps> = (props) => {
  const [editVehicle, setEditVehicle] = useState<boolean>(false)
  const context = useContext(AppContext);
  // @ts-ignore
  const { kenteken } = useParams();
  const addDefaultSrc = (ev: any) => {
    ev.target.src = "/assets/cars/unkown-car.png";
  };

  if (context == null) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin>,</div>;
  }
  if (!context.selectedCar) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin>,</div>;
  }
  if (!context.selectedCar.kenteken) {
    return (<Alert
      message="Oeps er is iets fout gegaan met het ophalen van het kenteken, neem contact op met de administrator van hikes"
      description=""
      type="error"
      showIcon
    />);
  }
  return (
    <div className="full-height-with-menu">
      <div className="sub-menu">
        <div className="sub-nav-bar">
          <div
            onClick={() => {
              context.setActiveCar("");
            }}
            className="left-content"
          >
            <BackIcon className="back-button" /> <span>Terug</span>
          </div>
          <div className="right-content">
            <div className="secondary-text">
              {context.selectedCar && context.selectedCar.kenteken &&
                <div>
                  <div style={{ marginBottom: "5px" }}>
                    <Tag className="kentekenTag">{formatLicenseplate(context.selectedCar.kenteken)}</Tag>
                  </div>
                  {/* <div style={{ fontSize: "12px" }}> {`${context.selectedCar.merk} ${context.selectedCar.model} `}</div> */}
                </div>}
            </div>
            <span className="primary-text">Laatst behandeld door:</span>{" "}
            {""}
            <span className="secondary-text">
              {context.selectedCar.laast_aangepast_door} -{" "}
              {new Date(context.selectedCar.laast_aangepast_op).toLocaleDateString(
                "nl-NL",
                // @ts-ignore
                options
              )}
            </span>

          </div>
        </div>
      </div>
      <div className="top-area">
        <VehicleNotifications vehicle={context.selectedCar} />
        <div style={{ textAlign: "center" }}>
          <div className="car-image-container">
            <img className="car-image" onError={addDefaultSrc} src={`/assets/cars/${context.selectedCar.model.toLowerCase()}.png`} alt="car" />
          </div>
        </div>
        <div style={{ fontSize: "14px", color: "#979797", textAlign: "center", marginTop: "20px", fontWeight: "bold" }}> {`${context.selectedCar.merk} ${context.selectedCar.model} `}</div>
      </div>
      <div className="current-car-state">
        <Flex>
          <Flex.Item onClick={() => setEditVehicle(true)}>
            <div className="car-state-icon">
              <LocationIcon />
            </div>
            <div className="car-state-sub-title">Locatie</div>
            <div data-testid="state-location" className="car-state-title">
              {context.selectedCar.huidige_locatie_op_terein}
            </div>
          </Flex.Item>
          <Flex.Item />
          <Flex.Item onClick={() => setEditVehicle(true)}>
            <div className="car-state-icon">
              <CheckMarkIcon />
            </div>
            <div className="car-state-sub-title">Status</div>
            <div data-testid="state-maintance" className="car-state-title">
              {context.selectedCar.verzorgings_status}
            </div>
          </Flex.Item>
        </Flex>
        <WhiteSpace size="xl" />
        <Flex>
          <Flex.Item onClick={() => setEditVehicle(true)}>
            <div className="car-state-icon">
              <KilometerIcon />
            </div>
            <div className="car-state-sub-title">Kilometers</div>
            <div data-testid="state-mileage" className="car-state-title">
              {" "}
              {context.selectedCar.kilometerstand}
            </div>
          </Flex.Item>
          <Flex.Item />
          <Flex.Item onClick={() => setEditVehicle(true)}>
            <div className="car-state-icon">
              <WaterDropIcon />
            </div>
            <div className="car-state-sub-title">Tankinhoud</div>
            <div data-testid="state-tank-level" className="car-state-title">
              {context.selectedCar.tankinhoud + "%"}
            </div>
          </Flex.Item>
        </Flex>
      </div>
      <div className="related-cars">
        <div className="related-cars-title">Vergelijkbare auto's</div>

        <div className="car-carousel">
          <div
            style={{ width: context.relatedCars.length * 169 }}
            className="car-inner-container"
          >
            {context.relatedCars.map(relatedCar => {
              return (
                <div
                  onClick={() => {
                    context.setActiveCar(relatedCar.kenteken);
                  }}
                  key={"related-car-" + relatedCar.kenteken}
                  className="car-carousel-slide"
                >
                  <div className="car-carousel-license-plate">
                    {formatLicenseplate(relatedCar.kenteken)}
                  </div>
                  <img onError={addDefaultSrc} src={`/assets/cars/${relatedCar.model.toLowerCase()}.png`} alt="car" />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <CarScreenModal
        afterClose={() => { console.log("closing") }}
        open={editVehicle}
        submitHandler={(data: any) => {
          // @ts-ignore
          context.setActiveCar(context.selectedCar.kenteken);
        }}
        cancelHandler={() => setEditVehicle(false)}
        selectedCar={context.selectedCar}
        loading={false}

      />
      <div onClick={() => setEditVehicle(true)} className="edit-button">
        Auto bijwerken
      </div>
    </div>
  );
};

interface VehicleNotifications {
  vehicle: Vehicle;
}
interface CarScreenProps {
  vehicle: Vehicle;
}

const VehicleNotifications: React.FC<VehicleNotifications> = ({ vehicle }) => {
  const messages = getMessages(vehicle);
  return (
    <div className="notification-area">
      {vehicle.voertuig_bescrhijving ? (
        <div
          onClick={() => {
            openNotification(vehicle.voertuig_bescrhijving);
          }}
          className="notification-wrapper alert-wrapper"
        >
          <span className={"alert-action"}>
            Melding{" "}
            <AlertMarkIcon className="align-alert" type="check-circle-o" />
          </span>
          <br />
        </div>
      ) : null}
      {messages.map((message, index) => {
        return message === "Melding" ? null : (
          <div key={index} className="notification-wrapper">
            <span className={"take-action " + message}>{message}</span>
            <br />
          </div>
        );
      })}

      {messages.length === 0 ? (
        <div className="no-notification">
          Geen meldingen <Icon type="check-circle-o" />
        </div>
      ) : null}
    </div>
  );
};

const getMessages = (car: Vehicle) => {
  const arr = [];
  if (car.beschadigd) {
    arr.push("Schade");
  }
  if (car.verzorgings_status.toLocaleLowerCase() !== "schoon") {
    arr.push("Schoonmaken");
  }
  if (car.tankinhoud < 100) {
    arr.push("Tanken");
  }
  if (car.voertuig_bescrhijving !== "") {
    arr.push("Melding");
  }

  return arr;
};

const openNotification = (description: string) => {
  console.log("des", description);
  const args = {
    message: "Melding",
    description,
    duration: 0
  };

  notification.info(args);
};
