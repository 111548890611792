import React, { useContext, useState, useEffect } from "react";
import ReactGA from "react-ga";
import moment from "moment";
import _ from "lodash";
import { BarChartOutlined, PieChartTwoTone } from "@ant-design/icons";
import "moment/locale/nl";
import {

    Avatar, Comment, Tooltip, Tag, Menu,
    Timeline, Input, Spin, Result, Row, Col, Button, Table, Space, Badge, Dropdown, Statistic

} from "antd";
import { TankEvent } from "@internal/hikes";
import fireStoreService from "../fireStoreService";

const { Column } = Table;
// @ts-ignore
export const TankEventHistoryFeed = ({ data, kenteken, view }) => {
    moment().locale("nl");
    const [history, setHistory] = useState<TankEvent[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(true);
    const [cockPit, setCockPit] = useState<any>({ wittebrugRent: 0, volkswagen: 0, audi: 0 });
    const [filteredInfo, setFilteredInfo] = useState<boolean>(true);
    const [hideMoreButton, setHideButton] = useState<boolean>(false);
    const [lastPosition, setLastPosition] = useState<any>();
    useEffect(() => {
        historyFeed(kenteken)
    }, [kenteken]);
    const historyFeed = async (kenteken: string) => {
        const daysInThePast = 365;
        const date = new Date()

        date.setDate(date.getDate() - daysInThePast);
        const toDate = date.toLocaleDateString("fr-CA");
        const data = await fireStoreService.getTankEventsByKenteken(toDate, kenteken);
        if (data === "error") {
            setError(true);
            return console.log("error from firebase");
        }
        // @ts-ignore
        const grouped = _(data.data).groupBy("organisatie").map((deparment: any, index: any) => {
            return {
                organisatie: index,
                amount: _.sumBy(deparment, "liters")
            };
        }).value();
        // console.log("tank events", data)

        setCockPit({
            audi: _.get(grouped.find(x => x.organisatie === "Audi"), "amount", 0),
            volkswagen: _.get(grouped.find(x => x.organisatie === "Volkswagen"), "amount", 0),
            wittebrugRent: _.get(grouped.find(x => x.organisatie === "Wittebrug Rent"), "amount", 0),
            total: _.sumBy(data.data, (x) => x.liters)
        });
        ReactGA.event({
            category: "TankGegevens",
            action: `Tank gegevens opgevraagd`,
            label: `Tank gegevens voor auto ${kenteken}`,
            value: data.data.length
        });
        // @ts-ignore
        setHistory(data.data);
        setLastPosition(data.start);

        setPending(false);
    }
    const handleMoreButton = async () => {
        // get current month and year 
        // use start before and end after query
        // get history for car(fromDate, toDate) 

        const data = await fireStoreService.getTankEventsByKentekenMore(kenteken, lastPosition);
        if (data === "error") {
            setError(true)
            return console.log("error from firebase");
        }
        const currentTable = history
        const newTable = [...currentTable, ...data.data];

        data.start ? setLastPosition(data.start) : setHideButton(true);
        setHistory(newTable);
        if (data.start) {
            const grouped = _(data.data).groupBy("organisatie").map((deparment: any, index: any) => {
                return {
                    organisatie: index,
                    amount: _.sumBy(deparment, "liters")
                };
            }).value();
            // console.log("tank events", data)

            setCockPit({
                audi: _.get(grouped.find(x => x.organisatie === "Audi"), "amount", 0),
                volkswagen: _.get(grouped.find(x => x.organisatie === "Volkswagen"), "amount", 0),
                wittebrugRent: _.get(grouped.find(x => x.organisatie === "Wittebrug Rent"), "amount", 0),
                total: _.sumBy(data.data, (x) => x.liters)
            });
        }

        console.log("more...", data.data);
        console.log("table...");

    }

    if (pending) {
        return <div> <Spin tip="Loading...">
        </Spin></div>;
    }
    if (error && history.length == 0) {
        return (<Result
            status="403"
            title="Niet beschikbaar"
            subTitle="Sorry, op dit moment is er geen geschiendenis beschikbaar"
        />)
    }

    return (<div>

        <Row style={{ marginBottom: "20px" }}>
            <Col span={24}>
                <Row gutter={40}>
                    <Col style={{ borderRight: "2px solid #f2f2f2" }} span={6}>
                        <Statistic title="Totaal" formatter={value => value} precision={0} value={cockPit.total} prefix={<BarChartOutlined style={{ color: "#08c" }} />} suffix="Liter" />
                    </Col>
                    <Col style={{ borderRight: "2px solid #f2f2f2" }} span={6}>
                        <Statistic title="Audi" formatter={value => value} precision={0} value={cockPit.audi} prefix={<PieChartTwoTone style={{ color: "#87d068" }} />} suffix="Liter" />
                    </Col>
                    <Col style={{ borderRight: "2px solid #f2f2f2" }} span={6}>
                        <Statistic title="Wittebrug Rent" formatter={value => value} precision={0} value={cockPit.wittebrug} prefix={<PieChartTwoTone style={{ color: "#2db7f5" }} />} suffix="Liter" />
                    </Col>
                    <Col span={6}>
                        <Statistic title="Volkswagen" formatter={value => value} precision={0} value={cockPit.volkswagen}
                            prefix={<PieChartTwoTone color={"#2db7f5"} style={{ color: "#2db7f5" }} />} suffix="Liter" />
                    </Col>
                </Row>

            </Col>
        </Row>

        <Table
            className="table"
            dataSource={history}
            rowKey={record => `event-table-${record.id}`}>
            <Column title="Liters" dataIndex="liters" key="liters"
                render={liters => (<Badge style={{ backgroundColor: liters > 49 ? "#f4a636" : "#52c41a", fontWeight: "bold" }} size="default" count={liters} />)}
            />
            <Column title="Vloot" dataIndex="organisatie" key="organisatie"
                render={organisatie => (<Tag style={{ fontSize: "14px", fontWeight: "bolder", borderRadius: "10px", ...colorStyle["Wittebrug Rent"] }}>{organisatie} </Tag>)} />
            <Column title="Datum" dataIndex="event_datum" key="event_datum"
                render={(date: any) => (
                    <span>
                        {moment(date).format("lll")}
                    </span>
                )} />
            <Column filters={_.uniqBy((history.map((x: any) => {
                return {
                    text: x.gebruikersnaam,
                    value: x.gebruikersnaam
                }
            })), "text")}
                onFilter={(value, record: any) => record.gebruikersnaam.includes(value)}
                title="Gebruiker" dataIndex="gebruikersnaam" key="gebruikersnaam" />
        </Table>
        { !hideMoreButton && <Button style={{
            color: "#fff",
            height: "50px",
            backgroundColor: "#108ee9",
            border: "1PX solid #108ee9",
            borderRadius: "5px"
        }} type="primary" onClick={() => handleMoreButton()} className="">
            Meer laden
    </Button>}
    </div>
    )

}
const colorLib = {
    "Wittebrug Rent": "#2db7f5",
    Volkswagen: "#108ee9",
    Audi: "#87d068"
}
const colorStyle = {
    "Wittebrug Rent": {
        color: "#00B2F2",
        backgroundColor: "#e6f7ff",
        borderColor: "#00B2F2"
    },
    Wittebrug: {
        color: "#096dd9",
        backgroundColor: "#e6f7ff",
        borderColor: "#91d5ff"
    },
    Volkswagen: {
        color: "#001e50",
        backgroundColor: "#cad0da",
        borderColor: "#001e50"
    },
    Audi: {
        color: "#f50437",
        backgroundColor: "#fbcad5",
        borderColor: "#f50437"
    },
    Seat: {
        color: "#f50437",
        backgroundColor: "#fbcad5",
        borderColor: "#f50437"
    }
} as any;

const colorMapping = (organisatieName: string) => {
    return colorStyle[organisatieName] || "blue";
}

