import React, { useContext, useState } from "react";
import { WhiteSpace, Icon, Flex, Button } from "antd-mobile";
import { Modal, notification, Spin, Alert, Tabs, Form, Input } from "antd";
import { CarScreenModal } from "./CarscreenModal"
import "./CarScreen.css";
import _ from "lodash";
import {
  CheckMarkIcon,
  LocationIcon,
  KilometerIcon,
  WaterDropIcon,
  AlertMarkIcon
} from "../../../components/icons";
import { Vehicle } from "../../../types";
import { DataFeed } from "../../../components/Feed";
import { TankEventHistoryFeed } from "../../../components/TankEventHistoryFeed";
import { VehicleEvent } from '@internal/hikes';
import { VehicleContext } from '../../../Providers/VehicleProvider';
const options = { month: "long", day: "numeric", year: "numeric" };
const { TabPane } = Tabs;
const historyFeed = [
  {
    id: "1",
    gebruikersnaam: "Marlowe",
    kenteken: "2thx57",
    userId: "234522",
    event_datum: "23-01-2020",
    actions: [{
      value: "Getankt"
    }]
  },
  {
    id: "2",
    gebruikersnaam: "Marlowe",
    kenteken: "2thx57",
    userId: "234522",
    event_datum: "23-01-2020",
    actions: [{
      name: "Getankt"
    }]
  }, {
    id: "3",
    gebruikersnaam: "Marlowe",
    kenteken: "2thx57",
    userId: "234522",
    event_datum: "23-01-2020",
    actions: [{
      name: "Getankt"
    }]
  }
] as VehicleEvent[]
export const CarScreenDesktop: React.FC = (props) => {
  const context = useContext(VehicleContext);
  const [editVehicle, setEditVehicle] = useState<boolean>(false)

  const addDefaultSrc = (ev: any) => {
    ev.target.src = "/assets/cars/unkown-car.png";
  };
  const handleCancel = () => {
    setEditVehicle(false)
  };
  if (!context.selectedVehicle) {
    console.log("spining", context.selectedVehicle)
    return <div> <Spin tip="Loading...">
    </Spin></div>;
  }
  return (
    <div className="full-height-with-menu">
      <Tabs defaultActiveKey="1">
        {/* <div className="sub-menu">
          <div className="sub-nav-bar">
            <div className="right-content">
              <span className="primary-text">Laatst behandeld door:</span>{" "}
              {""}
              <span className="secondary-text">
                {context.selectedVehicle.laast_aangepast_door} -{" "}
                {new Date(context.selectedVehicle.laast_aangepast_op).toLocaleDateString(
                  "nl-NL",
                  options
                )}
              </span>
            </div>
          </div>
        </div> */}
        <TabPane tab="Auto" key="1">
          <div className="top-area">
            <VehicleNotifications vehicle={context.selectedVehicle} />
            <div style={{ textAlign: "center" }}>
              <div className="car-image-container">
                <img className="car-image" onError={addDefaultSrc} src={`/assets/cars/${context.selectedVehicle.model.toLowerCase()}.png`} alt="car" />
              </div>
            </div>
          </div>
          <div className="current-car-state">
            <Flex>
              <Flex.Item onClick={context.showEditCarModal}>
                <div className="car-state-icon">
                  <LocationIcon />
                </div>
                <div className="car-state-sub-title">Locatie</div>
                <div data-testid="state-location" className="car-state-title">
                  {context.selectedVehicle.huidige_locatie_op_terein}
                </div>
              </Flex.Item>
              <Flex.Item />
              <Flex.Item onClick={context.showEditCarModal}>
                <div className="car-state-icon">
                  <CheckMarkIcon />
                </div>
                <div className="car-state-sub-title">Status</div>
                <div data-testid="state-maintance" className="car-state-title">
                  {context.selectedVehicle.verzorgings_status}
                </div>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="xl" />
            <Flex>
              <Flex.Item onClick={context.showEditCarModal}>
                <div className="car-state-icon">
                  <KilometerIcon />
                </div>
                <div className="car-state-sub-title">Kilometers</div>
                <div data-testid="state-mileage" className="car-state-title">
                  {" "}
                  {context.selectedVehicle.kilometerstand}
                </div>
              </Flex.Item>
              <Flex.Item />
              <Flex.Item onClick={context.showEditCarModal}>
                <div className="car-state-icon">
                  <WaterDropIcon />
                </div>
                <div className="car-state-sub-title">Tankinhoud</div>
                <div data-testid="state-tank-level" className="car-state-title">
                  {context.selectedVehicle.tankinhoud + "%"}
                </div>
              </Flex.Item>
            </Flex>
          </div>
          {/* <div className="related-cars">
        <div className="related-cars-title">Vergelijkbare auto's</div>

        <div className="car-carousel">
          <div
            style={{ width: context.relatedCars.length * 169 }}
            className="car-inner-container"
          >
            {context.relatedCars.map(relatedCar => {
              return (
                <div
                  onClick={() => {
                    context.setActiveCar(relatedCar.kenteken);
                  }}
                  key={"related-car-" + relatedCar.kenteken}
                  className="car-carousel-slide"
                >
                  <div className="car-carousel-license-plate">
                    {formatLicenseplate(relatedCar.kenteken)}
                  </div>
                  <img onError={addDefaultSrc} src={`/assets/cars/${relatedCar.model.toLowerCase()}.png`} alt="car" />
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
          <CarScreenModal
            open={editVehicle}
            afterClose={() => { console.log("closing") }}
            submitHandler={(data: any) => {
              context.getVehicle(context.selectedVehicle.kenteken)
            }}
            cancelHandler={handleCancel}
            selectedCar={context.selectedVehicle}
            loading={false}

          />
          <Button type="primary" onClick={() => setEditVehicle(true)} className="">
            Auto bijwerken
          </Button>
        </TabPane>
        <TabPane tab="Historie" key="2">
          <DataFeed view={1} kenteken={context.selectedVehicle.kenteken} data={historyFeed} />
        </TabPane>
        <TabPane tab="Tank historie" key="3">
          <TankEventHistoryFeed view={1} kenteken={context.selectedVehicle.kenteken} data={historyFeed} />
        </TabPane>

      </Tabs>
    </div>
  );
};

interface VehicleNotifications {
  vehicle: Vehicle;
}
interface CarScreenProps {
  vehicle: Vehicle;
}

const VehicleNotifications: React.FC<VehicleNotifications> = ({ vehicle }) => {
  const messages = getMessages(vehicle);
  return (
    <div className="notification-area">
      {vehicle.voertuig_bescrhijving ? (
        <div
          onClick={() => {
            openNotification(vehicle.voertuig_bescrhijving);
          }}
          className="notification-wrapper alert-wrapper"
        >
          <span className={"alert-action"}>
            Melding{" "}
            <AlertMarkIcon className="align-alert" type="check-circle-o" />
          </span>
          <br />
        </div>
      ) : null}
      {messages.map((message, index) => {
        return message === "Melding" ? null : (
          <div key={index} className="notification-wrapper">
            <span className={"take-action " + message}>{message}</span>
            <br />
          </div>
        );
      })}

      {messages.length === 0 ? (
        <div className="no-notification">
          Geen meldingen <Icon type="check-circle-o" />
        </div>
      ) : null}
    </div>
  );
};

const getMessages = (car: Vehicle) => {
  const arr = [];
  if (car.beschadigd) {
    arr.push("Schade");
  }
  if (car.verzorgings_status.toLocaleLowerCase() !== "schoon") {
    arr.push("Schoonmaken");
  }
  if (car.tankinhoud < 100) {
    arr.push("Tanken");
  }
  if (car.voertuig_bescrhijving !== "") {
    arr.push("Melding");
  }

  return arr;
};

const openNotification = (description: string) => {
  const args = {
    message: "Melding",
    description,
    duration: 0
  };

  notification.info(args);
};
