import React, { useContext, useState } from "react";
import { message, Spin, Table, Alert, Checkbox, Tabs, Form, Input, Select, Button } from "antd";
import _ from "lodash";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Providers/Auth";
import { createTeam, Team } from "@internal/hikes";
import fireStoreService from "../../fireStoreService";
import * as Sentry from "@sentry/browser";
import { allPermissions, permision } from "./TeamsScreen";
import { User } from "../../types";
const options = { month: "long", day: "numeric", year: "numeric" };
const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;

interface TeamDetailsType {
  team: Team;
  users: User[];
}
export const TeamDetails = ({ team, users }: TeamDetailsType) => {
  const { currentUser } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({
    name: team ? team.name : "",
    description: team ? team.description : ""
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(team ? [...team.permissions] : []);
  const [removePermissionMutations, setRemovePermissionMutations] = useState<string[]>([]);
  const [addPermissionMutations, setAddPermissionMutations] = useState<string[]>([]);
  const [formSaved, setFormSaved] = useState<boolean>(true);
  // if (!team) {
  //   setCreatenNewTeam(true);
  // }
  const onChange = (record: any, isNotSelected, param) => {
    console.log(record, isNotSelected, param);
    const newPermission = param.map(x => x.name);
    console.log("It was not selected not previosly", isNotSelected);
    // console.log("current permissions", selectedRowKeys);
    // console.log("new permissions", newPermission);
    // console.log(param.map(x => x.name));
    setSelectedRowKeys(newPermission);
    // console.log("current permissions", selectedRowKeys);
    if (isNotSelected) {
      console.log("add permission", addPermissionMutations, record.name);
      setAddPermissionMutations([...addPermissionMutations, record.name]);
      // const newArray = selectedRowKeys.splice(selectedRowKeys.indexOf(record.name), 1);
      // setSelectedRowKeys(newArray);
    } else {
      setRemovePermissionMutations([...removePermissionMutations, record.name]);
      console.log("remove permission", removePermissionMutations, record.name);
      // setSelectedRowKeys([...selectedRowKeys, record.name]);
      // selectedRowKeys.push(record.key);
    }

  };
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, second, param) => onChange(record, second, param),
    onSelectAll: (record, second, param) => onChange(record, second, param),
    getCheckboxProps: (record: any) => ({
      disabled: false, // Column configuration not to be checked
      name: record.name
    })
  };
  const onChangeForm = () => {
    setFormSaved(false);
  };

  const handleDelete = (record) => {
    console.log(record);
    fireStoreService.removeUserFromTeam(record.uid, team.id, record.permision);
  };


  const handleFormSubmit = () => {
    // console.log('equal', equal, currentFormState, formState)
    form.validateFields()
      .then(async (values) => {
        console.log("values from team details", values, selectedRowKeys);
        if (team) {
          const updatedTeam = {
            ...team,
            name: values.name,
            description: values.description,
            permissions: selectedRowKeys
          } as Team;
          console.log("updated team", updatedTeam);
          // const permissionsToDelete = ()
          await fireStoreService.saveTeam(updatedTeam);
          // loop trough all users and add and remove permissions
          fireStoreService.editPermissionsOnAllUsers(team.id, selectedRowKeys, removePermissionMutations, addPermissionMutations);
          // fireStoreService.addPermission(currentUser.uid, selectedRowKeys);
          setFormSaved(true);
          message.success("Succesvol opgeslagen", 1);
        } else {
          const newTeam = createTeam(values.name, currentUser.uid, values.description, selectedRowKeys);
          await fireStoreService.saveTeam(newTeam);
          setFormSaved(true);
          message.success("Succesvol opgeslagen", 1);
        }

      })
      .catch((errorInfo) => {
        const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
        Sentry.captureMessage(`validatie fout voor ${errorObject}`, Sentry.Severity.Warning);
        // console.log("error info", errorObject);
        console.log("error info", errorInfo);
      });
  };

  const plainOptions = allPermissions;

  const handleAddUser = async (data) => {

    if (team && data.chosenUser) {
      if (selectedRowKeys.length == 0) {
        message.error("Kan gebruiker niet toevoegen aan een team zonder permissies.");
      } else {
        const user = await fireStoreService.addUserToTeamUser(data.chosenUser, team.id, selectedRowKeys);
        console.log("saved user", user);
      }
    }

  };
  return (
    <div className="full-height-with-menu">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Team" key="1">
          <Form form={form} size="large" initialValues={{
            name: team ? team.name : "",
            description: formData.description
          }} style={{ marginTop: "0" }} onChange={() => { onChangeForm(); }} onFinish={(values) => { handleFormSubmit(); }} layout="vertical" wrapperCol={{ span: 24 }}>
            <Form.Item name="name" rules={[{ required: true }]} label="Naam">
              <Input
                type="text"
              />
            </Form.Item>
            <Form.Item name="description" rules={[{ required: true }]} label="Beschrijving">
              <Input
                type="text"
              />
            </Form.Item>
            <h2>Toegang</h2>
            <Table rowSelection={rowSelection} onRow={record => ({
              onClick: () => {
                // onChange(record);
              }
            })} dataSource={permision} rowKey={(record, index) => `${record.name}`} pagination={{ pageSize: 50 }}>
              <Column title="Naam" dataIndex="label" key="label"
                sorter={(a: any, b: any) => a.name.localeCompare(b.name)}
                render={(name) => (<span>{name}</span>)}
              />
              <Column title="Omschrijving" dataIndex="description" key="description"
                render={description => (<div>{description}</div>)}
              />
            </Table>
            <Form.Item>
              <Button disabled={formSaved} data-testid="save-vehicle" className="vehicle-form-save" type="primary" htmlType="submit">
                Opslaan
              </Button>
            </Form.Item>
          </Form >
        </TabPane>
        <TabPane tab="Gebruikers" key="2">
          <Form layout="inline" onFinish={handleAddUser}>
            <Form.Item name="chosenUser" >
              <Select
                allowClear
                // onSelect={(e, param) => handleAddUser(e, param)}
                showSearch
                style={{ width: 300, borderRadius: "10px" }}
                placeholder="Nieuwe gebruiker toevoegen"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA: any, optionB: any) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {users.map(user => (<Option key={user.uid} value={user.uid}>{user.email}</Option>))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" shape="circle" icon={<PlusOutlined />} htmlType="submit"></Button>
            </Form.Item>
          </Form>
          <Table dataSource={users.filter(user => user?.teams?.includes(team?.id))}>
            <Column title="E-mail" dataIndex="email" key="email" />
            <Column title="Gebruikersnaam" dataIndex="first_name" key="email" />
            <Column title="Verwijderen" dataIndex="uid" key="uid" render={(key, record) => (
              <span>
                <DeleteOutlined onClick={() => { handleDelete(record); }} style={{ fontSize: "20px", color: "red" }} />
              </span>
            )} />
          </Table>
        </TabPane>
      </Tabs>
    </div >
  );
};
