import React, { useEffect, useState, useContext } from "react";
import fireStoreService from "../fireStoreService";
import { AuthContext } from "../Providers/Auth";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
    useWindowSize
} from "@react-hook/window-size";
import { Team, Permission, WorkOrder, VehicleEvent } from "@internal/hikes";

// type VehicleProps = {
//     currentVehicle: Vehicle | null,
//     pending: boolean
// };

// @ts-ignore

interface WorkOrderContext {
    workOrders: WorkOrder[];
    subscribeToWorkOrders: () => void;
    prioMapping: (prio: string) => string;
    stateLabel: any;
    loading: boolean;
}
const stateLabel = {
    0: "Open",
    1: "In behandeling",
    2: "Klaar"
};
export const WorkOrderContext = React.createContext<WorkOrderContext>({
    workOrders: [],
    subscribeToWorkOrders: Function,
    prioMapping: (prio: string) => "Einde dag",
    stateLabel,
    loading: true
});
// @ts-ignore
export const WorkOrderProvider = ({ children }) => {
    const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const { currentUser } = useContext(AuthContext);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const cleanedUpPermissions = currentUser?.permissions?.map((x) => x.split("|")[1]);
        setLoading(false);
        return () => {
            console.log("unmounting", "UserProvider");
        };

    }, []);

    const prioLibrary = {
        1: "Spoed",
        2: "Voor 12:00 uur",
        3: "Voor 17:00 uur",
        4: "Einde dag"
    };

    const prioMapping = (prio: string) => {
        return prioLibrary[prio];
    };

    const subscribeToWorkOrders = () => {
        // console.log("subscribe to workorders");
        if (workOrders.length != 0) {
            console.log("listner already set");
            return;
        }
        if (currentUser.role == 1) {
            fireStoreService.subscribeToWorkOrders((data: any) => {
                // console.log("subscribe to workorders as admin", data);
                const newArr = data.map((order: WorkOrder) => {
                    return {
                        ...order,
                        sortOrder: `${moment(order.dueDate).format("ll")} ${order.priority} `,
                        day: `${order.dueDate?.split("T")[0]}-${order.priority}-${moment(order.dueDate).format("hh-mm")}`,
                        time: moment(order.dueDate).format("hh-mm")
                    };
                });

                const newOrder = _.orderBy(newArr, ["day", "dueDate"], ["asc", "asc"]);
                setWorkOrders(newOrder);
                // setPending(false)
            });
        }
        if (currentUser.role == 2) {
            fireStoreService.subscribeToActiveOrders((data: any) => {

                const newArr = data.map((order: WorkOrder) => {
                    return {
                        ...order,
                        sortOrder: `${moment(order.dueDate).format("ll")} ${order.priority} `,
                        time: moment(order.dueDate).format("hh-mm"),
                        day: `${order.dueDate?.split("T")[0]}-${order.priority}-${moment(order.dueDate).format("hh-mm")}`,
                    };
                });
                const newOrder = _.orderBy(newArr, ["day", "priority", "time"], ["asc", "asc"]);
                setWorkOrders(newOrder);
                // setPending(false)
            }, currentUser.role);
        }
        if (currentUser.role >= 3) {
            fireStoreService.subscribeToSubSelectionWorkOrders((data: any) => {

                const newArr = data.map((order: WorkOrder) => {
                    return {
                        ...order,
                        sortOrder: `${moment(order.dueDate).format("ll")} ${order.priority} `,
                        day: `${order.dueDate?.split("T")[0]}-${order.priority}-${moment(order.dueDate).format("hh-mm")}`,
                        time: moment(order.dueDate).format("hh-mm")
                    };
                });
                const newOrder = _.orderBy(newArr, ["day", "priority", "time"], ["asc", "asc"]);
                setWorkOrders(newOrder);
                // setPending(false)
            }, currentUser.role);
        }

    };

    return (
        // @ts-ignore
        <WorkOrderContext.Provider
            value={{
                loading,
                workOrders,
                subscribeToWorkOrders,
                prioMapping,
                stateLabel
            }}
        >
            {children}
        </WorkOrderContext.Provider>
    );
};
