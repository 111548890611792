import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import "./TeamsScreen.css";
import _ from "lodash";

import moment from "moment";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Table, Row, Col, Drawer, Button, Tabs, Dropdown, Result, Tag, Menu, Badge, Space, Card, Alert, Spin, Divider, Select, Form, Statistic

} from "antd";
import { AppContext } from "../../Providers/MainProvider";
import { VehicleContext } from "../../Providers/VehicleProvider";
import { DashBoardMenu } from "../../components/DashboardMenu";
import { AuthContext } from "../../Providers/Auth";
import { Vehicle } from "../../types/vehicle";
import fireStoreService from "../../fireStoreService";
import { TeamDetails } from "./TeamsDetails";
import { Team, Permission } from "@internal/hikes";
import { UserProviderContext } from "../../Providers/UserProvider";
import { RBAC } from "../../Rbac";
const { Column } = Table;
const { Option } = Select;
const { TabPane } = Tabs;
interface Selected {
  drawerIsOpen: boolean;
  team?: Team | null;
}
export const TeamsScreen: React.FC = () => {
  const context = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);
  const { subscribeToPermissions, subscribeToUsers, subscribeToTeams, teams, users } = useContext(UserProviderContext);
  const currentVehicleContext = useContext(VehicleContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [DaysInThePast, setDaysInThePast] = useState<number>(7);
  const [mainDrawerIsOpen, setMainDrawerIsOpen] = useState<boolean>(false);
  const [audi, setAudi] = useState<number>(0);
  const [volkswagen, setVolkswagen] = useState<number>(0);
  const [wittebrug, setWittebrug] = useState<number>(0);
  const [selected, setSelected] = useState<Selected>({ drawerIsOpen: false, team: null });
  const [literCol, setLiterCol] = useState<any>({});
  const [filteredInfo, setFilteredInfo] = useState<{}>({});
  const [createteamDrawerIsOpen, setCreateteamDrawerIsOpen] = useState<boolean>(true);

  useEffect(() => {
    // context.searchCars("");
    const date = new Date();
    const fromDate = date.toLocaleDateString("fr-CA");
    date.setDate(date.getDate() - DaysInThePast);
    const toDate = date.toLocaleDateString("fr-CA");
    subscribeToPermissions();
    subscribeToUsers();
    subscribeToTeams();
  }, []);
  if (!context || !currentUser) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin></div>;
  }
  if (currentUser && currentUser.role === 2) {
    return <div>
      <Alert
        message="Je hebt niet voldoende rechten om deze pagina te bekijken."
        description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
        type="info"
      />
    </div>;
  }
  const handleButtonClick = (e: any, selectedTeam: any) => {
    setSelected({
      drawerIsOpen: true,
      team: selectedTeam
    });
    setMainDrawerIsOpen(true);
  };

  const handleCreateTeamClick = () => {
    setCreateteamDrawerIsOpen(true);
    setSelected({
      drawerIsOpen: true,
      team: null
    });
  };

  const handleMenuClick = (record: Team) => {
    fireStoreService.deleteTeam(record.id);
  };

  const CustomMenu = ({ record }) => (
    <Menu onClick={() => handleMenuClick(record)}>
      <Menu.Item key="1">
        <Button icon={<DeleteOutlined />} danger shape="round">Verwijderen</Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="dashboard-screen">
      {/* <HikerMenu
        user={context.user}
        kenteken={context.kenteken}
        selectedCar={context.selectedCar}
      /> */}
      <DashBoardMenu context={context} />
      <div className="body-content">
        <Row>
          <Col><h1 className="page-header">Gebruikers</h1></Col>
        </Row>
        <Divider style={{ marginTop: "15px" }} />
        <Row gutter={16}>
          <Col span={24}>
            <Drawer
              headerStyle={{ textAlign: "center" }}
              width={700}
              title={"Team"}
              destroyOnClose={true}
              placement={"left"}
              closable={true}
              onClose={(e) => {
                setSelected({
                  drawerIsOpen: false,
                  team: null
                });
              }}
              visible={selected.drawerIsOpen}
            >
              <TeamDetails users={users} team={selected.team} />
            </Drawer>
            <Tabs defaultActiveKey="2">
              <TabPane tab="Gebruikers" key="1">
                <RBAC permissionsNeeded={["gebruikers:bekijken"]} fallBackComponent={<Result
                  status="403"
                  title="Geen toegang"
                  subTitle="Neem contact op met een van de administators van hikes"
                />} currentUserPermissions={currentUser.permissions} >
                  <Card style={{ width: "100%", marginBottom: "20px" }} className="dashboard-card">
                    <div style={{ marginBottom: "20px" }}>
                      {/* <Button icon={<DownloadOutlined />} data-testid="add-cart-btn" onClick={() => handleCreateTeamClick()}
                      className="add-vehicle" style={{ width: "300px" }} size="large" shape="round">Nieuw team aanmaken</Button> */}
                    </div>
                    <Table dataSource={users} rowKey={(record, index) => `${record.uid}-${index}`} pagination={{ pageSize: 50 }}>
                      <Column title="Gebruikersnaam" dataIndex="first_name" key="first_name"
                        sorter={(a: any, b: any) => a.first_name.localeCompare(b.first_name)}
                        render={(firstName: string) => (<span>{firstName}</span>)}
                        onCell={(value: Vehicle, index) => ({
                          onClick: () => {
                            setMainDrawerIsOpen(true);
                          }

                        })}
                      />
                      <Column title="Email" dataIndex="email" key="email"
                        render={email => (<div>{email}</div>)}
                      />
                      <Column title="Acties" dataIndex="id" key="id"
                        render={(id, record) => (<div>
                          <RBAC permissionsNeeded={["gebruikers:bewerken"]} currentUserPermissions={currentUser.permissions} >
                            <Dropdown.Button onClick={(e) => handleButtonClick(e, record)} overlay={<CustomMenu record={record} />}>
                              Bewerken
                            </Dropdown.Button>
                          </RBAC>
                        </div>)}
                      />

                    </Table>
                  </Card>
                </RBAC>
              </TabPane>
              <TabPane tab="Teams" key="2">
                <RBAC permissionsNeeded={["teams:bekijken"]} fallBackComponent={<Result
                  status="403"
                  title="Geen toegang"
                  subTitle="Neem contact op met een van de administators van hikes"
                />} currentUserPermissions={currentUser.permissions} >
                  <Card style={{ width: "100%", marginBottom: "20px" }} className="dashboard-card">
                    <div style={{ marginBottom: "20px" }}>
                      <Button icon={<DownloadOutlined />} data-testid="add-cart-btn" onClick={() => handleCreateTeamClick()}
                        className="add-teams" style={{ width: "300px" }} size="large" shape="round">Nieuw team aanmaken</Button>
                    </div>
                    <Table className={"team-table"} dataSource={teams} rowKey={(record, index) => `${record.id}-${index}`} pagination={{ pageSize: 50 }}>
                      <Column title="Naam" dataIndex="name" key="name"
                        sorter={(a: any, b: any) => a.name.localeCompare(b.name)}
                        render={(name) => (<span>{name}</span>)}
                        onCell={(value: Vehicle, index) => ({
                          onClick: () => {
                            setMainDrawerIsOpen(true);
                          }

                        })}
                      />
                      <Column title="Omschrijving" dataIndex="description" key="description"
                        render={description => (<div>{description}</div>)}
                      />
                      <Column title="Gebruikers" dataIndex="users" key="users"
                        render={(usersVar, record: any) => (<Badge style={{ fontWeight: "bold" }} size="default" count={users.filter(x => x.team === record.name).length || 0} />)}
                      />
                      <Column title="Acties" dataIndex="id" key="id"
                        render={(id, record) => (<div>
                          <RBAC permissionsNeeded={["teams:bewerken"]} currentUserPermissions={currentUser.permissions} >
                            <Dropdown.Button onClick={(e) => handleButtonClick(e, record)} overlay={<CustomMenu record={record} />}>
                              Bewerken
                            </Dropdown.Button>
                          </RBAC>
                        </div>)}
                      />

                    </Table>
                  </Card>
                </RBAC>
              </TabPane>

              <TabPane tab="Permissisies" key="3">
                <RBAC permissionsNeeded={["permissies:bekijken"]} fallBackComponent={<Result
                  status="403"
                  title="Geen toegang"
                  subTitle="Neem contact op met een van de administators van hikes"
                />} currentUserPermissions={currentUser.permissions} >
                  <Card style={{ width: "100%", marginBottom: "20px" }} className="dashboard-card">
                    <div style={{ marginBottom: "20px" }}>
                      <Button icon={<DownloadOutlined />} data-testid="add-cart-btn" onClick={() => handleCreateTeamClick()}
                        className="add-vehicle" style={{ width: "300px" }} size="large" shape="round">Nieuw team aanmaken</Button>
                    </div>
                    <Table dataSource={permision} rowKey={(record, index) => `${record.id}-${index}`} pagination={{ pageSize: 50 }}>
                      <Column title="Naam" dataIndex="name" key="name"
                        sorter={(a: any, b: any) => a.name.localeCompare(b.name)}
                        render={(name) => (<span>{name}</span>)}
                      />
                      <Column title="Omschrijving" dataIndex="description" key="description"
                        render={description => (<div>{description}</div>)}
                      />
                      <Column title="Acties" dataIndex="id" key="id"
                        render={(id, record) => (<div>
                          <RBAC permissionsNeeded={["permissies:bewerken"]} currentUserPermissions={currentUser.permissions} >
                            <Dropdown.Button onClick={(e) => handleButtonClick(e, record)} overlay={<CustomMenu record={record} />}>
                              Bewerken
                            </Dropdown.Button>
                          </RBAC>
                        </div>)}
                      />

                    </Table>
                  </Card>
                </RBAC>
              </TabPane>
            </Tabs>
          </Col>

        </Row>

      </div >
    </div >
  );
};
export const permision = [{
  id: "1",
  name: "voertuigen:bekijken",
  label: "Voertuigen bekijken",
  description: "Hiermee kan iemand alle voertuigen bekijken",
  type: "bekijken"
}, {
  id: "2",
  name: "voertuigen:bewerken",
  label: "Voertuigen bewerken",
  description: "Hiermee kan je alles bewerken",
  type: "bewerken"
}, {
  id: "3",
  name: "gebruikers:bekijken",
  label: "gebruikers:bekijken",
  description: "Hiermee kan iemand alle voertuigen bekijken",
  type: "bewerken"
},
{
  id: "4",
  name: "permissies:bekijken",
  label: "permissies:bekijken",
  description: "Hiermee kan iemand alle Permissies bekijken",
  type: "bekijken"
},
{
  id: "5",
  name: "permissies:bewerken",
  label: "permissies:bewerken",
  description: "permissies bewerken",
  type: "bewerken"
},
{
  id: "6",
  name: "teams:bewerken",
  label: "teams:bewerken",
  description: "Teams bewerken",
  type: "bewerken"
},
{
  id: "7",
  name: "teams:bekijken",
  label: "teams:bekijken",
  description: "Teams bekijken",
  type: "bekijken"
},
{
  id: "8",
  name: "opdrachten:bekijken",
  label: "Opdrachten:bekijken",
  description: "Opdrachten bekijken",
  type: "bekijken"
},
{
  id: "9",
  name: "opdrachten:bewerken",
  label: "Opdrachten:bewerken",
  description: "Opdrachten bewerken",
  type: "bewerken"
},
{
  id: "10",
  name: "opdrachten:bewerken:seat",
  label: "Opdrachten:bewerken:seat",
  description: "Opdrachten bewerken Seat",
  type: "bewerken"
},
{
  id: "11",
  name: "opdrachten:bekijken:seat",
  label: "Opdrachten:bekijken:seat",
  description: "Opdrachten bekijken Seat",
  type: "bekijken"
},
{
  id: "12",
  name: "opdrachten:bewerken:skoda",
  label: "Opdrachten:bewerken:skoda",
  description: "Opdrachten bewerken Skoda",
  type: "bewerken"
},
{
  id: "13",
  name: "opdrachten:bekijken:skoda",
  label: "Opdrachten:bekijken:skoda",
  description: "Opdrachten bekijken Skoda",
  type: "bekijken"
},
{
  id: "14",
  name: "opdrachten:bewerken:audi",
  label: "Opdrachten:bewerken:audi",
  description: "Opdrachten bewerken audi",
  type: "bekijken"
},
{
  id: "15",
  name: "opdrachten:bekijken:audi",
  label: "Opdrachten:bekijken:audi",
  description: "Opdrachten bekijken Audi",
  type: "bekijken"
},
{
  id: "16",
  name: "opdrachten:bewerken:volkswagen",
  label: "Opdrachten:bewerken:volkswagen",
  description: "Opdrachten bewerken Volkswagen",
  type: "bekijken"
},
{
  id: "17",
  name: "opdrachten:bekijken:volkswagen",
  label: "Opdrachten:bekijken:volkswagen",
  description: "Opdrachten bekijken Volkswagen",
  type: "bekijken"
}
] as Permission[];

export const allPermissions = [
  "voertuigen:bekijken",
  "voertuigen:bewerken",
  "gebruikers:bekijken",
  "gebruikers:bewerken",
  "teams:bekijken",
  "teams:bewerken",
  "opdrachten:bekijken",
  "opdrachten:bewerken",
  "opdrachten:vloot:seat:bekijken",
  "opdrachten:vloot:seat:bewerken",
  "opdrachten:vloot:skoda:bekijken",
  "opdrachten:vloot:skoda:bewerken",
  "opdrachten:vloot:audi:bekijken",
  "opdrachten:vloot:audi:bewerken"
];
