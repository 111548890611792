import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import "antd-mobile/dist/antd-mobile.css";
require("typeface-open-sans");

const log = (...msgs) => {
  if (process.env.NODE_ENV === "development") console.log(...msgs);
};
window.log = log;
global.log = log;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
