import React, { useEffect, useContext, useState } from "react";
import { List } from "antd-mobile";
import { List as List2, Checkbox } from "antd";
import { Team, Permission, WorkOrder, Task, createTankEvent, createEvent } from "@internal/hikes";
import fireStoreService from "../../fireStoreService";
import * as Sentry from "@sentry/browser";
import { AuthContext } from "../../Providers/Auth";
import "react-spring-bottom-sheet/dist/style.css";
import {
    CheckMarkIcon,
    LocationIcon,
    KilometerIcon,
    WaterDropIcon,
    BackIcon,
    AlertMarkIcon
} from "../../components/icons";
import { BottomSheet } from "react-spring-bottom-sheet";
import { SegmentedControl } from "antd-mobile"
import { ArrowLeftOutlined, SmileTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import {
    Table, Row, Col, Radio, Input, Drawer, Button, Tabs, Dropdown, Result, Tag, Menu, Badge, Space, Card, Alert, Spin, Divider, Select, Form, Statistic

} from "antd";
import { WorkOrderProvider, WorkOrderContext } from "../../Providers/WorkOrderProvider";
import { round, StringNullableChain, values } from 'lodash';
import { Vehicle } from "@internal/hikes";
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

export const MobileWorkOrders = () => {
    const [workOrder, setWorkOrder] = useState<WorkOrder>();
    const { currentUser } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState([1, 2]);
    const [savedFilter, setSavedFilter] = useState(0);
    const [openAfrondenSheet, setOpenAfrondenSheet] = useState(false)
    const [afrondenDisabled, setAfrondenDisabled] = useState(false);
    const [form] = Form.useForm();
    const [activeTask, setActiveTask] = useState<Task>(null)
    const { subscribeToWorkOrders, workOrders, prioMapping, stateLabel } = useContext(WorkOrderContext);
    useEffect(() => {
        subscribeToWorkOrders();
    }, []);
    const onSwitch = (index, param2) => {
        const mapping = {
            "Audi": [6],
            "After sales": [3, 4, 5],
            "Verhuur": [1, 2]
        };
        const mapping2 = {
            "Audi": 2,
            "After sales": 1,
            "Verhuur": 0
        };
        // console.log("segmented control", index, mapping2[index])
        // console.log("savedFilter", savedFilter);
        setSavedFilter(mapping2[index]);
        setFilter(mapping[index]);
        // console.log("workOrderFiltered", workOrders, workOrderFilterd.map(x => x.createdByRole).length);
    };
    const handleOpenPage = (value, order) => {
        setWorkOrder(order);
    };
    const handleCheck = (event, item) => {
        const i = workOrder;
        setOpen(true);
        setActiveTask(item);
    };
    const setActive = async () => {
        const newWorkOrder = { ...workOrder, state: 1, assignee: currentUser.first_name, assigneeId: currentUser.uid };
        const order = await fireStoreService.saveWorkOrder(newWorkOrder);
        setWorkOrder(order);
    }
    const handleDiss = (event, item) => {
        const i = workOrder;
        setOpen(true);
        setActiveTask(item);
    };
    const openConfirmation = () => {
        setOpenAfrondenSheet(true);
    };
    const updateAttachedVehicle = (vehicle: Vehicle) => {
        fireStoreService.updateOrCreateVehicle(vehicle);
    };
    const changeWorkOrderState = async (value) => {
        console.log("the value of select", value);
        const newWorkOrder = { ...workOrder, state: value, assignee: currentUser.first_name, assigneeId: currentUser.uid };
        const order = await fireStoreService.saveWorkOrder(newWorkOrder);
        setWorkOrder(order);
    }
    const handleFormSubmitAfronden = () => {
        // console.log('Handle form submit afronden form ')
        form.validateFields()
            .then(async (values) => {
                const vehicleInfo = {
                    ...workOrder.vehicleAttachment,
                    huidige_locatie_op_terein: values.locatie_op_terein || ""
                };
                if (values.locatie_op_terein) {
                    const vehicleEvent = createEvent(values.locatie_op_terein, workOrder.vehicleAttachment.kenteken, "huidige_locatie_op_terein", currentUser);
                    fireStoreService.saveVehicleEvent(vehicleEvent);
                }
                let newWorkOrder = { ...workOrder, state: 2, vehicleAttachment: vehicleInfo } as WorkOrder;
                if (values.comment) {
                    newWorkOrder.comments.push(values.comment);
                }
                if (newWorkOrder.createdByRole <= 2 && values.locatie_op_terein) {
                    updateAttachedVehicle(vehicleInfo);
                }

                // console.log(values, "Afronden values");

                const order = await fireStoreService.saveWorkOrder(newWorkOrder);
                // console.log("upDatedWorkOrder", order);
                setWorkOrder(order);
                form.resetFields();
                setOpenAfrondenSheet(false);
                setAfrondenDisabled(true);
                // message.success("Succesvol opgeslagen", 1);

            })
            .catch((errorInfo) => {
                const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
                Sentry.captureMessage(`validatie fout voor ${errorObject}`, Sentry.Severity.Warning);
                // console.log("error info", errorObject);
                console.log("error info", errorInfo);
            });
    };
    const handleFormSubmit = () => {
        //  console.log('Handle form submit taken ')

        form.validateFields()
            .then(async (values) => {
                console.log("normale scherm")
                if (workOrder) {
                    const tak = workOrder?.task.map((taskArr) => {
                        if (taskArr.id === activeTask.id) {
                            taskArr.isCompleted = true;
                        }
                        return taskArr;
                    });
                    const newWorkOrder = { ...workOrder, task: [...tak] };
                    // console.log(values, "values");
                    // console.log("upDatedWorkOrder", newWorkOrder);
                    const order = await fireStoreService.saveWorkOrder(newWorkOrder);
                    if (values.liters) {

                        const event = createTankEvent(workOrder.vehicleAttachment.kenteken, values.liters, values.organisatie, currentUser.first_name);
                        console.log("starting to create tank event ", event);
                        // @ts-ignore
                        // dataToBeSaved.laatste_tank_event = event;
                        fireStoreService.saveTankEvent(event);
                        const vehicleInfo = {
                            ...workOrder.vehicleAttachment,
                            laatste_tank_event: event
                        };
                        updateAttachedVehicle(vehicleInfo);
                    }
                    setOpen(false);
                    setWorkOrder(order);
                    form.resetFields();
                    // setFormSaved(true);
                    // message.success("Succesvol opgeslagen", 1);
                }

            })
            .catch((errorInfo) => {
                const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
                Sentry.captureMessage(`validatie fout voor ${errorObject}`, Sentry.Severity.Warning);
                // console.log("error info", errorObject);
                console.log("error info", errorInfo);
            });
    };
    const onDismissAfrondenSheet = () => {
        setOpenAfrondenSheet(false);
    };
    const onDismiss = (item, event) => {
        // submit my form;
        // console.log("handlecheck: ", activeTask, workOrder);
        // const tak = workOrder?.task.map((taskArr) => {
        //     if (taskArr.id === activeTask.id) {
        //         taskArr.isCompleted = true;
        //     }
        //     return taskArr;
        // });
        // const newWorkOrder = { ...workOrder, task: [...tak] };
        // console.log(newWorkOrder);
        // fireStoreService.saveWorkOrder(workOrder);
        setOpen(false);
    };
    if (workOrder) {
        return (
            <div className="workOrderDetailMobile">
                <div className="sub-menu">
                    <div className="sub-nav-bar">
                        <div
                            style={{ paddingLeft: "20px" }}
                            className="left-content"
                        >
                            <Button onClick={() => {
                                setWorkOrder(null);
                            }} color="#bfe3ff" style={{ backgroundColor: "#bfe3ff" }} size={"large"} shape="circle" icon={<ArrowLeftOutlined color="blue" style={{ color: "#2b9bfe" }} />} />

                        </div>
                        <div style={{
                            position: "absolute",
                            width: "100 %",
                            top: "30px",
                            left: "36%"
                        }}>

                            <div>
                                <Tag className="kentekenTag">{workOrder?.vehicleAttachment?.kenteken_pretty}</Tag>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: "0 20px" }}>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={8}>
                            <span style={{ fontWeight: "bold" }}>Prioriteit</span>
                            {workOrder.priority === 1 ? <Tag style={{
                                color: "#f50437",
                                fontSize: "14px",
                                fontWeight: "bolder",
                                borderRadius: "10px",
                                backgroundColor: "#fbcad5",
                                borderColor: "#f50437"
                            }}>{prioMapping(workOrder.priority)}</Tag> : <Tag color="green" style={{
                                fontSize: "14px",
                                fontWeight: "bolder",
                                borderRadius: "10px"
                            }}>Normaal</Tag>}
                        </Col>
                        <Col span={8}>
                            <span style={{ fontWeight: "bold" }}>Status</span>
                            <div>
                                {/* <span style={{}}>{stateLabel[workOrder.state]}</span> */}
                                <Select onSelect={changeWorkOrderState} value={workOrder.state} style={{ width: 130, fontWeight: "500" }} bordered={false}>
                                    <Option disabled={workOrder.state == 2} value={0}>Open</Option>
                                    <Option disabled={workOrder.state == 2} value={1}>In behandeling</Option>
                                    <Option disabled value={2}>Klaar</Option>
                                </Select>
                            </div>

                        </Col>
                    </Row>

                </div>
                <Row style={{ margin: "20px 20px" }}>
                    <Col style={{ fontWeight: "500" }} span={24}>
                        <span> <LocationIcon />  Locatie: <span style={{ color: "#008eff" }}>{workOrder?.vehicleAttachment.huidige_locatie_op_terein}</span></span>
                    </Col>
                </Row>
                <Row style={{ margin: "20px 20px" }}>
                    <Col span={24}>
                        <h2>Taken</h2>
                        <List style={{ marginTop: "10px" }}>
                            {workOrder.task.map(order => {
                                return (<List.Item
                                    thumb={order.isCompleted ? <CheckCircleTwoTone style={{ fontSize: '28px' }}
                                        twoToneColor="#52c41a" /> : <SmileTwoTone style={{ fontSize: '28px' }} />} extra={<Checkbox />} onClick={(item) => { handleCheck(item, order); }}>
                                    <span style={{ "textDecoration": order.isCompleted ? "line-through" : "none" }}>{order.name} </span></List.Item>);
                            })
                            }
                        </List >
                    </Col>
                    {workOrder.description &&
                        <Col span={24}>
                            <h2 style={{ marginTop: "20px" }}>Notities</h2>
                            <TextArea value={workOrder.description} readOnly />
                        </Col>
                    }
                </Row>
                <div style={{ margin: "auto", width: "90%", position: "fixed", bottom: "20px", left: 0, right: 0 }}>
                    {workOrder.state == 0 ?
                        <Button style={{ fontSize: "20px", fontWeight: 700, width: "100%", height: "50px" }} onClick={setActive} type="primary" size="large" shape={"round"}>
                            Behandelen
                        </Button> : <Button disabled={workOrder.state == 2} style={{ fontSize: "20px", fontWeight: 700, width: "100%", height: "50px" }} onClick={openConfirmation} type="primary" size="large" shape={"round"}>
                            Afronden
                        </Button>
                    }
                </div>
                {
                    workOrder && activeTask &&
                    <BottomSheet
                        open={open}
                        onDismiss={onDismiss}
                        defaultSnap={({ snapPoints, lastSnap }) =>
                            lastSnap ?? Math.min(...snapPoints)
                        }
                        snapPoints={({ maxHeight }) => [
                            maxHeight - maxHeight / 5,
                            maxHeight * 0.6,
                        ]}
                        header={
                            <h2>
                                {activeTask?.name}
                            </h2>
                        }
                        footer={
                            <Button htmlType="submit" form="workOrderForm"

                                shape="round" type="primary" style={{ fontSize: "20px", fontWeight: 700, width: "100%", height: "50px" }} className="w-full">
                                Klaar
                            </Button>
                        }
                    >
                        <div style={{ margin: "20px 20px" }}>
                            <Form form={form} id="workOrderForm" initialValues={{
                                huidige_locatie_op_terein: workOrder?.vehicleAttachment.huidige_locatie_op_terein,
                                kilometerstand: workOrder?.vehicleAttachment.kilometerstand,
                                verzorgings_status: workOrder?.vehicleAttachment.verzorgings_status,
                                tankinhoud: workOrder?.vehicleAttachment.tankinhoud,
                                voertuig_bescrhijving: workOrder?.vehicleAttachment.voertuig_bescrhijving,
                                organisatie: "Wittebrug Verhuur"
                            }} onFinish={(values) => { handleFormSubmit(); }}>
                                {activeTask.name === "Locatie" &&
                                    <Form.Item name="huidige_locatie_op_terein">
                                        <Input placeholder="locatie opgeven" />
                                    </Form.Item>
                                }
                                {
                                    activeTask.name == "kilometerstand" &&
                                    < Form.Item name="kilometerstand">
                                        <Input
                                            name="kilometerstand"
                                        />
                                    </Form.Item>
                                }
                                {["Tanken", "Shortlease", "Aftersales"].includes(activeTask.name) && <div>
                                    <Form.Item name="organisatie">
                                        <select>
                                            <option key="1" value="Wittebrug Verhuur">Wittebrug Verhuur</option>
                                            <option key="2" value="Audi">Audi</option>
                                            <option key="3" value="Volkswagen">Volkswagen</option>
                                        </select>
                                    </Form.Item>
                                    <Form.Item hasFeedback rules={[{
                                        validateTrigger: "onChange",
                                        message: "Ongeldig",
                                        validator: (rule, value) => {
                                            if (!value || value == 0) {
                                                return Promise.reject(new Error("0 liter is geen waarde"));
                                            }
                                            if (isNaN(value)) {
                                                return Promise.reject(new Error("geen geldige waarde"));
                                            }
                                            // console.log("resolving")
                                            return Promise.resolve();
                                        }

                                    }]} name="liters">
                                        <Input placeholder="liters" />
                                    </Form.Item>

                                </div>
                                }
                            </Form>
                        </div>
                    </BottomSheet >
                }
                <BottomSheet
                    open={openAfrondenSheet}
                    onDismiss={onDismissAfrondenSheet}
                    defaultSnap={({ snapPoints, lastSnap }) =>
                        lastSnap ?? Math.min(...snapPoints)
                    }
                    snapPoints={({ maxHeight }) => [
                        maxHeight - maxHeight / 5,
                        maxHeight * 0.6
                    ]}
                    header={
                        <h2>
                            Opdracht af ronden?
                        </h2>
                    }
                    footer={
                        <Button disabled={workOrder.state === 2} htmlType="submit" form="afrondenForm"
                            shape="round" type="primary" style={{ fontSize: "20px", fontWeight: 700, width: "100%", height: "50px" }} className="w-full">
                            Ja
                        </Button>
                    }
                >  <div style={{ margin: "0 20px", paddingTop: "20px" }}>
                        <Form form={form} id="afrondenForm" onFinish={(values) => { handleFormSubmitAfronden(); }}>

                            <Form.Item name="comment" label="Plaats een optionele opmerking">
                                <TextArea
                                    style={{ borderRadius: "10px" }}
                                    placeholder={"opmerking"}
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </Form.Item>
                            <Form.Item name="kilometerstand" label="Kilometerstand">
                                <Input placeholder="kilometerstand" />
                            </Form.Item>
                            <Form.Item name="locatie_op_terein" label="Waar staat de auto?">
                                <Input placeholder="locatie" />
                            </Form.Item>
                        </Form>
                    </div>
                </BottomSheet>
            </div>
        );
    }
    return (
        <div>
            {/* <Radio.Group onChange={onSwitch} defaultValue="all" buttonStyle="solid">
                <Radio.Button value="all">Alle</Radio.Button>
                <Radio.Button value="verhuur">Verhuur</Radio.Button>
                <Radio.Button value="wasstraat">Wasstraat</Radio.Button>
            </Radio.Group> */}
            <SegmentedControl selectedIndex={savedFilter} onValueChange={onSwitch} values={['Verhuur', 'After sales', "Audi"]} />

            <div>
                {workOrders.filter(x => x.assignee == currentUser.first_name && x.state == 1).length != 0 &&
                    <div>
                        <h2 style={{ marginTop: "20px" }}> Huidige opdrachten</h2>
                        <div style={{ overflowX: "scroll" }}>
                            <List style={{ marginTop: "20px", marginBottom: "20px" }}>
                                {workOrders.filter(x => x.assignee == currentUser.first_name && x.state == 1).map(order => {
                                    return (<List.Item
                                        thumb={<Badge color={order.priority == 1 ? "red" : "green"} status="processing" />} arrow="horizontal" onClick={(item) => { handleOpenPage(item, order); }}>
                                        <Tag className="kentekenTag">{order?.vehicleAttachment?.kenteken_pretty}</Tag>
                                        <span className="list-sub-text"> {order?.vehicleAttachment?.voertuig_model_omschrijving}</span>
                                    </List.Item>);
                                })}
                            </List>
                        </div>
                    </div>
                }
            </div>
            <h2 style={{ marginTop: "10px" }}> Open opdrachten</h2>
            <div style={{ overflowX: "scroll" }}>
                <List style={{ marginTop: "5px" }}>
                    {workOrders.filter(x => x.state === 0 && filter.includes(x.createdByRole)).map(order => {
                        return (<List.Item
                            thumb={<Badge color={order.priority == 1 ? "red" : "green"} status="processing" />} arrow="horizontal" onClick={(item) => { handleOpenPage(item, order); }}>
                            <Tag className="kentekenTag">{order?.vehicleAttachment?.kenteken_pretty}</Tag>
                            <span className="list-sub-text"> {order?.vehicleAttachment?.voertuig_model_omschrijving}</span></List.Item>);
                    })}
                </List>
            </div>
        </div >
    )
};

const selectSize = {
    0: 130,
    1: 130,
    2: 130
};