import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import "./AllCarsScreen.css";

import {
  Table, Row, Col, Tag, Drawer, Button, Space, Card, Alert, Spin, Result, Divider, Select, Form

} from "antd";
import { AppContext } from "../../Providers/MainProvider";
import { CarScreenDesktop } from "../Hikes/Car/CarScreenDesktop";
import { VehicleContext } from "../../Providers/VehicleProvider";
import { DashBoardMenu } from "../../components/DashboardMenu";
import { NewVehicleForm } from "../../components/NewVehicleForm";
import { AuthContext } from "../../Providers/Auth";
import { SearchComponent } from "../../components/Hikers";
import firestoreService from "../../fireStoreService";
import { Vehicle } from '../../types/vehicle';
const { Column } = Table;
const { Option } = Select;
const addDefaultSrc = (ev: any) => {
  ev.target.src = "/assets/cars/unkown-car.png";
};
export const AllCarsDashboardScreen: React.FC = () => {
  useEffect(() => {
    context.searchCars("");
  }, []);
  const [form] = Form.useForm();
  const context = useContext(AppContext);
  const currentVehicleContext = useContext(VehicleContext);

  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [vehicleDrawerisOpen, setVehicleDrawerisOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<String[]>([]);
  if (!context || !currentUser || !context.carSearchResult) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin></div>;
  }
  if (currentUser && currentUser.role == 2) {
    return <div>
      <Alert
        message="Je hebt niet voldoende rechten om deze pagina te bekijken."
        description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
        type="info"
      />
    </div>;
  }
  const handleFilters = (text: string) => {
    setFilters([...filters, text]);
    context.searchCars(text);
  };

  const handleFilterRemove = (tag: String) => {
    // const items = filters.filter(x => x !== tag);
    setFilters([]);
    form.resetFields();
    console.log("reset");
    context.searchCars("");
    // items.length < 0 ? context.searchCars("") : context.searchCars(filters.join(""))
  }
  return (
    <div className="dashboard-screen">
      {/* <HikerMenu
        user={context.user}
        kenteken={context.kenteken}
        selectedCar={context.selectedCar}
      /> */}
      <DashBoardMenu context={context} />
      <div className="body-content">
        <Row>
          <Col><h1 className="page-header">Auto's</h1></Col>
        </Row>
        <Divider style={{ marginTop: "15px" }} />
        {/*<Col span={6}>
           <Card title="Filters" style={{ width: "100%" }} className="dashboard-card">

              {filters.map(value => (
                <Tag closable key={`tag-${value}`} onClose={() => handleFilterRemove(value)}>{value}</Tag>

              ))}
              <Form form={form}>
                <div style={{ marginBottom: "20px" }}>



                  <h3>
                    Merk
                </h3>
                  <Form.Item name="merk">
                    <Select size={"large"} defaultValue="Selecteer" onChange={(value) => handleFilters(value)} style={{ width: "100%", borderRadius: "5px" }}>
                      {
                        context.carSearchResult.facets.brands.map((facet: string) => (<Option key={`brands-${facet}`} value={facet}>{facet}</Option>))
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h3>
                    Model
                </h3>
                  <Form.Item name="brand">
                    <Select size={"large"} defaultValue="Selecteer" onChange={(value) => handleFilters(value)} style={{ width: "100%" }}>
                      {
                        context.carSearchResult.facets.model.map((facet: string) => (<Option key={`model-${facet}`} value={facet}>{facet}</Option>))
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h3>
                    Eigenaar
                </h3>
                  <Form.Item name="owner">
                    <Select size={"large"} defaultValue="Selecteer" onChange={(value) => handleFilters(value)} style={{ width: "100%" }}>
                      {
                        context.carSearchResult.facets.owner && context.carSearchResult.facets.owner.map((facet: string) => (<Option key={`owner-${facet}`} value={facet}>{facet}</Option>))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </Card> 
          </Col>*/}
        <Row gutter={16} style={{ marginBottom: "20px" }}>
          <Col span={24} >
            <Card style={{ width: "100%" }} className="dashboard-card">
              <SearchComponent data={context.carSearchResult.results} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Drawer
              headerStyle={{ textAlign: "center" }}
              width={700}
              title={currentVehicleContext.selectedVehicle ?
                <div> <strong>{currentVehicleContext.selectedVehicle.kenteken_pretty}</strong> <div>{currentVehicleContext.selectedVehicle.voertuig_model_omschrijving}</div> </div> : "Bewerk auto"}
              placement={"left"}
              closable={true}
              onClose={(e) => { setVehicleDrawerisOpen(false); }}
              visible={vehicleDrawerisOpen}
            >
              <CarScreenDesktop />
            </Drawer>
            <Card title="Auto's" style={{ width: "100%", marginBottom: "20px" }} className="dashboard-card">
              <div style={{ marginBottom: "20px" }}>
                {!isOpen && <Button data-testid="add-cart-btn" className="add-vehicle" size="large" shape="round" onClick={() => { setIsOpen(true); }}  >Auto toevoegen</Button>}
              </div>

              <Drawer
                data-testid="drawer-addCart"
                width={500}
                title="Nieuwe auto toevoegen"
                placement={"right"}
                closable={true}
                onClose={(e) => { setIsOpen(false); context.searchCars(""); }}
                visible={isOpen}
              >
                {context.loginErrorMessage &&
                  <Alert
                    message="Error"
                    description={context.loginErrorMessage}
                    type="error"
                    showIcon
                  />
                }
                <Spin spinning={context.loading} tip="Bezig met opslaan...">
                  {!context.vehicleCreated && <NewVehicleForm />}
                </Spin>
                {context.vehicleCreated && <Result
                  status="success"
                  title="Auto is opgeslagen"
                  data-testid="added-vehicle-success"
                  subTitle=""
                  extra={[
                    <Button shape="round" onClick={() => {
                      context.setVehicleCreated(false); ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed add car vehicle",
                        label: "Add Vehicle",
                        value: 1
                      });
                    }} type="primary" key="console">
                      Nog één auto toevoegen
                    </Button>
                  ]}
                />}
              </Drawer>
              <Table dataSource={context.carSearchResult.results} rowKey={record => record.kenteken}
                pagination={{ pageSize: 50 }}>
                <Column title="Model" dataIndex="model" key="kenteken_pretty2"
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })}
                  render={vehicle => (
                    <img style={{ width: "80px", height: "auto" }} onError={addDefaultSrc} src={`/assets/cars/${vehicle.toLowerCase()}.png`} alt="car" />

                  )} />
                <Column title="Kenteken" dataIndex="kenteken_pretty" key="kenteken_pretty"
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })}
                  render={kenteken => (
                    <Tag className="kentekenTag">
                      {kenteken}
                    </Tag>
                  )}
                />
                <Column title="Voertuig" dataIndex="voertuig_model_omschrijving" key="voertuig_model_omschrijving"
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })} />
                {/* <Column title="Bewerker" dataIndex="laast_aangepast_door" key="laast_aangepast_door" /> */}
                <Column title="Locatie" dataIndex="huidige_locatie_op_terein" key="huidige_locatie_op_terein"
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })} />
                <Column title="Status" dataIndex="verzorgings_status" key="verzorgings_status"
                  onCell={(value: Vehicle, index) => ({
                    onClick: () => {
                      currentVehicleContext.getVehicle(value.kenteken);
                      ReactGA.event({
                        category: "All Vehicle",
                        action: "User pressed vehicle drawer from table",
                        label: `Vehicle Drawer opend for ${value.kenteken}`,
                        value: 1
                      });
                      setVehicleDrawerisOpen(true);
                    }

                  })} />
                {/* <Column
                  title="Actie"
                  key="kenteken"
                  dataIndex="kenteken"
                  render={(kenteken, record: Vehicle, index) =>
                    record.inQuarantaine &&
                    (
                      <Space>
                        <Button onClick={() => {
                          firestoreService.updateCarByKenteken(kenteken, { inQuarantaine: false })
                          console.log("text", context.vehicleSearchText)
                          context.searchCars("");
                        }}>
                          Toevoegen
                    </Button>
                        <Button onClick={() => {
                          firestoreService.updateCarByKenteken(kenteken, { isDeleted: true })
                          console.log(context.vehicleSearchText)
                          context.searchCars("");
                        }}

                        >
                          Verwijderen
                   </Button>
                      </Space>
                    )}
                /> */}
              </Table>
            </Card>
          </Col>

        </Row>

      </div >
    </div >
  );
};


