import React, { useContext, useState, useEffect } from "react";
import "./users.css";
import { Form, Input, Button, Table, Select, Result, Drawer, Spin, Alert, Avatar, Divider } from "antd";
import moment from "moment";
import { AppContext } from "../../Providers/MainProvider";
import { DashBoardMenu } from "../../components/DashboardMenu";
import firestore from "../../fireStoreService";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { UserEditForm } from "../../components/UserEditForm";
import { AuthContext } from "../../Providers/Auth";
const { Column } = Table;

const columns = [
    {
        title: "E-mail",
        dataIndex: "email",
        key: "kenteken"
    },
    {
        title: "Naam",
        dataIndex: "first_name",
        key: "model"
    },
    {
        title: "Acties",
        dataIndex: "eigenaar",
        key: "eigenaar"

    }
];

const UserScreen: React.FC = () => {
    const context = useContext(AppContext);
    const { currentUser } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [drawerUser, setDrawerUser] = useState<any>(null);
    const [userIsOpen, setUserIsOpen] = useState<boolean>(false);
    const [passwordResetSuccessfull, setPasswordResetSuccessfull] = useState<boolean>(false);
    const [passwordResetPending, setPasswordResetPending] = useState<boolean>(false);
    useEffect(() => {
        context.getUsers();
    }, []);
    if (!context || !currentUser) {
        return <div>Loading....</div>;
    }
    if (currentUser && currentUser.role == 2) {
        return <div>
            {console.log(currentUser.role)}
            <Alert
                message="Je hebt niet voldoende rechten om deze pagina te bekijken."
                description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
                type="info"
            />
        </div>;
    }
    const resetPassword = (email: string) => {
        setPasswordResetPending(true);
        firestore.forgotPassword(email, (message, err) => {
            setPasswordResetPending(false);
            if (err) {
                // set error
                setPasswordResetSuccessfull(false);
            } else {
                setPasswordResetSuccessfull(true);
            }
        });
    };
    return (
        <div className="allcar-screen">
            <DashBoardMenu context={context} />

            <div style={{ padding: "20px 20px 0 225px" }}>
                <h1>Gebruikers</h1>
                <Divider />
                <div style={{ marginBottom: "20px" }}>
                    {!isOpen && <Button shape="round" onClick={() => { setIsOpen(true); }}  >Nieuwe gebruiker toevoegen</Button>}
                </div>
                <Drawer
                    width={500}
                    title="Gebruiker aanmaken"
                    placement={"right"}
                    closable={true}
                    onClose={(e) => { setIsOpen(false); }}
                    visible={isOpen}
                >
                    {context.loginErrorMessage &&
                        <Alert
                            message="Fout"
                            description={context.loginErrorMessage}
                            type="error"
                            showIcon
                        />
                    }
                    <Spin spinning={context.loading} tip="Bezig met opslaan...">
                        {!context.userCreated && <UserForm />}
                    </Spin>
                    {context.userCreated && <Result
                        status="success"
                        title="Gebruiker is aangemaakt"
                        subTitle=""
                        extra={[
                            <Button shape="round" onClick={() => { context.setUserCreated(false); }} type="primary" key="console">
                                Nog iemand toevoegen
                            </Button>
                        ]}
                    />},
                </Drawer>
                <Table dataSource={context.users} onRow={(r) => ({
                    onClick: () => { setUserIsOpen(true); setDrawerUser(r); }
                })}>
                    <Column title="E-mail" dataIndex="email" key="email" />
                    <Column title="Gebruikersnaam" dataIndex="first_name" key="email" />
                    <Column title="Rol" dataIndex="role" key="role" render={role => (
                        <span>
                            {role === 1 ? "Admin" : "Gebruiker"}
                        </span>
                    )} />
                    <Column
                        title="Mutatie datum"
                        dataIndex="laast_aangepast_op"
                        key="laast_aangepast_op"
                        render={date => (
                            <span>
                                {moment(date).format("DD-MM HH:mm")}
                            </span>
                        )}
                    />
                </Table>
                <Drawer
                    headerStyle={{ textAlign: "center" }}
                    width={500}
                    title={drawerUser ? <div> <strong>{drawerUser.first_name}</strong> <div>{drawerUser.email}</div> </div> : "Bewerk klant"}
                    placement={"left"}
                    closable={true}
                    onClose={(e) => { setUserIsOpen(false); setPasswordResetSuccessfull(false); }}
                    visible={userIsOpen}
                >
                    {!setPasswordResetSuccessfull &&
                        <Alert
                            message="Fout"
                            description={"er is iets fout gegaan probeer het opniew of neem contact op met de administrator"}
                            type="error"
                            showIcon
                        />
                    }

                    <Spin spinning={passwordResetPending} tip="Bezig met resetten...">
                        {passwordResetSuccessfull && <Result
                            status="success"
                            title="Wachtwoord reset e-mail is verzonden"
                            subTitle=""
                        />}

                        <Avatar style={{
                            marginBottom: "20px", display: "table",
                            margin: "20px auto"
                        }} shape="circle" size={250} icon={<UserOutlined />} />
                        <Button type="primary" onClick={() => resetPassword(drawerUser.email)}>
                            Wachtwoord resetten
                        </Button>


                    </Spin>
                    {/* <UserEditForm currentUser={drawerUser} /> */}
                </Drawer>
            </div>

        </div >
    );
};

const UserForm = () => {
    const context = useContext(AppContext);
    return (
        <Form size="large" style={{ marginTop: "40px" }} onFinish={(values) => { context.onSubmitNewUserForm(values); }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item
                name="email"
                label="E-mailadres"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: true }]}
                name="first_name"
                label="Voornaam"
            >
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: true }]}
                name="last_name"
                label="Achternaam"
            >
                <Input />
            </Form.Item>
            <Form.Item name="role" label="Rol" rules={[{ required: true }]}>
                <Select
                    placeholder="Selecteer rol"
                    allowClear
                >
                    <Select.Option value={2}>Gebruiker</Select.Option>
                    <Select.Option value={1}>Admin</Select.Option>
                    <Select.Option value={3}>After Sales Seat</Select.Option>
                    <Select.Option value={4}>After Sales Skoda</Select.Option>
                    <Select.Option value={5}>After Sales Volkswagen</Select.Option>
                    <Select.Option value={6}>After Sales Audi</Select.Option>

                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Opslaan
                </Button>
            </Form.Item>
        </Form >);
};

export default UserScreen;
