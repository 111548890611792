import React, { useContext } from "react";
import "./LoginScreen.css";
import { Form, Input, Button } from "antd";
import { NoticeBar, ActivityIndicator } from "antd-mobile";
import { AppContext } from "../../Providers/MainProvider";
import { LoginIcon, LogoIcon } from "../../components/icons";
import { CheckCircleOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";

const LoginScreen: React.FC = () => {
  const context = useContext(AppContext);
  if (!context) {
    // console.log("Carscreen loading context");
    return <div>Loading</div>;
  }
  return (
    <div className="login-screen">
      {context.loginErrorMessage && (
        <NoticeBar
          mode="closable"
          icon={<CheckCircleOutlined />}
        >
          {context.loginErrorMessage}
        </NoticeBar>
      )}
      <ActivityIndicator animating={context.loading} toast text="bezig..." />
      <div className="top-section">
        <div className="logo">
          {/* <LogoIcon /> */}
          <div style={{ marginBottom: "50px" }}>
            <LoginIcon />
          </div>
          <h3 className="login-header">Login bij Hikes</h3>
          {/* <h4 style={{ width: "300px" }}>Leuk dat je wilt inloggen bij Hikes.
            Vul je gegevens in en voila!</h4> */}
        </div>
      </div>
      <div style={{ padding: "0 20px" }} className="">
        <Form
          labelCol={{ span: 24 }}
          onFinish={(values) => context.onSubmitLoginForm(values)}
          className="login-form"
        >
          <Form.Item label="E-MAILADRES" name="username" shouldUpdate rules={[{ required: true }]}>
            <Input
              style={style.input}
              prefix={
                <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              placeholder="Gebruikersnaam"
            />
          </Form.Item>
          <Form.Item label="WACHTWOORD" name="password" rules={[{ required: true }]}>
            <Input
              style={style.input}
              prefix={
                <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
              }
              type="password"
              placeholder="Wachtwoord"
            />
          </Form.Item>
          <Form.Item>
            {/* <a className="login-form-forgot">Forgot password</a> */}
            <Button
              style={{ borderRadius: "32px", height: "50px", fontWeight: 800 }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Inloggen
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div >
  );
};

const style = {
  input: {
    borderRadius: "5px"
  }
};

export default LoginScreen;
