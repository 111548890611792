import React, { useEffect, useState } from "react";
import fireStoreService from "../fireStoreService";
import { useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import {
    useWindowSize
} from "@react-hook/window-size";
import { Team, Permission } from "@internal/hikes";
import { User } from "../types/user";

// type VehicleProps = {
//     currentVehicle: Vehicle | null,
//     pending: boolean
// };

// @ts-ignore

interface UserProviderContext {
    users: User[];
    teams: Team[];
    permissions: Permission[];
    loading: boolean;
    subscribeToUsers: () => void;
    subscribeToTeams: () => void;
    subscribeToPermissions: () => void;
    getUserById: (uid: string) => User;
    getTeamById: (uid: string) => Team;
}
export const UserProviderContext = React.createContext<UserProviderContext>({
    users: [],
    teams: [],
    permissions: [],
    subscribeToPermissions: Function,
    subscribeToTeams: Function,
    subscribeToUsers: Function,
    getUserById: null,
    getTeamById: null,
    loading: true
});
// @ts-ignore
export const UserProvider = ({ children }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            console.log("UserProvider loading");
        }
        setLoading(false);
        // subscribeToUsers();
        // subscribeToTeams();
        return () => {
            console.log("unmounting UserProvider");
            fireStoreService.subscribeToUsers(() => { });
        };

    }, []);

    const subscribeToUsers = () => {
        fireStoreService.subscribeToUsers((data: any) => {
            setUsers(data);
            // setPending(false)
        });
    };
    const getUserById = (uid: string) => {
        // console.log("GetUserById");
        return users.find(x => x.uid === uid);
    };
    const subscribeToTeams = () => {
        if (process.env.NODE_ENV !== "production") {
            console.log("subscribe teams");
        }

        fireStoreService.subscribeToResources("/teams", (data: any) => {
            setTeams(data);
            // setPending(false)
        });
    };

    const subscribeToPermissions = () => {
        // console.log("subscribe permissions");
        fireStoreService.subscribeToResources("/permissions", (data: any) => {
            setPermissions(data);
            // setPending(false)
        });
    };
    const getTeamById = (uid: string) => {
        return teams.find(x => x.id === uid);
    };
    return (
        // @ts-ignore
        <UserProviderContext.Provider
            value={{
                permissions,
                loading,
                teams,
                users,
                subscribeToTeams,
                subscribeToUsers,
                subscribeToPermissions,
                getUserById,
                getTeamById
            }}
        >
            {children}
        </UserProviderContext.Provider>
    );
};
