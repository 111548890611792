import React, { useContext } from "react";
import moment from "moment";
import formatLicenseplate from "../licenseplateFormater";
import { AppContext } from "../Providers/MainProvider";
import {

    Avatar, Comment, Tooltip, Tag, List, Input

} from "antd";
import { Button } from "antd-mobile";

const { Search } = Input;
// @ts-ignore
export const Hikers = ({ data }) => {
    return (<Comment
        author={data.gebruikersnaam}
        avatar={
            <Avatar>{data.gebruikersnaam.charAt(0).toUpperCase()}</Avatar>
        }
        content={
            <p>
                <Tag color="success">{formatLicenseplate(data.kenteken)}</Tag> <strong>{data.liters}</strong> liter {data.organisatie}
            </p>
        }
        datetime={
            <Tooltip title={moment(data.event_datum).format("YYYY-MM-DD HH:mm:ss")}>
                <span>{moment(data.event_datum).fromNow()}</span>
            </Tooltip>
        }
    />);

};
// @ts-ignore
export const UnkownVehicle = ({ data }) => {
    return (<Comment
        author={data.gebruikersnaam}
        avatar={
            <Avatar>{data.gebruikersnaam.charAt(0).toUpperCase()}</Avatar>
        }
        content={
            <p>
                <Tag color="error">{formatLicenseplate(data.kenteken)}</Tag>
            </p>
        }
        datetime={
            <Tooltip title={moment(data.event_datum).format("YYYY-MM-DD HH:mm:ss")}>
                <span>{moment(data.event_datum).fromNow()}</span>
            </Tooltip>
        }
    />)

}

// @ts-ignore
export const QuarantineList = ({ data }) => {
    return (<List>
        {data.map((item: any) => (
            <List.Item
                actions={[<Button>Toevoegen</Button>, <Button>Verwijderen</Button>]}
            >
                <List.Item.Meta
                    avatar={
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    }
                    title={item.kenteken_pretty}
                    description=""
                />
            </List.Item>
        ))}

    </List>)

}

// @ts-ignore
export const SearchComponent = ({ data }) => {
    const context = useContext(AppContext);
    const onSearch = (value: any) => {
        if (value && value.target && value.target.value) {
            context.searchCars(value.target.value);
        } else {
            context.searchCars("");
        }

    }
    return (<div>
        <Input
            placeholder="Zoek opdracht"
            allowClear
            size="large"
            onChange={onSearch}
        />
        <div style={{ marginTop: "10px" }}><strong>{data.length} Auto(s)</strong></div>
    </div>)

}