/* tslint:disable:triple-equals  */
import * as Sentry from "@sentry/browser";
import React, { useState, useEffect, useContext } from "react";
import { notification } from "antd";
import formatLicenseplate, {
  unformatLicenseplate
} from "../licenseplateFormater";
import fireStoreService from "../fireStoreService";
import { withRouter } from "react-router-dom";
import { User } from "../types";
import { Vehicle, Task } from "../types/vehicle";
import { v4 as uuidv4 } from "uuid";
import { search, SearchResult } from "../search";
import moment from "moment";
import ReactGA from "react-ga";
import { TankEvent, TankForm, UnkownVehicleEvent, VehicleEvent, Action } from "@internal/hikes";
import { getRdwVehicle, carFactory, VehicleDraft } from "@internal/hikes";
import { AuthContext } from "./Auth";
import _ from "lodash";
import {
  useWindowSize
} from "@react-hook/window-size";

// var getPosition = function (options) {
//   return new Promise(function (resolve, reject) {
//     navigator.geolocation.getCurrentPosition(resolve, reject, options);
//   });
// };
const initialState: AppContext = {
  user: null,
  cars: [],
  users: [],
  relatedCars: [],
  saving: false,
  selectedCar: null,
  dropDownIsOpen: false,
  openNotificationModal: false,
  kenteken: "",
  editCarVisible: false,
  currentCarTankLevel: 0,
  loginErrorMessage: "",
  loading: false,
  tankDropdownVisible: "hide",
  tankButtonVisible: "show",
  vehicleSearchText: "",
  onSubmitLoginForm: Function,
  onSubmitNewUserForm: Function,
  login: Function,
  handleTankenDropDown: Function,
  openModal: Function,
  userCreated: false,
  setUserCreated: Function,
  setVehicleCreated: Function,
  setActiveCar: Function,
  handleKenteken: Function,
  showEditCarModal: Function,
  handleEditCarModalOk: Function,
  handleEditCarModalCancel: Function,
  getUsers: Function,
  subscribeToVehicleUpdates: Function,
  createVehicle: Function,
  vehicleCreated: false,
  setInProgress: Function,
  searchCars: Function,
  carSearchResult: null,
  tankForm: { liters: "" },
  handleTankForm: Function,
  /// new
  vehicleQue: [],
  pushToQue: Function,
  vehicleHistory: []
};

interface AppContext {
  user: User | null;
  users: User[];
  cars: Vehicle[];
  relatedCars: Vehicle[];
  saving: boolean;
  selectedCar: Vehicle | null;
  dropDownIsOpen: boolean;
  openNotificationModal: boolean;
  kenteken: string;
  editCarVisible: boolean;
  currentCarTankLevel: number;
  loginErrorMessage: string;
  loading: boolean;
  tankDropdownVisible: string;
  tankButtonVisible: string;
  userCreated: boolean;
  vehicleCreated: boolean;
  vehicleSearchText: string;
  carSearchResult: SearchResult | null;
  searchCars: (...p: any) => any;
  setUserCreated: (...p: any) => any;
  setVehicleCreated: (...p: any) => void;
  onSubmitLoginForm: (...p: any) => any;
  onSubmitNewUserForm: (...p: any) => any;
  login: (...p: any) => any;
  handleTankenDropDown?: (...p: any) => any;
  openModal: (...p: any) => any;
  handleFieldsInModal?: (element: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  setActiveCar: (id: string) => any;
  handleKenteken: (...p: any) => any;
  showEditCarModal: (...p: any) => void;
  handleEditCarModalOk: (...p: any) => any;
  handleEditCarModalCancel: (...p: any) => any;
  createVehicle: (...p: any) => any;
  getUsers: (...p: any) => any;
  subscribeToVehicleUpdates: (...p: any) => any;
  setInProgress: (...p: any) => any;
  tankForm: TankForm;
  handleTankForm: (...p: any) => any;
  vehicleQue: Action[];
  pushToQue: (...p: any) => any;
  vehicleHistory: Vehicle[];
}


export const AppContext = React.createContext<AppContext>(initialState);
export const useAppContext = () => useContext(AppContext);

const createMessage = (code: string) => {
  let message = code;
  if (code === "auth/argument-error") {
    message = "Je gebruikersnaam en wachtwoord komen niet overeen";
  }
  if (code === "auth/user-not-found") {
    message = "Je gebruikersnaam en wachtwoord komen niet overeen";
  }

  if (code === "auth/wrong-password") {
    message = "Je gebruikersnaam en wachtwoord komen niet overeen";
  }


  return message;
};

const AppProvider = ({ children, history, location }: any) => {
  const [user, setUser] = useState<User | null>(null);
  // const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [dropDownIsOpen, setdropDownIsOpen] = useState<boolean>(false);
  const [kenteken, setkenteken] = useState<string>("");
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCar, setSelectedCar] = useState<Vehicle | null>(null);
  const [editCarVisible, setEditCarVisible] = useState<boolean>(false);
  const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);
  const [relatedCars, setRelatedCars] = useState<Vehicle[]>([]);
  const [tankDropdownVisible, setTankDropdownVisible] = useState<string>("hide");
  const [tankButtonVisible, setTankButtonVisible] = useState<string>("show");
  const [saving, setSaving] = useState<boolean>(false);
  const [showInstallMessage, setShowInstallMessage] = useState<boolean>(false);
  const [currentCarTankLevel, setCurrentCarTankLevel] = useState<number>(0);
  const [cars, setCars] = useState<Vehicle[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [tankForm, setTankenForm] = useState<TankForm>({ liters: "", organisatie: "" });
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [vehicleCreated, setVehicleCreated] = useState<boolean>(false);
  const [carSearchResult, setCarSearchResult] = useState<SearchResult | null>(null);

  const [vehicleSearchText, setVehicleSearchText] = useState<string>("");
  const [audi, setAudi] = useState<number>(0);
  const [volkswagen, setVolkswagen] = useState<number>(0);
  const [wittebrug, setWittebrug] = useState<number>(0);
  const [hikers, setHikers] = useState<any>(null);
  const [width] = useWindowSize();

  const { currentUser } = useContext(AuthContext);
  const [key, setKey] = useState<string>("");
  const [vehicleQue, setVehicleQue] = useState<Action[]>([]);
  const [formState, setFormState] = useState<{}>({});
  const [vehicleHistory, setVehicleHistory] = useState<Vehicle[]>([]);
  // Form state we willen weten of het formulier ingevuld is, want soms kunnen dezelfde waardes ingevuld worden.

  const handleTankenDropDown = () => {
    // Create tank event
    // const tankEvent = {
    //   organisatie: "",
    //   getankt_door: "",
    //   kenteken: "",
    //   liters: 0,
    //   getankt_op: new Date().toISOString()
    // };
    setdropDownIsOpen(true);
    if (selectedCar) {
      ReactGA.event({
        category: "Tank dropdown",
        action: "Open",
        label: `Tank dropdown ${selectedCar.kenteken} geopend`,
        value: 1
      });
    }

  };
  const handleKenteken = (el: any) => {

    setkenteken(el.target.value);
    const formatted = formatLicenseplate(el.target.value);
    setkenteken(formatted);
  };

  const pushToQue = (event: Action) => {
    // set item to que
    setVehicleQue([...vehicleQue, event]);
  };

  const handleTankForm = (el: any) => {
    setTankenForm({ ...tankForm, liters: el.target.value });
  };

  const searchCars = (text: string) => {
    setVehicleSearchText(text);
    const data = search(text, cars);
    if (text && text.length > 3) {
      ReactGA.event({
        category: "Search",
        action: "All Vehicle search",
        label: text,
        value: 1
      });
    }
    setCarSearchResult(data);
  };

  // const getUserProfile = () => {
  //   return localStorage.getItem("user");
  // };
  // const getAllCars = async () => {
  //   const result = await fireStoreService.getAllCars();
  //   if (result) {
  //     setCars(result);
  //   }

  // };
  // Subsribe to Vehicle Events
  const subscribeToVehicleUpdates = async (callBack?: (data: Vehicle[]) => void) => {
    fireStoreService.subscribeToVehicles("cars/", (data) => {
      ReactGA.event({
        category: "Voertuigen",
        action: "autos ophalen",
        label: `${currentUser?.first_name} heeft ${data.length} opgehaald`,
        value: data.length
      });
      setCars(data);
      if (callBack) {
        callBack(data);
      }
    });
  };
  // const subscribeToTankEvents = async (callBack?: (data: Vehicle[]) => void) => {
  //   fireStoreService.subscribeToTankEvents("tank_events", "2020-10-11T18:56:23.510Z", "2020-09-27T18:56:23.511Z", (data: any[]) => {
  //     const grouped = _(data).groupBy("organisatie").map((deparment, index) => {
  //       return {
  //         organisatie: index,
  //         amount: _.sumBy(deparment, "liters")
  //       };
  //     }).value();
  //     // console.log("tank events", data)
  //     setAudi(_.get(grouped.find(x => x.organisatie === "Audi"), "amount", 0));
  //     setVolkswagen(_.get(grouped.find(x => x.organisatie === "Volkswagen"), "amount", 0));
  //     setWittebrug(_.get(grouped.find(x => x.organisatie === "Wittebrug"), "amount", 0));
  //     setHikers(data);
  //   });
  // };

  const getUsers = async () => {
    fireStoreService.subscribeToUsers((data) => {
      setUsers(data);
    });

  };
  const login = async (username: string, password: string) => {
    try {
      const result = await fireStoreService.login(username, password);
      // console.log("result", result);
      setLoginErrorMessage("");
      setLoading(false);
      history.push("/");
    } catch (error) {
      // console.log(error);
      Sentry.captureException(error);
      setLoginErrorMessage(createMessage(error.code));
      setLoading(false);
    }
  };
  const getKey = () => {
    return `${currentUser?.uid}-hikesvehicleHistory`;
  };
  const addVehicle = (vehicle: Vehicle) => {
    const items = window.localStorage.getItem(getKey());
    // console.log("parse", JSON.parse(items))
    const parsedItems = JSON.parse(items) || [];
    const newArr = parsedItems.filter(x => x.id != vehicle.id);
    // console.log("add vehicle 1", parsedItems);
    newArr.unshift(vehicle);
    if (newArr.length > 20) {
      newArr.pop();
    }
    setVehicleHistory(newArr);
    window.localStorage.setItem(getKey(), JSON.stringify(newArr));

  };
  const loadHistory = () => {
    const items = window.localStorage.getItem(getKey());
    // console.log("loadHistory", getKey());
    // console.log("parse", JSON.parse(items))
    const parsedItems = JSON.parse(items) || [];
    setVehicleHistory(parsedItems);

  };
  const setActiveCar = async (id: string) => {
    try {
      window.scrollTo(0, 0);
      // Added a user to ensure a user is login all the time check
      if (id && currentUser) {

        const carId = unformatLicenseplate(id);
        const car: Vehicle = await fireStoreService.getCarByKenteken(carId) as Vehicle;
        if (car) {
          setSelectedCar(car);
          setdropDownIsOpen(false);
          addVehicle(car);

          const relatedVehicles = await fireStoreService.getRelatedCars(
            car.kenteken,
            car.model
          ) as Vehicle[];
          setRelatedCars(relatedVehicles);
          // this.setState({ relatedCars: relatedCars });

          /// Set ReactGA Event
          ReactGA.event({
            category: "Voertuig Bekeken",
            action: car.kenteken,
            label: `${currentUser.first_name} heeft het voertuig met kenteken: ${car.kenteken} bekeken`,
            value: 1
          });
        } else {
          if (carId.length == 6) {

            const rdwResult = await getRdwVehicle(carId);
            if (rdwResult) {

              const vehicleDraft = {
                kenteken: carId,
                priority: "1",
                eigenaar: "Wittebrug Rent",
                inQuarantaine: false

              } as VehicleDraft;

              const newVehicle = carFactory(vehicleDraft, rdwResult, currentUser.first_name);
              openNotification(`Het kenteken "${carId}" is niet gevonden in het bestand, volgens de RDW is het een ${newVehicle.voertuig_model_omschrijving}  `);
              // console.log("car exists tyring to create a new one", newVehicle);
              setSelectedCar(newVehicle);

            }


          } else {
            openNotification(`Het kenteken "${carId}" is niet gevonden, we zijn op de hoogte gesteld en zullen het zo snel mogelijk proberen te verhelpen.`);
          }
          // const unkownEvent = {
          //   kenteken: carId,
          //   event_datum: new Date().toISOString(),
          //   gebruikersnaam: currentUser.first_name
          // } as UnkownVehicleEvent;

          // fireStoreService.saveUnKownVehicle(unkownEvent);
          ReactGA.event({
            category: "Voertuig niet gevonden",
            action: carId,
            label: `${currentUser.first_name} heeft gezocht naar het voertuig met kenteken: ${carId}`,
            value: 1
          });

          Sentry.captureMessage(`Het kenteken "${carId}" is niet gevonden door gebruiker: ${currentUser.first_name}`, Sentry.Severity.Warning);
        }

      } else {
        setSelectedCar(null);
        setTankenForm({ ...tankForm, liters: "" });
        setRelatedCars([]);
        // this.setState({ selectedCar: null, relatedCars: [] });
      }
    } catch (err) {
      Sentry.captureException(err);
    }

  };
  const blockVehicle = (kentekenPlaat: string, block: boolean, userCar: string) => {
    if (selectedCar) {
      const carBlocker = block ? userCar : null;
      fireStoreService.updateCarByKenteken(kentekenPlaat, { isBlocked: block, blockedBy: carBlocker });
    }
  };

  const showEditCarModal = () => {
    // beta feature to block usage
    const newCar = { ...selectedCar, isBlocked: true } as Vehicle;
    setSelectedCar(newCar);
    if (selectedCar) {
      fireStoreService.updateCarByKenteken(selectedCar.kenteken, { isBlocked: true });
    }
    setEditCarVisible(true);
  };
  useEffect(() => {

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    // @ts-ignore
    const isInStandaloneMode = () => ("standalone" in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }
    if (currentUser) {
      setUser(currentUser);
      loadHistory();
    }
    // fireStoreService.onAuthUserListener(
    //   (firebaseUser: any) => {
    //     console.log("loggedin", firebaseUser)
    //     setUser(firebaseUser);
    //     subscribeToVehicleUpdates();
    //     // setLoading(false);
    //     localStorage.setItem("user", JSON.stringify(user));
    //     console.log("location", location.pathname, firebaseUser.role);
    //     if (location.pathname === "/login" && firebaseUser.role === 1) {
    //       history.push("/dashboard");
    //     }
    //     if (location.pathname === "/login" && firebaseUser.role === 2) {
    //       history.push("/zoeken");
    //     }
    //     // Set UA
    //     if (user) {
    //       ReactGA.set({
    //         userId: user.first_name
    //         // any data that is relevant to the user session
    //         // that you would like to track with google analytics
    //       });
    //     }

    //   },
    //   (error: any) => {
    //     // console.log("what is the error", error);
    //     Sentry.captureException(error);
    //     localStorage.removeItem("user");
    //     // this.setState({ loginErrorMessage: error, loading: false });
    //     history.push("/login");
    //   }
    // );

  }, [history]);
  const onSubmitNewUserForm = (values: any) => {
    setLoading(true);
    setLoginErrorMessage("");
    if (values) {
      const userProfile: User = {
        email: values.email,
        username: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        role: values.role ? parseInt(values.role) : 2,
        team: "Wittebrug Rent",
        permissions: [],
        teams: []
      };
      createUser(userProfile.username, "hike123!", userProfile);
    } else {
      setLoading(false);
      setLoginErrorMessage("Niet alle velden zijn ingevuld");
    }

  };
  const setInProgress = () => {
    if (selectedCar && currentUser) {
      console.log("set in progress", currentUser);
      const username = currentUser.first_name;
      // @ts-ignore

      const bool = cars.find(x => x.kenteken == selectedCar.kenteken).isBlocked;
      blockVehicle(selectedCar.kenteken, !bool, username);
    }

  };
  const createUser = async (username: string, password: string, userProfile: User) => {
    try {
      const result = await fireStoreService.createProfile(username, password, userProfile);
      setLoading(false);
      setUserCreated(true);
      // Set success message
      setUserCreated(true);
    } catch (error) {
      setLoading(false);
      setLoginErrorMessage(error.message);
      Sentry.captureException(error);
    }

  };
  const validateVehicle = async (kenteken2: any) => {
    setLoginErrorMessage("");
    const autoKenteken = kenteken2.replace(/-/g, "").toUpperCase();
    setLoading(true);
    const rdwResult = await getRdwVehicle(kenteken2);
    setLoading(false);
    return;

  };
  const createVehicle = async (values: any) => {
    setLoginErrorMessage("");
    setLoading(true);
    try {
      const autoKenteken = values.kenteken.replace(/-/g, "").toUpperCase();
      // console.log(rdwResult);
      if (!autoKenteken) {
        setLoading(false);
        Sentry.captureMessage(`no autokenteken ${autoKenteken}`, Sentry.Severity.Warning);
        setLoginErrorMessage("er is iets fout gegaan heb je het juiste kenteken opgegeven?");
        return;
      }
      // check if car exists
      const rdwResult = await getRdwVehicle(autoKenteken);
      if (!rdwResult) {
        setLoading(false);
        ReactGA.event({
          category: "RDW",
          // @ts-ignore
          action: "Geen RDW Data",
          // @ts-ignore
          label: `${currentUser.first_name} Geen RDW gegevens beschikbaar voor ${autoKenteken},`,
          value: 1
        });
        Sentry.captureMessage(`Geen RDW gegevens beschikbaar ${autoKenteken}`, Sentry.Severity.Warning);
        setLoginErrorMessage("Geen RDW gegevens beschikbaar, heb je het juiste kenteken opgegeven?");
        return;
      }

      const vehicleDraft = {
        kenteken: autoKenteken,
        priority: values.priority,
        eigenaar: values.owner,
        inQuarantaine: false,
        id: autoKenteken,
        inzetdatum: values.inzet,
        kenteken_pretty: formatLicenseplate(autoKenteken),
        organisatie: "Wittebrug",
        land_standplaats: "NL",
        voertuig_model_omschrijving: values.model,
        verzorgings_status: values.status,
        taken: values.taken || [],
        tankinhoud: values.tankinhoud
      } as VehicleDraft;
      // @ts-ignore
      const newVehicle = carFactory(vehicleDraft, rdwResult, currentUser.first_name);
      const result = await fireStoreService.updateCarByKenteken(autoKenteken, newVehicle);

      ReactGA.event({
        category: "Create Vehicle",
        // @ts-ignore
        action: "Aangemaakt",
        // @ts-ignore
        label: `Gebruiker ${currentUser.first_name} heeft ${autoKenteken} aangemaakt`,
        value: 1
      });
      setLoading(false);
      // setUserCreated(true);
      // Set success message
      setVehicleCreated(true);
    } catch (error) {
      setLoading(false);
      setLoginErrorMessage(error.message);
      Sentry.captureException(error);

    }
  };


  const onSubmitLoginForm = (values: any) => {
    setLoading(true);
    setLoginErrorMessage("");
    const username = values.username;
    const password = values.password;
    if (username && password) {
      login(username, password);
    } else {
      setLoading(false);
      setLoginErrorMessage("er is iets fout gegaan probeer het opnieuw");
      Sentry.captureMessage("username or password not given", Sentry.Severity.Critical);
    }

  };

  const handleFieldsInModal = (e: any) => {
    const elName = e.target.name;
    const newValue = e.target.value;
    console.log("formstate prev", formState);
    const newState = { ...formState, [elName]: newValue };
    setFormState(newState);
    // tijdelijk
    console.log("formstate", formState);
    // if (elName === "liters") {
    //   const newCar = { ...selectedCar, getankte_liters: newValue, [elName]: newValue } as Vehicle;
    //   setSelectedCar(newCar);
    // } else {
    //   const newCar = { ...selectedCar, [elName]: newValue } as Vehicle;
    //   setSelectedCar(newCar);
    // }
  };
  const handleEditCarModalOk = (e: any) => {
    updateSelectedCar();
    setTankenForm({ ...tankForm, liters: "" });
  };
  const openModal = () => {
    setOpenNotificationModal(true);
  };

  const handleEditCarModalCancel = (e: any) => {
    setEditCarVisible(false);
  };
  const updateSelectedCar = async () => {
    setLoading(true);
    if (!selectedCar || !currentUser) {
      return;
    }
    try {
      const time = new Date().toISOString();
      // console.log("car to save", selectedCar.inQuarantaine);
      // tslint:disable-next-line: prefer-const
      let data: Vehicle = {
        ...selectedCar,
        huidige_locatie_op_terein: selectedCar.huidige_locatie_op_terein || "",
        huidige_locatie_lat_long: "",
        kilometerstand: selectedCar.kilometerstand,
        beschadigd: selectedCar.beschadigd,
        verzorgings_status: selectedCar.verzorgings_status,
        tankinhoud: selectedCar.tankinhoud,
        voertuig_bescrhijving: selectedCar.voertuig_bescrhijving,
        laast_aangepast_op: time,
        aparaat_gebruiker: navigator.userAgent,
        laast_aangepast_door: currentUser.first_name,
        laast_bekeken_door_id: currentUser.uid || "",
        laast_bekeken_door: currentUser.first_name,
        organisatie: selectedCar.organisatie || "",
        taken: selectedCar.taken || [],
        kenteken: selectedCar.kenteken,
        isDeleted: selectedCar.isDeleted || false,
        inQuarantaine: selectedCar.inQuarantaine ? true : false,
        zoek_index: `auto ${selectedCar.kenteken_pretty} ${selectedCar.kenteken} ${selectedCar.merk} ${selectedCar.model} ${selectedCar.verzorgings_status} ${selectedCar.kleur} ${selectedCar.eigenaar} ${selectedCar.inrichting}`
      };

      // TODO clean this up
      /// Write a Car event
      // @ts-ignore
      const carEvent = {
        id: uuidv4(),
        kenteken: selectedCar.kenteken,
        event_datum: time,
        gebruikersnaam: currentUser.first_name,
        userId: currentUser.uid,
        maand: moment(time).format("M"),
        actions: []
      } as VehicleEvent;


      // React GA Event
      if (tankForm.liters) {
        // Need workarount for types
        const getankteLiters = parseInt(tankForm.liters) || 0;
        const tankevent = {
          event_datum: time,
          gebruikersnaam: currentUser.first_name,
          liters: getankteLiters,
          kenteken,
          organisatie: selectedCar.organisatie

        } as TankEvent;
        // add vehicle Tank Action
        carEvent.actions.push({ action: "getankt", category: "Vehicle", value: getankteLiters.toString() });

        data.getankte_liters = getankteLiters;
        data.laatste_tank_event = tankevent;
        fireStoreService.saveTankEvent(tankevent);
        console.log("data", data);
        ReactGA.event({
          category: "Tanken",
          action: selectedCar.eigenaar,
          label: `Gebruiker ${currentUser.first_name} heeft ${getankteLiters} liter getankt voor ${selectedCar.kenteken}`,
          value: getankteLiters
        });
      }
      /// Task logic
      // if (car.beschadigd) {
      //   arr.push("Schade");
      // }
      // if (car.verzorgings_status.toLocaleLowerCase() !== "schoon") {
      //   arr.push("Schoonmaken");
      // }
      // if (car.tankinhoud < 100) {
      //   arr.push("Tanken");
      // }
      // if (car.voertuig_bescrhijving !== "") {
      //   arr.push("Melding");
      // }
      const tankenName = "Tanken";
      const status = "Schoonmaken";
      if (selectedCar.tankinhoud < 100) {
        const taskData: Task = {
          id: uuidv4(),
          name: tankenName
        };
        const existingTask = data.taken.find((vehicle) => vehicle.name === tankenName);
        if (!existingTask) {
          data.taken.push(taskData);
        }
      }
      // remove tanken taak
      if (selectedCar.tankinhoud == 100) {
        const existingTasks = data.taken.filter((x) => x.name !== tankenName);
        data.taken = [...existingTasks];
      }
      /// Start Clean logic
      if (selectedCar.verzorgings_status.toLocaleLowerCase() !== "schoon") {
        const taskData: Task = {
          id: uuidv4(),
          name: status
        };
        const existingTask = data.taken.find((vehicle) => vehicle.name === status);
        if (!existingTask) {
          data.taken.push(taskData);
        }
      }
      // remove clean logic
      if (selectedCar.verzorgings_status == "schoon") {
        const existingTasks = data.taken.filter((x) => x.name !== status);
        data.taken = [...existingTasks];
      }

      console.log("data before save", data);
      await fireStoreService.updateCarByKenteken(
        selectedCar.kenteken,
        { ...data }
      );
      fireStoreService.saveVehicleEvent(carEvent);
      setSelectedCar(data);
      setEditCarVisible(false);
    } catch (error) {
      Sentry.captureException(error);
    }
    setLoading(false);
    setdropDownIsOpen(false);
  };
  return (
    <AppContext.Provider value={{
      getUsers,
      user,
      users,
      cars,
      relatedCars,
      saving,
      searchCars,
      vehicleHistory,
      carSearchResult,
      setUserCreated,
      setVehicleCreated,
      selectedCar,
      vehicleSearchText,
      dropDownIsOpen,
      openNotificationModal,
      kenteken,
      editCarVisible,
      currentCarTankLevel,
      loginErrorMessage,
      loading,
      tankDropdownVisible,
      tankButtonVisible,
      userCreated,
      vehicleCreated,
      onSubmitLoginForm: (element: any) => onSubmitLoginForm(element),
      onSubmitNewUserForm,
      login: (username, password) => login(username, password),
      handleTankenDropDown,
      openModal,
      handleFieldsInModal: (element: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => handleFieldsInModal(element),
      setActiveCar: (id) => setActiveCar(id),
      createVehicle,
      handleKenteken: (licenseplate) => handleKenteken(licenseplate),
      subscribeToVehicleUpdates,
      showEditCarModal,
      handleEditCarModalOk,
      handleEditCarModalCancel,
      setInProgress,
      tankForm,
      handleTankForm,
      vehicleQue,
      pushToQue
    }}>
      {children}
    </AppContext.Provider >
  );
};

const openNotification = (description: string) => {
  const args = {
    message: "Er is iets misgegaan!",
    description,
    duration: 3
  };
  notification.error(args);
};

// tslint:disable-next-line: no-default-export
export default withRouter(AppProvider);
