import React, { useContext, useEffect, useState } from "react";
import "./DashboardScreen.css";
import moment from "moment";
import _ from "lodash";
import ReactGA from "react-ga";
import formatLicenseplate, {
  unformatLicenseplate
} from "../../licenseplateFormater";
import { SearchOutlined } from "@ant-design/icons";
import {
  Table, Row, Col, Alert, Input, Spin, Drawer, Card, Divider, Tag, Button, Space, message,
} from "antd";
import { AppContext } from "../../Providers/MainProvider";
import { VehicleContext } from '../../Providers/VehicleProvider';
import firestoreService from "../../fireStoreService";
import { Vehicle } from "./../../types/vehicle";
import { CarScreenDesktop } from "../Hikes/Car/CarScreenDesktop";
import { DashBoardMenu } from "../../components/DashboardMenu";
import { Hikers, UnkownVehicle } from '../../components/Hikers';
import { UnkownVehicleEvent } from '../../../../lib/src/types';
import { AuthContext } from "../../Providers/Auth";
import { DesktopDataFeed } from "../../components/desktopFeed";
const { Column } = Table;
const { Search } = Input;
const DashboardScreen: React.FC = () => {
  const startDate = moment().toISOString();
  const endDate = moment().subtract(14, "days").toISOString();
  const context = useContext(AppContext);
  const currentVehicleContext = useContext(VehicleContext);
  const { currentUser } = useContext(AuthContext);
  const [searchKey, setSearchKey] = useState<any>({ key: "", isOpen: false });
  const [vehicleDrawerisOpen, setVehicleDrawerisOpen] = useState<boolean>(false);
  const [unkownTankEvents, setUnkownTankEvents] = useState<UnkownVehicleEvent[]>([]);
  // const [timeQuery, setTimeQuery] = useState<string>("");
  useEffect(() => {
    // Update the document title using the browser API
    /// TODO Refurbish
    // const unsubscribeToEvents = firestoreService.subscribeToTankEvents("tank_events", "2020-10-11T18:56:23.510Z", "2020-09-27T18:56:23.511Z", (data: any[]) => {
    //   const grouped = _(data).groupBy("organisatie").map((deparment, index) => {
    //     return {
    //       organisatie: index,
    //       amount: _.sumBy(deparment, "liters")
    //     };
    //   }).value();
    //   // console.log("tank events", data)
    //   setAudi(_.get(grouped.find(x => x.organisatie === "Audi"), "amount", 0));
    //   setVolkswagen(_.get(grouped.find(x => x.organisatie === "Volkswagen"), "amount", 0));
    //   setWittebrug(_.get(grouped.find(x => x.organisatie === "Wittebrug"), "amount", 0));
    //   setHikers(data);
    // });

    // const unsubscribeToUnkownUpdates = firestoreService.subscribeToUnkownUpdates((data: any[]) => {
    //   setUnkownTankEvents(data);
    // });
    // context.subscribeToVehicleUpdates((updates: Vehicle[]) => {
    //   setMutatedCars(updates);
    // });

    return () => {
      // unsubscribeToEvents();
      // unsubscribeToUnkownUpdates();
    };
  }, []);
  if (!context || !currentUser) {
    return <div> <Spin tip="Loading...">
      <Alert
        message="Gegevens worden opgehaald"
        description="een ogenblik geduld"
        type="info"
      />
    </Spin></div>;
  }
  if (currentUser && currentUser.role == 2) {
    return <div>
      <Alert
        message="Je hebt niet voldoende rechten om deze pagina te bekijken."
        description="Als je deze rechten wel nodig hebt neem contact op met de administator van Hikes"
        type="info"
      />
    </div>;
  }
  return (
    <div className="dashboard-screen">
      {/* <HikerMenu
        user={context.user}
        kenteken={context.kenteken}
        selectedCar={context.selectedCar}
      /> */}
      <DashBoardMenu context={context} />
      <div className="body-content">
        <Row>
          <Col><h1 className="page-header">Dashboard </h1></Col>
        </Row>
        <Divider style={{ marginTop: "15px" }} />
        <Row gutter={16} className="card-space">
          <Col>
            {currentVehicleContext.notFound && <Alert onClose={() => { currentVehicleContext.setNotFoundVehicle(false); }} type="warning" message="Auto niet gevonden" banner showIcon closable />}
            <Search className="search-kenteken" style={{ width: "300px", height: "35px", borderRadius: "20px" }}
              placeholder="Kenteken"
              allowClear
              enterButton="Zoek"
              size="large"
              onSearch={async (e) => {
                /// Set ReactGA Event
                ReactGA.event({
                  category: "Search",
                  action: "OnSearch",
                  label: `${currentUser.first_name} heeft gezocht naar: ${e}`,
                  value: 1
                });
                if (e.length > 5) {
                  const id = unformatLicenseplate(e);
                  setSearchKey({ key: e });
                  const vehicle = await currentVehicleContext.getVehicle(id);
                  console.log("veh", vehicle);
                }
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} className="card-space">
          <Col span={24}>

            <Card bodyStyle={{ height: "500px", overflowX: "auto" }} title="Mutaties" className="dashboard-card">
              <DesktopDataFeed setActiveCar={currentVehicleContext.getVehicle} setVehicleDrawer={setVehicleDrawerisOpen} />
              {/* <PieChart audi={audi} volkswagen={volkswagen} wittebrug={wittebrug} /> */}
            </Card>
          </Col>

          {/* <Col span={6}>
            <Card bodyStyle={{ height: "500px", overflowX: "auto" }} title="Niet gevonden auto's" className="dashboard-card">
              {unkownTankEvents && unkownTankEvents.map((x: any, index: number) => (<UnkownVehicle key={`unkownVehicle-${index}`} data={x} />))}
            </Card>
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {/* <RangePicker
            defaultValue={[moment(), moment().subtract(7, "days")]}
            format="DD-MM"
            mode="year"
          /> */}

            <Drawer
              headerStyle={{ textAlign: "center" }}
              width={700}
              destroyOnClose={true}
              title={currentVehicleContext.selectedVehicle ? <div> <strong>{currentVehicleContext.selectedVehicle.kenteken_pretty}</strong> <div>{currentVehicleContext.selectedVehicle.voertuig_model_omschrijving}</div> </div> : "Bewerk auto"}
              placement={"left"}
              closable={true}
              onClose={(e) => { currentVehicleContext.setVehicle(null); }}
              visible={currentVehicleContext.selectedVehicle != null}
            >
              <CarScreenDesktop />
            </Drawer>
            {/* <Card title="Auto's" style={{ width: "100%" }} className="dashboard-card">
              <Table dataSource={context.cars.filter(x => x.laast_aangepast_op < startDate && !x.inQuarantaine)} rowKey={record => `dashboard-table-${record.kenteken}`} pagination={{ pageSize: 50 }} onRow={(r) => ({
                onClick: () => { currentVehicleContext.setVehicle(r); setVehicleDrawerisOpen(true); }
              })}>
                <Column title="Kenteken" dataIndex="kenteken_pretty" key="kenteken_pretty"
                  render={kenteken => (
                    <Tag className="kentekenTag">
                      {kenteken}
                    </Tag>
                  )} />
                <Column title="Voertuig" dataIndex="voertuig_model_omschrijving" key="voertuig_model_omschrijving" />
                <Column title="Bewerker" dataIndex="laast_aangepast_door" key="laast_aangepast_door" />
                <Column title="Locatie" dataIndex="huidige_locatie_op_terein" key="huidige_locatie_op_terein" />
                <Column title="Status" dataIndex="verzorgings_status" key="verzorgings_status" />
                <Column title="Tankinhoud" dataIndex="tankinhoud" key="tankinhoud" render={(x) => `${x}%`} />

                <Column
                  title="Mutatie datum"
                  dataIndex="laast_aangepast_op"
                  key="laast_aangepast_op"
                  render={date => (
                    <span>
                      {moment(date).format("DD-MM HH:mm")}
                    </span>
                  )}
                />

              </Table>
            </Card> */}
          </Col>

        </Row>

      </div>
    </div >
  );
};

export default DashboardScreen;
