import React, { useContext, useState } from "react";
import "./HomeScreen.css";
import Title from "antd/lib/typography/Title";
import { Input } from "antd";
import { useAppContext } from "../../Providers/MainProvider";
import { CarScreen } from "../../Screens/Hikes/Car/CarScreen";
import { VehicleHistory } from "../components/History";
import { SearchOutlined, HomeOutlined, MenuOutlined, HomeTwoTone } from "@ant-design/icons";
import { AuthContext } from "../../Providers/Auth";
import { TabBar } from "antd-mobile";
import { MobileWorkOrders } from "./MobileworkOrders";
import { MobileMenu } from "./MobileMenu";
const options = { month: "long", day: "numeric", year: "numeric" };

export const MobileHomeScreen = () => {
  const { user, kenteken, selectedCar } = useAppContext();
  const [currentTab, setCurrentTab] = useState<string>("search");
  const handleTab = (tab: string) => {
    setCurrentTab(tab);
  };
  return (
    <div style={{ paddingTop: "69px" }} className="full-height mobileHomeScreen" >

      {/* <HikerMenu
        user={user}
        kenteken={kenteken}
        selectedCar={selectedCar}
      /> */}
      <div style={{ position: "fixed", bottom: 0, width: "100%", height: "100%" }}>
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#33A3F4"
          barTintColor="white"
          hidden={false}

        >

          <TabBar.Item
            title="Opdrachten"
            key="home"
            icon={<HomeOutlined style={{ fontSize: "25px" }} />
            }
            selectedIcon={<HomeTwoTone style={{ fontSize: "25px" }} />
            }
            selected={currentTab === "home"}
            onPress={() => {
              handleTab("home");
            }}
            data-seed="logId"
          >    <div style={{ margin: "40px 10px 0 10px" }}>
              <h2 style={{ color: "#008eff", fontWeight: "bold" }}>Hoi Marlowe</h2>
              <div style={{ color: "#979797", fontWeight: "700" }}>Bekijk de opdrachten voor vandaag</div>
              <div style={{ marginTop: "20px" }}>

                <MobileWorkOrders />
              </div>

            </div>
            {!selectedCar ? (
              <div></div>
              // <SearchCar />
            ) : (<div></div>
              ///  Testing new feature
              // <CarScreen vehicle={selectedCar} />
              // <CarScreenBeta vehicle={selectedCar} />
            )}
          </TabBar.Item>
          <TabBar.Item
            title="Zoeken"
            key="search"
            icon={<SearchOutlined style={{ fontSize: "25px" }} />}
            selectedIcon={<SearchOutlined style={{ color: "rgb(51, 163, 244)", fontSize: "25px" }} />
            }
            selected={currentTab === "search"}
            // selected={this.state.selectedTab === 'blueTab'}
            onPress={() => {
              handleTab("search");
            }}
            data-seed="logId"
          >
            {!selectedCar ? (
              <div>
                <SearchCar />
              </div>
            ) : (
              ///  Testing new feature
              <CarScreen vehicle={selectedCar} />
              // <CarScreenBeta vehicle={selectedCar} />
            )}
          </TabBar.Item>
          <TabBar.Item
            title="menu"
            key="menu"
            icon={<MenuOutlined style={{ fontSize: "25px" }} />}
            selectedIcon={<MenuOutlined style={{ fontSize: "25px" }} />
            }
            selected={currentTab === "menu"}
            onPress={() => {
              handleTab("menu");
            }}
            data-seed="logId"
          >
            <MobileMenu />
          </TabBar.Item>
        </TabBar>
      </div>
    </div>
  );
};


function SearchCar() {
  const { currentUser } = useContext(AuthContext);
  const { user, kenteken, setActiveCar, handleKenteken } = useAppContext();
  return (
    <div className="search-screen" >
      <div>
        <div style={{
          paddingTop: "50px",
          paddingLeft: "20px",
          textAlign: "center"

        }} >
          <h4 style={{
            color: "#008EFF",
            fontWeight: 800,
            lineHeight: "40px",
            fontSize: "28px"
          }}> Welke auto zoek je</h4>
          <h5 className="autozoeksubtitle">Voer een kenteken in</h5>
        </div>
        <div className="bottom-area" >
          { // @ts-ignore
          }
          <form onSubmit={(e) => {
            e.preventDefault();
            setActiveCar(kenteken);
          }}>
            <div style={{ textAlign: "center" }}>

            </div>

            <div className="license-plate" >
              <button
                className="search-kenteken"
                type="submit"
              >
                <SearchOutlined />
              </button>

              <Input
                name="kenteken"
                onChange={handleKenteken}
                value={kenteken}
                placeholder="2-THX-57"
              />
            </div>
          </form>
        </div>
        <VehicleHistory />
      </div>
    </div>
  );
}
