import React, { useEffect, useState } from "react";
import fireStoreService from "../../fireStoreService";
import { Link } from "react-router-dom";
import { UserAddOutlined, CarOutlined, HomeOutlined, DashboardOutlined, LogoutOutlined } from "@ant-design/icons";
import { LogoIcon } from "../../components/icons";
import { DashboardLogoIcon } from "../../components/icons";
import { Vehicle } from "@internal/hikes";
import { useAppContext } from "../../Providers/MainProvider";
import { Tag } from "antd";

export const VehicleHistory = () => {
    const { vehicleHistory, setActiveCar } = useAppContext();
    return (
        <div style={{
            paddingLeft: "40px"
        }}>
            <div>
                <h2>Geschiedenis</h2>
            </div>
            <ul className="history-vehicle-list">
                {vehicleHistory.map(vehicle => {
                    return (
                        <li
                            onClick={() => {
                                setActiveCar(vehicle.id);
                            }}
                        >
                            <Tag className="kentekenTag">{vehicle.kenteken_pretty}</Tag>
                            <span> {vehicle.voertuig_model_omschrijving}</span>
                        </li>
                    );
                })}

            </ul>
        </div>
    );
};
