import React, { useEffect, useState } from "react";
import fireStoreService from "../fireStoreService";
import { useHistory, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import { User } from "../types";
import {
    useWindowSize
} from "@react-hook/window-size";
import {

    Spin

} from "antd";


type ContextProps = {
    currentUser: User | null,
    pending: boolean
};


export const AuthContext = React.createContext<ContextProps>({ currentUser: null, pending: true });
// @ts-ignore
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [pending, setPending] = useState(true);
    let history = useHistory();
    let location = useLocation();
    const [width] = useWindowSize();
    useEffect(() => {
        // console.log("auth rerender");
        const authListner = fireStoreService.onAuthUserListener(
            (firebaseUser: any) => {
                // console.log("loggedin", firebaseUser);
                setCurrentUser(firebaseUser);
                // setLoading(false);
                // console.log(firebaseUser.role, location.pathname);
                if (width > 500 && firebaseUser.role === 1) {
                    history.push("/dashboard");
                }
                if (firebaseUser.role >= 3) {
                    history.push("/opdrachten");
                }
                if (width < 500 && firebaseUser.role === 1) {
                    history.push("/");
                }
                if (firebaseUser.role === 2) {
                    history.push("/");
                }
                ReactGA.set({
                    userId: firebaseUser.first_name
                    // any data that is relevant to the user session
                    // that you would like to track with google analytics
                });
                setPending(false);
            },
            (error: any) => {
                // console.log("what is the error", error);
                Sentry.captureException(error);
                localStorage.removeItem("user");
                // console.log(error);
                // this.setState({ loginErrorMessage: error, loading: false });
                history.push("/login");
                setCurrentUser(null);
                setPending(false);
            }
        );
        // @ts-ignore
        return () => authListner();

    }, [history]);
    if (pending) {
        return <div> <Spin size="large" className="spinner" tip="Loading..."></Spin></div>
    }
    return (
        <AuthContext.Provider
            value={{
                currentUser,
                pending
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
