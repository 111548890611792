import { Vehicle } from './types/vehicle';

export const aggragate = (data: Vehicle[]): Facets => {
    const tree = {
        brands: [...new Set(data.map((x) => x.merk))],
        model: [...new Set(data.map((x) => x.model))],
        type: [...new Set(data.map((x) => x.inrichting))],
        color: [...new Set(data.map((x) => x.kleur))],
        state: [...new Set(data.map((x) => x.verzorgings_status))],
        owner: [...new Set(data.map((x) => x.eigenaar))]
    };

    return tree;
};

export const search = (text: string, data: Vehicle[]): SearchResult => {
    const filteredData = data.filter((vehicle) => {
        return vehicle.zoek_index && vehicle.zoek_index
            .toLowerCase()
            .includes(text.toLowerCase());
    });
    return {
        results: [...filteredData],
        facets: aggragate(filteredData)
    };
};

export interface SearchResult {
    results: Vehicle[];
    facets: Facets;
}


export interface Facets {
    brands: string[];
    model: string[];
    type?: string[];
    color?: string[];
    state?: string[];
    owner?: string[];
}