import React from 'react';
const allowedUserChecker = (
    userRoles: any,
    neededPermissions: any
) => {
    const cleanedUpPermissions = userRoles.map((x) => x.split("|")[1]);
    const isIncluded = neededPermissions.every((role: any) =>
        cleanedUpPermissions.includes(role)
    );
    // console.log("included", isIncluded);
    // console.log("needed", neededPermissions);
    // console.log("user", userRoles);
    return isIncluded;
};
interface RBACProps {
    permissionsNeeded: string[];
    children: any;
    fallBackComponent?: any;
    currentUserPermissions: string[];
    props?: any;
}
// const {user} = useCurrentUser()
export const RBAC: React.FC<RBACProps> = ({
    permissionsNeeded,
    children,
    fallBackComponent,
    currentUserPermissions,
    ...props
}) => {
    if (!currentUserPermissions || !permissionsNeeded) {
        return null;
    }

    const access = allowedUserChecker(currentUserPermissions, permissionsNeeded);
    // console.log("access", currentUserPermissions, permissionsNeeded);
    // console.log("access", access);
    // console.log("props", props);
    if (!children) {

        return null;
    }
    if (fallBackComponent) {
        return access ? children : fallBackComponent;
    }

    return access && React.cloneElement(children, props);
};

export const teams = {
    wassers: {
        permissions: ["vehicles:view", "vehicles:edit"]
    },
    hikers: {
        permissions: ["vehicles:view", "vehicles:edit:basic"]
    },
    superadmins: {
        permissions: [
            "vehicles:view",
            "vehicles:edit",
            "vehicles:edit:all",
            "user:edit",
            "user:create",
            "user:view"
        ]
    }
};
