import React, { useContext, useEffect, useState } from "react";
import { message, Spin, Table, Alert, Grid, Comment, Col, Row, Checkbox, List, Tabs, Form, Input, Select, Button } from "antd";
import _ from "lodash";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Providers/Auth";
import { createTeam, Team, createTask, WorkOrder, Task } from "@internal/hikes";
import fireStoreService from "../../fireStoreService";
import * as Sentry from "@sentry/browser";
import { UserProviderContext } from "../../Providers/UserProvider";
import { Tag } from "antd-mobile";
import moment from "moment";
import { search } from '../../search';
import { RBAC } from "../../Rbac";
import { permision } from '../Teams/TeamsScreen';
const options = { month: "long", day: "numeric", year: "numeric" };
const { TabPane } = Tabs;
const { Option } = Select;
const { Column } = Table;
const { TextArea } = Input;

interface WorkOrderType {
  data: WorkOrder;
  close: Function;
}
export const WorkOrderDetails = ({ data, close, ...props }: WorkOrderType) => {
  const { currentUser } = useContext(AuthContext);
  const { users, teams } = useContext(UserProviderContext);
  const [form] = Form.useForm();
  const [tasks, setTasks] = useState<any[]>([]);
  const [dataTasks, setDataTasks] = useState<Task[]>([]);
  const [formData, setFormData] = useState<any>({
    prio: data?.priority || "",
    description: data?.description,
    assignee: data?.asignee
  });
  useEffect(() => {
    const taskNames = data.task.map(task => ({ value: task.name }));
    setTasks(taskNames);
    setDataTasks(data.task);
  }, []);
  const [formSaved, setFormSaved] = useState<boolean>(true);
  // if (!team) {
  //   setCreatenNewTeam(true);
  // }
  const onChange = (record: any, isNotSelected, param) => {
    console.log(record, isNotSelected, param);

  };
  const rowSelection = {
    onSelect: (record, second, param) => onChange(record, second, param),
    onSelectAll: (record, second, param) => onChange(record, second, param),
    getCheckboxProps: (record: any) => ({
      disabled: false, // Column configuration not to be checked
      name: record.name
    })
  };
  const onChangeForm = () => {
    setFormSaved(false);
  };
  const onEdited = () => {
    setFormSaved(false);
  };
  const handleClose = () => {
  };

  const handleDelete = (record) => {
    console.log(record);
  };
  const taskEdit = (tasks: Task[], selection: string) => {
    console.log("taskname", selection, tasks);
    const newtasks = tasks.find(x => x.name === selection);
    if (!newtasks) {
      const newlyAddedTask = createTask(selection);
      console.log("newly added", newlyAddedTask);
      setDataTasks([...dataTasks, newlyAddedTask]);
    }
  };
  const onTaskSelect = (value: any, value2: any) => {
    taskEdit(dataTasks, value);
    setFormSaved(false);
    setTasks(value2);
  };
  const onTaskSelect2 = (value: any, value2: any) => {
    console.log("taskname", value, value2);
  };

  const onTaskDeselect = (taskName, values2) => {
    const removeFields = dataTasks.filter(x => x.name !== taskName);
    console.log("data", dataTasks);
    console.log("on Deselect: ", removeFields);
    setFormSaved(false);
    setDataTasks(removeFields);
  };
  const handleCheck = (event: any, task: Task) => {
    console.log("handlecheck: ", task);
    const tak = data.task.map((taskArr) => {
      if (taskArr.id === task.id) {
        taskArr.isCompleted = true;
      }
      return taskArr;
    });
    const workOrder = { ...data, task: [...tak] };
    fireStoreService.saveWorkOrder(workOrder);
  }
  const handleFormSubmit = () => {
    // console.log('equal', equal, currentFormState, formState)

    form.validateFields()
      .then(async (values) => {
        console.log("------taken-----", values.chosenTasks);
        const asignedUser = values.state == 1 ? currentUser.first_name : null;
        // console.log("asigned user", asignedUser)
        const newState = values.state != "" ? values.state : data.state;
        // console.log("new.state", newState);
        const upDatedWorkOrder = {
          ...data,
          state: values.state,
          description: values.description || "",
          assignee: values.chosenUser || asignedUser,
          team: values.chosenTeam || null,
          task: dataTasks,
          priority: values.prio || 4
        } as WorkOrder;
        // console.log("upDatedWorkOrder", upDatedWorkOrder);
        await fireStoreService.saveWorkOrder(upDatedWorkOrder);
        setFormSaved(true);
        message.success("Succesvol opgeslagen", 1);

      })
      .catch((errorInfo) => {
        const errorObject = JSON.stringify(errorInfo.errorFields, null, 2);
        Sentry.captureMessage(`validatie fout voor ${errorObject}`, Sentry.Severity.Warning);
        // console.log("error info", errorObject);
        console.log("error info", errorInfo);
      });
  };
  return (
    <div style={{ height: "100%", width: "100%" }} className="full-height-with-menu">
      <Form form={form} size="large" initialValues={{
        prio: data?.priority || 1,
        description: data?.description,
        assignee: data?.assignee,
        state: data.state || "Open",
        chosenTeam: data.team,
        chosenTasks: data.task.map(x => x.name),
        kenteken: data?.vehicleAttachment?.kenteken ? [data?.vehicleAttachment?.kenteken_pretty] : ["xx-xx-xx"]
      }} style={{ marginTop: "0" }} onChange={() => { onChangeForm(); }} onFinish={(values) => { handleFormSubmit(); }} layout="vertical" wrapperCol={{ span: 24 }}>
        <span>Aangemaakt op:</span> <span style={{ color: "#31a3ff", fontWeight: 700 }}>{moment(data.createdAt).format("ll")}</span>

        {/* <h2>Opdracht kenteken {data?.vehicleAttachment?.kenteken}</h2> */}
        {/* <div style={{
          display: "inline-block",
          width: "calc(20% - 8px)"
        }}>
          <Tag className="kentekenTag">{data.vehicleAttachment.kenteken}</Tag>
        </div> */}
        <Row gutter={6}>
          <Col span={8}>
            <Form.Item
              label="Kenteken"
              name="kenteken" rules={[{ required: true }]}>
              <Select suffixIcon={<PlusOutlined />}
                tagRender={(tag) => (<Tag className="kentekenTag">{tag.value} </Tag>)} placeholder="Kenteken" onChange={onTaskSelect}
                style={{ width: "100%" }} value={[data?.vehicleAttachment?.kenteken]} mode="tags">
                <Option key={1} title="Klant Wacht" value={data?.vehicleAttachment?.kenteken}>{data?.vehicleAttachment?.kenteken}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Wanneer moet het af"
              name="prio" rules={[{ required: true }]}>
              <Select onChange={() => onEdited()} placeholder="Selecteer taak" value={1} defaultValue={1}>
                <Option key={1} title="Klant Wacht" value={1}>Klant wacht op auto</Option>
                <Option key={2} title="Voor 12 uur" value={2}>Voor 12 uur</Option>
                <Option key={3} title="Voor 17:00 uur" value={3}>Voor 17 uur</Option>
                <Option key={4} title="Einde dag" value={4}>Einde dag</Option>

              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={"Status"}
              name="state" rules={[{ required: true }]}>
              <Select onChange={() => onEdited()} placeholder="Selecteer taak">
                <Option disabled={currentUser.role >= 3} key={0} value={0}>Open</Option>
                <Option disabled={currentUser.role >= 3} key={2} value={1}>In behandeling</Option>
                <Option key={3} value={2}>Klaar</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div>
          <div> Afronden op:  <span style={{ color: "#31a3ff", fontWeight: 700 }}>{moment(data.dueDate).format("ll")}</span></div>
          <div> Aangemaakt door: <span style={{ color: "#31a3ff", fontWeight: 700 }}>{data?.createdBy}</span></div>
          <div> Locatie: <span style={{ color: "#31a3ff", fontWeight: 700 }}>{data?.vehicleAttachment?.huidige_locatie_op_terein}</span></div>
        </div>
        <Row>
          <Col span="24">
            <Form.Item name="description" label="Notities">
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {data?.comments?.length > 0 && <label>Comments</label>}
          <Col span="24">
            {data?.comments?.map(comment => <div> <Comment author={<a>Gebruiker</a>} content={
              <p>
                {comment}
              </p>
            } /></div>)}
          </Col>
        </Row>
        {/* <RBAC {...props} permissionsNeeded={["opdrachten:bewerken"]} currentUserPermissions={currentUser.permissions} >
          <div>
            <h2 style={{ color: "#31a3ff" }}>Leden</h2>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="chosenTeam" label="Team">
                  <Select
                    allowClear
                    // onSelect={(e, param) => handleAddUser(e, param)}
                    showSearch
                    style={{ borderRadius: "10px" }}
                    placeholder="Team"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {teams.map(team => (<Option key={team.id} value={team.id}>{team.name}</Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="chosenUser" label="Medewerker" >
                  <Select
                    allowClear
                    // onSelect={(e, param) => handleAddUser(e, param)}
                    showSearch
                    style={{ borderRadius: "10px" }}
                    placeholder="Nieuwe gebruiker toevoegen"
                    onChange={() => onEdited()}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {users.map(user => (<Option key={user.uid} value={user.uid}>{user.email}</Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </RBAC> */}
        <Row>
          <Col span="24" >
            <h2 style={{ color: "#31a3ff" }}>Taken</h2>
            {currentUser.role == 1 ?
              <Form.Item
                name="chosenTasks" rules={[{ required: true }]}>
                <Select placeholder="Selecteer taak" onSelect={onTaskSelect} onDeselect={onTaskDeselect} style={{ width: "100%" }} value={tasks} defaultValue={tasks} mode="tags">
                  <Option key={1} value={"Wassen"}>Autowassen</Option>
                  <Option key={3} value={"Stofzuigen"}>Stofzuigen</Option>
                  <Option key={2} value={"Tanken"}>Tanken</Option>
                  <Option key={4} value={"Aftersales"}>AfterSales</Option>
                  <Option key={5} value={"Shortlease"}>Shortlease</Option>
                </Select>
              </Form.Item>
              :
              <Form.Item
                name="chosenTasks" rules={[{ required: true }]}>
                <Select placeholder="Selecteer taak" onSelect={onTaskSelect} onDeselect={onTaskDeselect} style={{ width: "100%" }} value={tasks} defaultValue={tasks} mode="tags">
                  <Option key={1} value={"Wassen"}>Autowassen</Option>
                  <Option key={3} value={"Stofzuigen"}>Stofzuigen</Option>
                </Select>
              </Form.Item>
            }
            <List
              dataSource={data.task}
              renderItem={item => (<List.Item>
                <List.Item.Meta
                  style={{ "textDecoration": item.isCompleted ? "line-through" : "none" }}
                  title={item.name}
                />
                <Checkbox onChange={(e) => handleCheck(e, item)} checked={item.isCompleted} />
              </List.Item>)}>

            </List>

            {/* <Form.Item style={{ height: "100%", position: "relative" }}>
        </Form.Item> */}
          </Col>
        </Row>
        <Button onClick={() => { close() }} style={{
          position: "absolute", bottom: 0, left: 0, height: "50px", display: "inline-block",
          width: "50%",
          margin: "0",
          backgroundColor: "#393b4c",
          color: "white"
        }}>
          Annuleren
        </Button>
        <Button style={{
          display: "inline-block",
          width: "50%",
          position: "absolute", right: 0, bottom: 0, height: "50px"
        }} disabled={formSaved} data-testid="save-vehicle" className="vehicle-form-save" type="primary" htmlType="submit">
          Opslaan
        </Button>
      </Form>
    </div >
  );
};
