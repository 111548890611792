import React from "react";
import fireStoreService from "../../fireStoreService";
import { Link } from "react-router-dom";
import { UserAddOutlined, CarOutlined, HomeOutlined, DashboardOutlined, LogoutOutlined } from "@ant-design/icons";
import { LogoIcon } from "../../components/icons";
import { DashboardLogoIcon } from "../../components/icons";

export const MobileMenu = () => {

  return (
    <div className="mobilemenu">
      <div>

        <DashboardLogoIcon />


      </div>

      <ul className="drawer-menu">
        <li
          onClick={() => {
            fireStoreService.logout();
          }}
        >
          {" "}
          <LogoutOutlined />
          {" "}
          Uitloggen
        </li>
      </ul>
    </div>
  );
};
